import { uploadFile, apiCall, s3Url } from "@boatlist/common/lib";

export const postListABoat = (boatObject) => {
  return apiCall("/rest/listingrequest", {
    method: "POST",
    body: JSON.stringify(boatObject),
  });
};

export const updateListABoat = (boatObject) => {
  return apiCall("/rest/listingrequest", {
    method: "PUT",
    body: JSON.stringify(boatObject),
  });
};

export const uploadListABoatFiles = (files, requestId, onProgress, onError) => {
  return new Promise((resolve, reject) => {
    const photoNames = [];
    console.log("Uploading", requestId);

    files.forEach((photo) => {
      console.log(photo);
      photoNames.push({ fileName: photo.name });
    });
    console.log(photoNames);

    s3Url(`/rest/photoupload/${requestId}`, {
      method: "POST",
      body: JSON.stringify(photoNames),
    })
      .then((res) => res.json())
      .then((json) => {
        const { urls } = json;

        urls.forEach((url, index) => {
          const newFormData = new FormData();
          newFormData.append("photo", files[index]);

          uploadFile(url, files[index]).then((res) => {
            if (res.ok) {
              const photoNumber = index + 1;
              const done = urls.length === photoNumber;
              if (onProgress) {
                onProgress({ photoNumber, done });
              }
              if (done) {
                resolve();
              }
            } else {
              res.json().then((uploadJson) => {
                if (uploadJson.error) {
                  onError(uploadJson.error);
                } else {
                  onError(uploadJson);
                }
              });
            }
          });
        });
      })
      .catch((error) => {
        onError(error);
      });
  });
};

export function deleteListABoatFiles(deleteArray, listingId) {
  return apiCall(`/rest/photoupload/${listingId}`, {
    method: "DELETE",
    body: JSON.stringify(deleteArray),
  });
}
