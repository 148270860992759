import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "@/components/common/Card/Card.module.scss";

const Card = ({ variant, children, color, style }) => {
  const cardClasses = classnames(
    styles["card"],
    styles[`card__${variant}`],
    styles[`card__${color}`]
  );
  return (
    <div style={style} className={cardClasses}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  color: null,
};

Card.propTypes = {
  variant: PropTypes.oneOf(["single"]),
  color: PropTypes.string,
};

export default Card;
