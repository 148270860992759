import * as toolkitRaw from "@reduxjs/toolkit";
const { createSlice } = toolkitRaw.default ?? toolkitRaw;

export const questionsSlice = createSlice({
  name: "questions",
  initialState: {
    questions: [],
  },
  reducers: {
    addQuestions: (state, action) => {
      state.questions = action.payload;
    },
    addQuestion: (state, action) => {
      state.questions = [action.payload].concat(state.questions);
    },
    updateQuestion: (state, action) => {
      state.questions = state.questions.map((question) => {
        if (question.questionId === action.payload.questionId) {
          return { ...question, ...action.payload };
        }
        return question;
      });
    },
    deleteQuestion: (state, action) => {
      state.questions = state.questions.filter(
        (question) => question.questionId !== action.payload
      );
    },
    answeredQuestion: (state, action) => {
      const newArray = state.questions.filter(
        (filteredQuestion) =>
          filteredQuestion.questionId !== action.payload.questionId
      );
      console.log(action.payload.question_text);

      const newAnswer = state.questions.find(
        (question) => question.questionId === action.payload.questionId
      );
      console.log("newAnswer", newAnswer);
      if (newAnswer) {
        newAnswer.just_answer = true;
        state.questions = [{ ...newAnswer, ...action.payload }].concat(
          newArray
        );
      }
      console.log("here", state.questions);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addQuestions,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  answeredQuestion,
} = questionsSlice.actions;

export const selectAllQuestions = (state) => state.questions.questions;

export default questionsSlice.reducer;
