import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiCall, getConfig } from "@boatlist/common/lib";
import { BiArrowBack } from "react-icons/all";

import { CONSTANTS } from "@/lib/core/index.js";

import Tabs from "@/components/common/Tabs";
import Card from "@/components/common/Card";
import Layout from "@/components/common/Layout";
import Label from "@/components/common/Label";
import PostDealForm from "@/components//forms/PostDealForm";
import Dialog from "@/components/common/Dialog";
import Button from "@/components/common/Button";
import AuctionFeed from "@/components/auctions/AuctionFeed";
import AuctionLabels from "@/components/auctions/AuctionLabels";
import AuctionUpdateTab from "@/pages/SingleAuctionPage/AuctionUpdateTab.jsx";
import SingleAuctionDetailsCard from "@/pages/SingleAuctionPage/SingleAuctionDetailsCard.jsx";
import styles from "./SingleAuctionPage.module.scss";
import AuctionManageTab from "@/pages/SingleAuctionPage/AuctionManageTab";

const SingleAuctionPage = () => {
  const { auctionId } = useParams();
  const navigate = useNavigate();
  const [auction, setAuction] = useState({});
  const [defaultTab, setDefaultTab] = useState(0);

  useEffect(() => {
    apiCall(`/rest/listing/${auctionId}`)
      .then((res) => res.json())
      .then((json) => setAuction(json));
  }, []);

  const getListingStatusLabel = (listingStatus) => {
    switch (listingStatus) {
      case CONSTANTS.LISTING_STATUS.NEW:
        return (
          <Label variant="secondary" size="md">
            New
          </Label>
        );
      case CONSTANTS.LISTING_STATUS.ACTIVE:
        return (
          <Label variant="primary" size="md">
            Scheduled
          </Label>
        );
      case CONSTANTS.LISTING_STATUS.LIVE:
        return (
          <Label variant="success" size="md">
            Active
          </Label>
        );
      case CONSTANTS.LISTING_STATUS.ENDED:
        return (
          <Label variant="danger" size="md">
            Ended
          </Label>
        );
      case CONSTANTS.LISTING_STATUS.POSTDEAL:
        return (
          <Label variant="danger" size="md">
            Post Deal
          </Label>
        );
      default:
        return (
          <Label variant="danger" size="md">
            Canceled
          </Label>
        );
    }
  };

  return (
    <Layout activePage={CONSTANTS.NAV.AUCTIONS}>
      <div className={styles["auction-box"]}>
        <div className={styles["title"]}>
          <div className={styles["icon"]}>
            <button
              type="button"
              className={styles["link-button"]}
              onClick={() => navigate(-1)}
            >
              <BiArrowBack />
            </button>
          </div>
          <div className={styles["mobile-title"]}>
            <div className={styles["request-title"]}>{auction.title}</div>
            <div className={styles["mobile-group"]}>
              <div>{getListingStatusLabel(auction.listingStatus)}</div>
              <div className={styles["flex-grow"]} />
              <a
                href={`${getConfig().base_content_url}/auctions/${auctionId}`}
                target="_blank"
                className={styles["link"]}
                rel="noreferrer"
              >
                View
              </a>
            </div>
          </div>
        </div>

        <Card>
          <div className={styles["outside-box"]}>
            <SingleAuctionDetailsCard {...{ auction }} />
            <div className={styles["flex-grow"]} />
            <img
              src={`https://boatlist.auction${auction.picture}`}
              className={styles["image"]}
              alt={auction.title}
            />
          </div>
          <AuctionLabels {...{ auction }} />
        </Card>
        <div className={styles["bottom-box"]}>
          <div className={styles["tabs"]}>
            <Tabs
              menu={[
                { title: "Manage", value: 0 },
                { title: "Update", value: 1 },
                { title: "Settings", value: 2 },
              ]}
              {...{ defaultTab }}
            >
              <AuctionManageTab {...{ auctionId, auction }} />
              <AuctionUpdateTab {...{ auction, setAuction }} />
            </Tabs>
          </div>
          <AuctionFeed {...{ auctionId, auction }} />
        </div>
      </div>
    </Layout>
  );
};

export default SingleAuctionPage;
