import React, { useEffect, useState } from "react";
import { apiCall, getConfig } from "@boatlist/common/lib";
import Card from "@/components/common/Card/index.js";
import DataTable from "@/components/common/DataTable/index.js";
import DealModal from "@/components/auctions/DealModal/index.js";
import Label from "@/components/common/Label/index.js";
import GetReservePrice from "@/components/auctions/Auctions/GetReservePrice.jsx";
import GetHighestBidUser from "@/components/auctions/Auctions/GetHighestBidUser/GetHighestBidUser.jsx";
import { CONSTANTS } from "@/lib/core/index.js";

const columns = [
  {
    id: "requestId",
    name: "requestId",
    title: "ID",
    className: "listingId",
    width: "10%",
  },
  {
    id: "title",
    name: "title",
    title: "Title",
    className: "title",
    value: "requestId",
    type: "link",
    width: "100%",
  },
  {
    id: "requestStatus",
    name: "requestStatus",
    title: "Listing Status",
    className: "status",
    width: "200px",
  },
  {
    id: "actions",
    name: "actions",
    title: "Actions",
    className: "actions",
    type: "actions",
    width: "15%",
  },
];

const getAuctionRows = (requests, actions) => {
  const getListingStatusLabel = (requestStatus) => {
    switch (requestStatus) {
      case CONSTANTS.REQUEST_STATUS.NEW:
        return <Label variant="secondary">New</Label>;
      case CONSTANTS.REQUEST_STATUS.APPROVED:
        return <Label variant="primary">Approved</Label>;
      case CONSTANTS.REQUEST_STATUS.REJECTED:
        return <Label variant="danger">Rejected</Label>;
      case CONSTANTS.REQUEST_STATUS.LISTED:
        return <Label variant="success">Listed</Label>;
      default:
        return <Label variant="primary">Canceled</Label>;
    }
  };

  const getTitle = (listing) => {
    return `${listing.brandName ? listing.brandName : listing.brandOther} ${
      listing.modelName ? listing.modelName : listing.modelOther
    }`;
  };

  return requests.map((request) => ({
    id: request.requestId,
    requestId: {
      value: request.requestId,
    },
    title: {
      value: getTitle(request) || "N/A",
      link: `/requests/${request.requestId}/${request.userId}`,
    },
    requestStatus: {
      filterValue: Number(request.requestStatus),
      value: getListingStatusLabel(request.requestStatus),
    },
    request: {
      value: request,
    },
    actions,
  }));
};

const UserRequestTable = ({ userId, liveAuctionColumns }) => {
  const [requests, setRequests] = useState(null);
  const [requestsRow, setRequestsRows] = useState(null);
  useEffect(() => {
    apiCall(`/rest/listingrequestsbyuser/${userId}`)
      .then((res) => res.json())
      .then((json) => setRequests(json));
  }, []);

  useEffect(() => {
    if (requests) {
      const actions = [
        {
          name: "view",
          title: "View",
          onClick: (listingId) => {
            console.log(listingId);
            window.open(
              `${getConfig().base_content_url}/auctions/${listingId}`,
              "_blank"
            );
          },
        },
      ];
      setRequestsRows(getAuctionRows(requests, actions));
    }
  }, [requests]);

  return (
    <div>
      <h2>Auction Request</h2>
      <Card>
        <DataTable id="requests" rows={requestsRow} {...{ columns }} />
      </Card>
    </div>
  );
};

export default UserRequestTable;
