import React, { useEffect, useState } from "react";
import TextField from "@/components/common/TextField/index.js";
import Card from "@/components/common/Card";

import styles from "./MarketListingForm.module.scss";
import FileUpload from "@/components/common/FileUpload/index.js";
import TextListField from "../../common/TextListField/TextListField";
import FormFileUpload from "../../common/FileUpload/FormFileUpload";

const ContentCard = ({ errors, updateListing, register, setValue, watch }) => {
  return (
    <Card color={updateListing && "blue"}>
      <div className={styles["heading"]}>Content</div>
      <div className={styles["photos"]}>
        <FormFileUpload
          extraDiv
          label="Upload photos of your listing and drag the main photo to the top."
          multiple
          limit={10}
          register={register}
          name="photos"
          error={errors.photos?.message}
          optional
          setValue={setValue}
          watch={watch}
        />
      </div>
      {/* <TextListField
        label="Paste or type URLs videos and add one at a time."
        onChange={(videos) => handleInput(videos, "videos")}
        videos={listing.videos}
        error={error.videos}
        limit={1}
      /> */}
      <TextField
        title="Paste or type the URL of a video. (Optional)"
        type="text"
        // value={listing.videos}
        // onChange={(e) => handleInput([e.target.value], "videos")}
        register={register}
        name="videos"
      />
    </Card>
  );
};

export default ContentCard;
