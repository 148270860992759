import React from "react";

import { Dropdown } from "@boatlist/common/components";
import { BiDotsHorizontalRounded } from "react-icons/all";

import PropTypes from "prop-types";
import IconButton from "@/components/common/IconButton";

import styles from "./Popper.module.scss";

const PopperTrigger = React.forwardRef((props, ref) => {
  return (
    <button
      type="button"
      className={styles["popper__trigger"]}
      ref={ref}
      {...props}
    >
      <IconButton variant="secondary" noPadding size="md">
        <BiDotsHorizontalRounded />
      </IconButton>
    </button>
  );
});

const Popper = ({ children }) => {
  return (
    <div className={styles["popper"]}>
      <Dropdown contentClassName={styles["popper"]} Trigger={PopperTrigger}>
        <div className={styles["popper__content"]}>
          {children(styles["popper__item"])}
        </div>
      </Dropdown>
    </div>
  );
};

Popper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Popper;
