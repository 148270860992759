import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";

import TextField from "@/components/common/TextField/index.js";
import Button from "@/components/common/Button/index.js";
import Card from "@/components/common/Card/index.js";
import styles from "./MarketStartTimeForm.module.scss";
import Dialog from "@/components/common/Dialog/index.js";
import { useForm } from "react-hook-form";
import FormDialog from "@/components/common/FormDialog";
import { updateMarketStartDate } from "../../../lib/services/MarketListingApiCalls";

const MarketStartTimeForm = ({ listing, setListing }) => {
  const [updateModal, setUpdateModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({});

  const timeStamp = new Date(listing.startDate);

  useEffect(() => {
    const year = timeStamp.getFullYear();
    const month = `0${timeStamp.getMonth() + 1}`.slice(-2);
    const date = `0${timeStamp.getDate()}`.slice(-2);
    const startDate = `${year}-${month}-${date}`;

    let hours = timeStamp.getHours();
    let minutes = timeStamp.getMinutes();

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (hours < 10) {
      hours = `0${hours}`;
    }

    const startTime = `${hours}:${minutes}`;

    reset({ startTime, startDate });
  }, [listing]);

  const onSubmit = (data) => {
    data.listingId = listing.listingId;
    data.startDate = new Date(
      `${data.startDate}T${data.startTime}`
    ).toISOString();
    updateMarketStartDate(data).then((res) => {
      if (res.ok) {
        console.log("success datetime");
        res.json().then((json) => setListing({ ...listing, ...json }));
        setUpdateModal(false);
      }
    });
  };

  return (
    <div className={styles["form-box"]}>
      <Card color="blue">
        <div className={styles["title"]}>Listing Start Time</div>
        <div className={styles["description"]}>
          Updating the start time will update the expiration date.
        </div>
        <div className={styles["inside-box"]}>
          <TextField
            title="Auction Start Date"
            type="date"
            register={register}
            required="This field is required."
            name="startDate"
            error={errors.startDate?.message}
          />
          <TextField
            title="Auction Start Time"
            type="time"
            register={register}
            required="This field is required."
            name="startTime"
            error={errors.startTime?.message}
          />
        </div>
        <div className={styles["button-right"]}>
          <Button variant="primary" onClick={() => setUpdateModal(true)}>
            Update
          </Button>
        </div>
      </Card>
      {updateModal && (
        <FormDialog
          onCancel={() => setUpdateModal(false)}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          variant="primary"
          acceptText="Update"
          description="Are you sure you want to update this listing?"
        />
      )}
    </div>
  );
};

MarketStartTimeForm.propTypes = {
  listing: PropTypes.object.isRequired,
  setListing: PropTypes.func.isRequired,
};

export default MarketStartTimeForm;
