import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getConfig } from "@boatlist/common/lib";
import { CONSTANTS } from "@/lib/core";

import Button from "@/components/common/Button";
import Card from "@/components/common/Card";
import DataTable from "@/components/common/DataTable";
import Label from "@/components/common/Label";
import GetReservePrice from "@/components/auctions/Auctions/GetReservePrice.jsx";
import GetHighestBidUser from "@/components/auctions/Auctions/GetHighestBidUser/GetHighestBidUser.jsx";

const columns = [
  {
    id: "listingId",
    name: "listingId",
    title: "ID",
    className: "listingId",
    isSortable: true,
    width: "10%",
  },
  {
    id: "title",
    name: "title",
    title: "Title",
    className: "title",
    value: "listingId",
    type: "link",
    isSortable: true,
    width: "20%",
  },
  {
    id: "username",
    name: "username",
    title: "Username",
    className: "seller",
    value: "userId",
    type: "link",
    isSortable: true,
    width: "15%",
  },
  {
    id: "expirationDate",
    name: "expirationDate",
    title: "Expiration Date",
    className: "expDate",
    value: "expirationDate",
    isSortable: true,
    width: "20%",
  },
  {
    id: "reserve",
    name: "reserve",
    title: "Reserve",
    className: "reserve",
    value: "listingId",
    width: "15%",
  },
  {
    id: "startingBid",
    name: "startingBid",
    title: "Starting Bid",
    className: "reserve",
    value: "startingBid",
    width: "15%",
    type: "currency",
  },
  {
    id: "highestBid",
    name: "highestBid",
    title: "Highest Bid",
    className: "highest-bid",
    isSortable: true,
    type: "currency",
    value: "highestBid",
    width: "15%",
  },
  {
    id: "highestBidUser",
    name: "highestBidUser",
    title: "Highest Bid User",
    className: "highest-bidder",
    isSortable: true,
    value: "highestBidUser",
    width: "20%",
  },
  {
    id: "listingStatus",
    name: "listingStatus",
    title: "Listing Status",
    className: "status",
    value: "listingStatus",
    isSortable: true,
    width: "15%",
  },
  {
    id: "actions",
    name: "actions",
    title: "Actions",
    className: "actions",
    value: "listingId",
    type: "actions",
    width: "10%",
  },
];

const LISTING_STATUS = {
  NEW: 0,
  ACTIVE: 1,
  LIVE: 2,
  ENDED: 3,
  CANCELLED: 4,
  POSTDEAL: 5,
};

const getAuctionRows = (
  auctions,
  actions,
  handleRelist,
  handleDeal,
  handleOffer
) => {
  const getListingStatusLabel = (listingStatus) => {
    switch (listingStatus) {
      case LISTING_STATUS.NEW:
        return <Label variant="secondary">New</Label>;
      case LISTING_STATUS.ACTIVE:
        return <Label variant="primary">Scheduled</Label>;
      case LISTING_STATUS.LIVE:
        return <Label variant="success">Active</Label>;
      case LISTING_STATUS.ENDED:
        return <Label variant="danger">Ended</Label>;
      case LISTING_STATUS.POSTDEAL:
        return <Label variant="danger">Post Deal</Label>;
      default:
        return <Label variant="danger">Canceled</Label>;
    }
  };

  const getExpirationDate = (expirationDate) => {
    if (expirationDate) {
      const date = new Date(expirationDate).toLocaleString("en-us", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      return date;
    }

    return "No date available";
  };

  const newActions = [
    {
      name: "relist",
      title: "Relist",
      onClick: (listingId) => {
        console.log(listingId);
        handleRelist(listingId);
      },
    },
    {
      name: "deal",
      title: "Deal",
      onClick: (listingId) => {
        console.log(listingId);
        handleDeal(listingId);
      },
    },
    {
      name: "offer",
      title: "Offer",
      onClick: (listingId) => {
        console.log(listingId);
        handleOffer(listingId);
      },
    },
  ];
  const offerAction = [
    {
      name: "offer",
      title: "Offer",
      onClick: (listingId) => {
        console.log(listingId);
        handleOffer(listingId);
      },
    },
    {
      name: "deal",
      title: "Deal",
      onClick: (listingId) => {
        console.log(listingId);
        handleDeal(listingId);
      },
    },
  ];

  const handleActions = (auction) => {
    if (auction.listingStatus === 3) {
      return newActions.concat(actions);
    }

    if (auction.listingStatus === 1) {
      return offerAction.concat(actions);
    }

    return actions;
  };

  return auctions.map((auction) => ({
    id: auction.listingId,
    listingId: {
      sortValue: auction.listingId,
      value: auction.listingId,
    },
    title: {
      sortValue: auction.title,
      value: auction.title,
      link: `/auctions/${auction.listingId}`,
    },
    username: {
      sortValue: auction.username,
      value: auction.username,
      link: `/users/${auction.userId}`,
    },
    expirationDate: {
      sortValue: new Date(auction.expirationDate).getTime(),
      value: getExpirationDate(auction.expirationDate),
    },
    reserve: {
      value: <GetReservePrice id={auction.listingId} />,
    },
    startingBid: {
      value: Number(auction.startingBid) || 0,
    },
    highestBid: {
      sortValue: auction.highestBid,
      value: Number(auction.highestBid) || 0,
    },
    highestBidUser: {
      sortValue: auction.highestBidUser,
      value: <GetHighestBidUser username={auction.highestBidUser} />,
    },
    listingStatus: {
      filterValue: Number(auction.listingStatus),
      value: getListingStatusLabel(auction.listingStatus),
    },
    auction: {
      value: auction,
    },
    // actions,
    actions: handleActions(auction),
  }));
};

const AuctionsTable = ({
  auctions,
  visibleColumns,
  filter,
  handleDeal,
  handleRelist,
  handleOffer,
}) => {
  const [auctionRows, setAuctionRows] = useState(null);
  const [filteredAuctionRows, setFilteredAuctionRows] = useState([]);

  useEffect(() => {
    if (auctions) {
      const actions = [
        {
          name: "view",
          title: "View",
          onClick: (listingId) => {
            console.log(listingId);
            window.open(
              `${getConfig().base_content_url}/auctions/${listingId}`,
              "_blank"
            );
          },
        },
      ];
      setAuctionRows(
        getAuctionRows(auctions, actions, handleRelist, handleDeal, handleOffer)
      );
    }
  }, [auctions]);

  useEffect(() => {
    if (auctionRows) {
      if (
        filter !== null &&
        typeof filter !== "undefined" &&
        filter.length !== 0
      ) {
        setFilteredAuctionRows(
          auctionRows.filter((auction) =>
            filter.includes(auction.listingStatus.filterValue)
          )
        );
      } else {
        setFilteredAuctionRows(auctionRows);
      }
    }
  }, [auctionRows, filter]);
  return (
    <DataTable
      id="auctions"
      defaultSort="expirationDate"
      rows={filteredAuctionRows}
      {...{ columns, visibleColumns }}
    />
  );
};

AuctionsTable.propTypes = {};

export default AuctionsTable;
