import React, { useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import {
  BiHome,
  BiGroup,
  BiBuoy,
  BiMenu,
  BiAnchor,
  BiShoppingBag,
} from "react-icons/bi";
import { MdMailOutline } from "react-icons/md";

import useLocalStorage from "@/hooks/useLocalStorage";

import AccountPopper from "@/components/account/AccountPopper";
import EnvironmentPopper from "../EnvironmentPopper";
import styles from "./Layout.module.scss";

const Layout = ({ children, nav, activePage }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [env, setEnv] = useLocalStorage("admin_env", "prod");

  const defaultNav = [
    { id: 0, title: "Home", link: "/", icon: <BiHome /> },
    { id: 1, title: "Users", link: "/users", icon: <BiGroup /> },
    { id: 2, title: "Auctions", link: "/auctions", icon: <BiBuoy /> },
    {
      id: 3,
      title: "Requests",
      link: "/requests",
      icon: <BiAnchor />,
    },
    {
      id: 4,
      title: "Market Place",
      link: "/marketplace",
      icon: <BiShoppingBag />,
    },
    {
      id: 5,
      title: "Emails",
      link: "/emails",
      icon: <MdMailOutline />,
    },
    ...nav,
  ];

  // if (env === "dev") {
  //   defaultNav.push({
  //     id: 4,
  //     title: "Market Place",
  //     link: "/marketplace",
  //     icon: <BiShoppingBag />,
  //   });
  // }

  return (
    <div className={styles["layout"]}>
      <div className={cx(styles["sidebar"], showSidebar && styles["show"])}>
        <div className={styles["title"]}>
          {/* <span>BoatList</span> */}
          <img
            src="https://boatlist.auction/images/boatlist_logo150.png"
            alt="BoatList"
          />
        </div>
        <nav className={styles["nav"]}>
          <h4 className={styles["nav__sub-title"]}>Dashboards</h4>
          <ul className={styles["nav__items"]}>
            {defaultNav.map((navItem) => (
              <Link key={navItem.link} to={navItem.link}>
                <li
                  className={cx(
                    styles["nav__item"],
                    activePage === navItem.id && styles["nav__item-active"]
                  )}
                >
                  {navItem.icon}
                  <span className={styles["nav__item-text"]}>
                    {navItem.title}
                  </span>
                </li>
              </Link>
            ))}
          </ul>
        </nav>
      </div>
      <main className={styles["main"]}>
        <div className={styles["topbar"]}>
          <button
            type="button"
            onClick={() => setShowSidebar(!showSidebar)}
            className={styles["topbar__menu"]}
          >
            <BiMenu />
          </button>
          <EnvironmentPopper />
          <div className={styles["topbar__main"]} />
          <AccountPopper />
        </div>
        <section className={styles["main__content"]}>{children}</section>
      </main>
    </div>
  );
};

Layout.defaultProps = {
  nav: [],
};

export default Layout;
