import { getConfig } from "@boatlist/common/lib";

const baseUrl = getConfig().base_api_url;

export const apiCall = (url, options) => {
  const token = sessionStorage.getItem("token");
  return fetch(baseUrl + url, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
      ...options?.headers,
    },
  });
};

export const s3Url = (url, options) => {
  const token = sessionStorage.getItem("token");
  return fetch(baseUrl + url, {
    ...options,
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
};

export const uploadFile = (url, file) => {
  return fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
