import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { apiCall } from "@boatlist/common/lib";

import Label from "@/components/common/Label/index.js";
import GetHighestBidUser from "@/components/auctions/Auctions/GetHighestBidUser/GetHighestBidUser.jsx";
import styles from "./SingleAuctionPage.module.scss";

const SingleAuctionDetailsCard = ({ auction }) => {
  const [reserve, setReserve] = useState(null);

  const handleDate = (expirationDate) => {
    if (expirationDate) {
      const date = new Date(expirationDate).toLocaleString("en-us", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      return date;
    }
    return "No date available";
  };

  useEffect(() => {
    apiCall(`/rest/listing/reserve/${auction.listingId}`)
      .then((res) => res.json())
      .then((json) => setReserve(json.reserve));
  }, [auction]);

  return (
    <div className={styles["auction-details"]}>
      <div className={styles["details"]}>Auction Details</div>
      <div className={styles["color-box"]}>
        <div className={styles["auction-header"]}>
          <div className={styles["header"]}>Auction ID</div>
          <div className={styles["header"]}>Seller</div>
        </div>
        <div className={styles["auction-holder"]}>
          <div className={styles["content"]}>{auction.listingId}</div>
          <div className={styles["username"]}>
            <Link
              to={`/users/${auction.userId}`}
              className={styles["seller__link"]}
            >
              {auction.username}
            </Link>
          </div>
        </div>
        <div className={styles["auction-header"]}>
          <div className={styles["header"]}>Starts</div>
          <div className={styles["header"]}>Ends</div>
        </div>
        <div className={styles["auction-holder"]}>
          <div className={styles["content"]}>
            {handleDate(auction.dateTime)}
          </div>
          <div className={styles["content"]}>
            {handleDate(auction.expirationDate)}
          </div>
        </div>
        <div className={styles["auction-header"]}>
          <div className={styles["header"]}>Reserve</div>
          <div className={styles["header"]}>Highest Bid/User</div>
        </div>
        <div className={styles["auction-holder"]}>
          <div className={styles["content"]}>
            <Label variant="secondary" size="md">
              ${reserve && reserve.toLocaleString()}
            </Label>
          </div>
          <div className={styles["bid"]}>
            <Label variant="secondary" size="md">
              ${auction.highestBid && auction.highestBid.toLocaleString()}
            </Label>
            <div className={styles["bid-user"]}>
              {auction.highestBidUser && (
                <GetHighestBidUser username={auction.highestBidUser} />
              )}
            </div>
          </div>
        </div>
        {auction.startingBid !== 0 && (
          <>
            <div className={styles["auction-header"]}>
              <div className={styles["header"]}>Starting Bid</div>
            </div>
            <div className={styles["auction-holder"]}>
              <div className={styles["content"]}>
                <Label variant="secondary" size="md">
                  ${auction.startingBid && auction.startingBid.toLocaleString()}
                </Label>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SingleAuctionDetailsCard.propTypes = {
  auction: PropTypes.object.isRequired,
};

export default SingleAuctionDetailsCard;
