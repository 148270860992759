import React, { useEffect, useState } from "react";
import { apiCall } from "@boatlist/common/lib";
import UserAuctionsTable from "@/pages/SingleUserPage/AuctionsTab/UserAuctionsTable.jsx";
import UserRequestTable from "@/pages/SingleUserPage/AuctionsTab/UserRequestTable.jsx";

const AuctionsTab = ({ userId }) => {
  const liveAuctionColumns = [
    "listingId",
    "title",
    "expirationDate",
    "listingStatus",
    "actions",
  ];
  const [auctions, setAuctions] = useState(null);

  useEffect(() => {
    apiCall(`/rest/listings/${userId}`)
      .then((res) => res.json())
      .then((json) => setAuctions(json));
  }, []);
  return (
    <div>
      <h2>Auctions</h2>
      <UserAuctionsTable
        {...{ auctions }}
        visibleColumns={liveAuctionColumns}
      />
      <UserRequestTable {...{ userId, liveAuctionColumns }} />
    </div>
  );
};

export default AuctionsTab;
