import React, { useEffect, useState } from "react";
import { apiCall, getConfig } from "@boatlist/common/lib";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import useLocalStorage from "@/hooks/useLocalStorage.js";
import { updateUsers } from "@/store/slices/users.js";
import { getUsers } from "@/lib/services/index.js";

import Select from "@/components/common/Select";
import TextField from "@/components/common/TextField";
import Button from "@/components/common/Button";

import styles from "./UsersTableBar.module.scss";

const UsersTableBar = ({ setLoading }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useLocalStorage("search", null);
  const [userId, setUserId] = useState(null);
  const [optionValue, setOptionValue] = useLocalStorage("option", null);
  const [placeHolder, setPlaceHolder] = useState(null);

  useEffect(() => {
    if (search) {
      handleSearch();
    }
  }, []);

  const handleSearch = () => {
    setLoading(true);
    if (search && optionValue) {
      if (optionValue === "username" || optionValue === "email") {
        if (search.length > 2) {
          apiCall(`/rest/user/${optionValue}/*${search}*`)
            .then((res) => res.json())
            .then((json) => {
              dispatch(updateUsers(json));
              setLoading(false);
            });
        }
      } else {
        apiCall(`/rest/user/${optionValue}/${search}`)
          .then((res) => res.json())
          .then((json) => {
            dispatch(updateUsers(json));
            setLoading(false);
          });
      }
    } else if (userId && optionValue) {
      if (optionValue === "userId") {
        apiCall(`/rest/user/${userId}`).then((res) => {
          if (res.ok) {
            window.location.href = `/users/${userId}`;
            setLoading(false);
          } else {
            dispatch(updateUsers([]));
            setLoading(false);
          }
        });
      }
    } else {
      getUsers({ page: 0, users: 20 }).then((res) => setLoading(false));
    }
  };

  const handleInput = (value) => {
    if (optionValue === "userId") {
      setUserId(value);
    } else {
      setSearch(value);
    }
  };

  const handleOptionValue = (value) => {
    if (value === "username") {
      setPlaceHolder("Ex: john*");
    }
    if (value === "email") {
      setPlaceHolder("Ex: *@gmail.com");
    }
    if (value === "firstname") {
      setPlaceHolder("Ex: John");
    }
    if (value === "lastname") {
      setPlaceHolder("Ex: Smith");
    }
    setOptionValue(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleClear = () => {
    setPlaceHolder(null);
    setSearch("");
    setOptionValue("");
    window.localStorage.removeItem("search");
    window.localStorage.removeItem("option");
    getUsers({ page: 0, users: 20 }).then((res) => setLoading(false));
  };

  return (
    <div className={styles["search"]}>
      <Select
        value={optionValue}
        onChange={(e) => handleOptionValue(e.target.value)}
      >
        <option className={styles["option"]} value="" />
        <option className={styles["option"]} value="username">
          username
        </option>
        <option className={styles["option"]} value="email">
          email
        </option>
        <option className={styles["option"]} value="firstname">
          first name
        </option>
        <option className={styles["option"]} value="lastname">
          last name
        </option>
        <option className={styles["option"]} value="userId">
          user ID
        </option>
      </Select>
      <TextField
        placeholder={placeHolder}
        onChange={(e) => handleInput(e.target.value)}
        onKeyUp={handleKeyPress}
        value={search}
      />
      <Button variant="secondary" onClick={handleSearch}>
        Search
      </Button>
      <Button variant="primary" outline onClick={handleClear}>
        Clear
      </Button>
    </div>
  );
};

UsersTableBar.propTypes = {
  setLoading: PropTypes.bool.isRequired,
};

export default UsersTableBar;
