import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";

import {
  fetchGetEmailTemplates,
  fetchPostEmailTemplate,
  fetchPutEmailTemplate,
  fetchDeleteEmailTemplate,
  fetchGetEmailTemplate,
  fetchPublishEmailTemplate,
} from "../../lib/services/EmailApiCalls";
// import {
//   updateEmailTemplate,
//   removeEmailTemplate,
//   addEmailTemplates,
//   addEmailTemplate,
//   setActiveEmailTemplate,
// } from "../../store/slices/emailTemplates";

export const useEmailTemplates = () => {
  const [emailTemplates, setEmailTemplates] = useState(null);
  const [activeEmailTemplate, setActiveEmailTemplate] = useState(null);
  //   const dispatch = useDispatch();

  useEffect(() => {
    fetchGetEmailTemplates().then((json) => setEmailTemplates(json));
  }, []);

  const handleUpdateTemplate = (template, newTemplateData) => {
    const { html, design, ...rest } = newTemplateData;
    const newTemplate = { template, ...rest };

    setActiveEmailTemplate(newTemplate);

    const templateIndex = emailTemplates.findIndex(
      (emailTemplate) => emailTemplate.templateName === template.templateName
    );

    setEmailTemplates(emailTemplates.toSpliced(templateIndex, 1, newTemplate));
  };

  const getEmailTemplate = (template) =>
    fetchGetEmailTemplate(template.templateName).then((json) => {
      const templateIndex = emailTemplates.findIndex(
        (emailTemplate) => emailTemplate.templateName === template.templateName
      );

      setEmailTemplates(
        emailTemplates.toSpliced(templateIndex, 1, {
          ...template,
          ...json,
        })
      );

      return json;
    });

  const putEmailTemplate = (template) =>
    fetchPutEmailTemplate(template).then((newTemplateData) =>
      handleUpdateTemplate(template, newTemplateData)
    );

  const postEmailTemplate = (template) =>
    fetchPostEmailTemplate(template).then((newTemplateData) => {
      const { html, design, ...rest } = newTemplateData;
      const newTemplate = { template, ...rest };

      setActiveEmailTemplate(newTemplate);
      setEmailTemplates(emailTemplates.concat(newTemplate));
    });

  const deleteEmailTemplate = (template) =>
    fetchDeleteEmailTemplate(template).then(() => {
      //   dispatch(removeEmailTemplate(template));
      const templateIndex = emailTemplates.findIndex(
        (emailTemplate) => emailTemplate.templateName === template.templateName
      );

      setActiveEmailTemplate(null);
      setEmailTemplates(emailTemplates.toSpliced(templateIndex, 1));
    });

  const publishEmailTemplate = (template) =>
    fetchPublishEmailTemplate(template).then((newTemplateData) =>
      handleUpdateTemplate(template, newTemplateData)
    );

  return {
    activeEmailTemplate,
    setActiveEmailTemplate,
    getEmailTemplate,
    deleteEmailTemplate,
    postEmailTemplate,
    putEmailTemplate,
    emailTemplates,
    publishEmailTemplate,
  };
};
