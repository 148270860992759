import React, { useEffect } from "react";
import { useAuth } from "@boatlist/common/lib";

import styles from "./UnauthorizedPage.module.scss";

const UnauthorizedPage = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    sessionStorage.removeItem("boatlist-user");
    logout({ returnTo: window.location.origin });
  };

  return (
    <span className={styles["page"]}>
      <img
        src="https://boatlist.auction/images/boatlist_logo250_no_tm.png"
        alt="BoatList"
      />
      <div className={styles["text"]}>
        You are not authorized to view this page
      </div>
      <button className={styles["link"]} onClick={handleLogout}>
        Click here to login
      </button>
    </span>
  );
};

export default UnauthorizedPage;
