import React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "@boatlist/common/lib";

import { Dropdown } from "@boatlist/common/components";
import styles from "./AccountPopper.module.scss";

const AccountPopperTrigger = React.forwardRef((props, ref) => {
  const { user } = useAuth();
  return (
    <button
      type="button"
      className={styles["accountDropdown__trigger"]}
      ref={ref}
      {...props}
    >
      <img src={user.picture} />
    </button>
  );
});

const AccountPopper = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    sessionStorage.removeItem("boatlist-user");
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className={styles["accountDropdown"]}>
      <Dropdown
        Trigger={AccountPopperTrigger}
        contentClassName={styles["accountDropdown"]}
        triggerClasses={styles[""]}
      >
        <div className={styles["accountDropdown__content"]}>
          <Link to="/account" className={styles["accountDropdown__item"]}>
            Account
          </Link>
          <button
            type="button"
            onClick={handleLogout}
            className={styles["accountDropdown__item"]}
          >
            Logout
          </button>
        </div>
      </Dropdown>
    </div>
  );
};

export default AccountPopper;
