import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { createPortal } from "react-dom";

import styles from "./Modal.module.scss";

function createWrapperAndAppendToBody(id) {
  const wrapperElement = document.createElement("div");
  wrapperElement.setAttribute("id", id);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

const Modal = ({ id = "bl_modal_holder", children, onCancel }) => {
  const modalHolderClasses = cx("modal_holder open");

  let element = document.getElementById(id);

  // if element is not found with wrapperId,
  // create and append to body
  if (!element) {
    element = createWrapperAndAppendToBody(id);
  }
  return createPortal(
    <div className={styles["modal_holder"]}>
      <div className={styles["modal_window_holder"]}>
        <div className={styles["modal_window"]}>{children}</div>
      </div>
      <div className={styles["modal_background"]} onClick={onCancel} />
    </div>,
    element
  );
};

Modal.propTypes = {};

export default Modal;
