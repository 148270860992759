import { apiCall } from "@boatlist/common/lib";
import { deleteListABoatFiles, uploadListABoatFiles } from "./ListABoatApiCall";

export function updateMarketStartDate(startDateObject) {
  return apiCall(`/rest/listingstartdatev2`, {
    method: "PUT",
    body: JSON.stringify(startDateObject),
  });
}

export function createListing(listingObject, handleUploadProgress, photoFiles) {
  return new Promise((resolve, reject) => {
    apiCall(`/rest/listing`, {
      method: "POST",
      body: JSON.stringify(listingObject),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          if (photoFiles) {
            uploadListABoatFiles(
              photoFiles,
              json.listingId,
              handleUploadProgress
            ).then((res) => resolve(json.listingId));
          }
        }
      });
  });
}

export function updateListingCall(
  listingObject,
  handleUploadProgress,
  listingId,
  photoOG,
  shouldUpdateStartDate,
  photoFiles
) {
  const promises = [];
  return new Promise((resolve, reject) => {
    apiCall(`/rest/listing/${listingId}`, {
      method: "PUT",
      body: JSON.stringify(listingObject),
    }).then((res) => {
      if (res.ok) {
        if (shouldUpdateStartDate) {
          const dateTimeObject = {
            listingId,
            startDate: listingObject.startDate,
            listingLength: listingObject.listingLength,
          };
          // here push a promise
          const updateMarketDate = updateMarketStartDate(dateTimeObject);
          promises.push(updateMarketDate);
        }
        const deleteMatch = photoOG.filter(
          (file) => !photoFiles.find((image) => image.name === file.name)
        );
        if (deleteMatch.length !== 0) {
          const deleteArray = deleteMatch.map((image) => ({
            fileName: image.name,
          }));
          // here push a promise
          const deleteFiles = deleteListABoatFiles(deleteArray, listingId);
          promises.push(deleteFiles);
        }
        const match = photoFiles.filter(
          (file) => !photoOG.find((image) => image.name === file.name)
        );
        if (match.length !== 0) {
          // here push a promise
          const uploadFiles = uploadListABoatFiles(
            match,
            listingId,
            handleUploadProgress
          );
          promises.push(uploadFiles);
        } else {
          // setTimeout(
          //   () =>
          //     (window.location.href = `/marketplacepreview/?id=${listingId}`),
          //   2000
          // );
        }
        Promise.all(promises).then((res) => {
          if (listingId) {
            resolve(listingId);
          } else {
            window.location.href = `/auctions/${listingId}`;
          }
        });
      }
    });
  });
}
