import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { Spinner } from "@boatlist/common/components";
import { CONSTANTS } from "@/lib/core";
import { getUsers } from "@/lib/services";
import { selectAllUsers } from "@/store/slices/users";

import Layout from "@/components/common/Layout";
import Users from "@/components/users/Users";

import styles from "@/pages/UsersPage/UsersPage.module.scss";

const UsersPage = () => {
  const users = useSelector(selectAllUsers);

  useEffect(() => {
    getUsers({ page: 0, users: 20 });
  }, []);
  console.log(users);

  return users.length !== 0 ? (
    <Layout activePage={CONSTANTS.NAV.USERS}>
      <div className={styles["user-content"]}>
        <h2>Users Page</h2>
        <Users {...{ users }} />
      </div>
    </Layout>
  ) : (
    <Spinner />
  );
};

export default UsersPage;
