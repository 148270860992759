import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiCall, getConfig } from "@boatlist/common/lib";
import { BiArrowBack } from "react-icons/all.js";
import { useSelector } from "react-redux";
import { CONSTANTS } from "@/lib/core/index.js";

import Layout from "@/components/common/Layout";
import Label from "@/components/common/Label/index.js";
import Card from "@/components/common/Card/index.js";
import { selectActiveUser } from "@/store/slices/users.js";
import Tabs from "@/components/common/Tabs/index.js";
import AuctionFeed from "@/components/auctions/AuctionFeed/index.js";
import MarketUpdateTab from "@/pages/SingleMarketListingPage/MarketUpdateTab.jsx";
import SingleMarketDetailsCard from "./SingleMarketDetailsCard";

import styles from "./SingleMarketListingPage.module.scss";
import Button from "@/components/common/Button/index.js";
import DeleteListingModal from "@/components/market/DeleteListingModal/index.js";
import MarketManageTab from "./MarketManageTab";
import ViewPopper from "./ViewPopper";

const SingleMarketListingPage = () => {
  const user = useSelector(selectActiveUser);
  const { listingId } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState({});
  const [images, setImages] = useState(null);
  const [defaultTab, setDefaultTab] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    apiCall(`/rest/marketplacelisting/${listingId}`)
      .then((res) => res.json())
      .then((json) => setListing(json));
  }, []);

  const getListingStatusLabel = (status) => {
    switch (status) {
      case CONSTANTS.LISTING_STATUS.NEW:
        return (
          <Label variant="secondary" size="md">
            New
          </Label>
        );
      case CONSTANTS.LISTING_STATUS.ACTIVE:
        return (
          <Label variant="primary" size="md">
            Published
          </Label>
        );
      case CONSTANTS.LISTING_STATUS.LIVE:
        return (
          <Label variant="success" size="md">
            Active
          </Label>
        );
      case CONSTANTS.LISTING_STATUS.ENDED:
        return (
          <Label variant="danger" size="md">
            Ended
          </Label>
        );
      default:
        return (
          <Label variant="primary" size="md">
            Canceled
          </Label>
        );
    }
  };

  return (
    listing && (
      <Layout activePage={CONSTANTS.NAV.MARKET}>
        <div className={styles["auction-box"]}>
          <div className={styles["title"]}>
            <div className={styles["icon"]}>
              <button
                type="button"
                className={styles["link-button"]}
                onClick={() => navigate(-1)}
              >
                <BiArrowBack />
              </button>
            </div>
            <div className={styles["request-title"]}>{listing.title}</div>
            <div>{getListingStatusLabel(listing.listingStatus)}</div>
            <div className={styles["flex-grow"]} />
            {/* <a
              href={`${
                getConfig().base_content_url
              }/marketplacepreview/?id=${listingId}`}
              target="_blank"
              className={styles["link"]}
              rel="noreferrer"
            >
              View
            </a> */}
            <ViewPopper {...{ listing }}>View</ViewPopper>
          </div>
          <Card>
            <div className={styles["outside-box"]}>
              <SingleMarketDetailsCard {...{ listing }} />
              <div className={styles["flex-grow"]} />
              <img
                src={`https://s3.amazonaws.com/boatlist.uploads/${listing.listingId}%2F${listing.picture}`}
                className={styles["image"]}
                alt={listing.title}
              />
            </div>
            {/* <AuctionLabels {...{ listing }} /> */}
          </Card>
          <div className={styles["bottom-box"]}>
            <div className={styles["tabs"]}>
              <Tabs
                menu={[
                  { title: "Manage", value: 0 },
                  { title: "Update", value: 1 },
                  { title: "Settings", value: 2 },
                ]}
                {...{ defaultTab }}
              >
                <MarketManageTab {...{ listing, setListing }} />
                <MarketUpdateTab {...{ user, listingId }} />
              </Tabs>
            </div>
            <AuctionFeed auctionId={listingId} auction={listing} market />
          </div>
        </div>
      </Layout>
    )
  );
};

export default SingleMarketListingPage;
