import React, { useEffect } from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  withAuthenticationRequired,
  useAuth,
  versionCheck,
} from "@boatlist/common/lib";
import { selectActiveUser, setActiveUser } from "@/store/slices/users";
import { getUser } from "@/lib/services";
import pkg from "../../package.json";

import HomePage from "./HomePage";
import UsersPage from "./UsersPage";
import AccountPage from "./AccountPage";
import AuctionsPage from "./AuctionsPage";
import SingleUserPage from "./SingleUserPage";
import UnauthorizedPage from "./UnauthorizedPage";
import SingleAuctionPage from "./SingleAuctionPage";
import SingleAuctionRequestPage from "./SingleAuctionRequestPage";
import AuctionRequestPage from "./AuctionRequestPage";
import MarketPlacePage from "./MarketPlacePage";
import SingleMarketListingPage from "@/pages/SingleMarketListingPage";
import EmailsPage from "./EmailsPage/EmailsPage";

const Routes = () => {
  const { user: authUser, getAccessTokenSilently } = useAuth();
  const activeUser = useSelector(selectActiveUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    versionCheck(pkg.version);
  }, []);

  useEffect(() => {
    if (authUser && !activeUser) {
      getAccessTokenSilently().then((token) => {
        sessionStorage.setItem("token", token);
        getUser(authUser.sub);
      });
    }
  }, [authUser, activeUser]);

  useEffect(() => {
    if (
      activeUser &&
      !activeUser.roles.includes("admin") &&
      !window.location.href.includes("/unauthorized")
    ) {
      navigate("/unauthorized");
      // dispatch(setActiveUser(activeUser));
      // sessionStorage.setItem("boatlist-user", JSON.stringify(activeUser));
      // getFollowList(activeUser).then((json) => dispatch(addFollowList(json)));
      // getWatchList(activeUser).then((json) => dispatch(addWatchList(json)));
    }
  }, [activeUser]);

  return useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "/users", element: <UsersPage /> },
    { path: "/users/:userId", element: <SingleUserPage /> },
    { path: "/account", element: <AccountPage /> },
    { path: "/auctions", element: <AuctionsPage /> },
    { path: "/requests", element: <AuctionRequestPage /> },
    { path: "/auctions/:auctionId", element: <SingleAuctionPage /> },
    {
      path: "/requests/:requestId/:userId",
      element: <SingleAuctionRequestPage />,
    },
    { path: "/unauthorized", element: <UnauthorizedPage /> },
    { path: "/marketplace", element: <MarketPlacePage /> },
    { path: "/marketplace/:listingId", element: <SingleMarketListingPage /> },
    { path: "/emails", element: <EmailsPage /> },
  ]);
};

export default withAuthenticationRequired(Routes);
