import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import EmailEditor from "./EmailEditor";
import Button from "../../components/common/Button";
import styles from "./EmailsPage.module.scss";
import TextField from "../../components/common/TextField";
import Card from "../../components/common/Card";

const EmailTemplateForm = ({ activeEmailTemplate, onSubmit, onPublish }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: activeEmailTemplate,
  });
  const ref = useRef();

  useEffect(() => {
    reset(activeEmailTemplate);
  }, [activeEmailTemplate.templateName]);

  const publishHtml = (values) => {
    ref.current.editor.exportHtml((data) => {
      const { html, design } = data;
      onPublish({ ...values, status: "Published", html, design });
    });
  };

  const saveTemplate = (values) => {
    ref.current.editor.saveDesign((design) => {
      onSubmit({ ...values, design });
    });
  };

  return (
    <form className={styles["edit-email__form"]}>
      <Card>
        <h3 className={styles["edit-email__form-title"]}>Email</h3>
        <TextField
          error={errors && errors.subject?.message}
          title="Subject"
          name="subject"
          required="This field is required."
          {...{ register }}
        />
        <h3 className={styles["edit-email__form-title"]}>Template</h3>
        <TextField
          error={errors && errors.templateName?.message}
          title="Name"
          name="templateName"
          required="This field is required."
          {...{ register }}
        />
        <div className={styles["edit-email__button-group"]}>
          <Button onClick={handleSubmit(saveTemplate)}>
            {activeEmailTemplate.updatedTimestamp ? "Save" : "Create"}
          </Button>
          <Button onClick={handleSubmit(publishHtml)}>Publish</Button>
        </div>
      </Card>
      <div>
        <EmailEditor {...{ ref, template: activeEmailTemplate }} />
      </div>
    </form>
  );
};

EmailTemplateForm.propTypes = {
  activeEmailTemplate: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
};

export default EmailTemplateForm;
