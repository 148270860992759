import React, { useState } from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";
import FormDialog from "@/components/common/FormDialog";
import Card from "@/components/common/Card";
import Button from "@/components/common/Button";
import TextField from "@/components/common/TextField";

import { useForm } from "react-hook-form";
import styles from "./ResetPasswordModal.module.scss";

const ResetPasswordModal = ({ onCancel, handleResetSuccess, user, userId }) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const handleEmail = () => {
    console.log("Reset Email");
  };

  const onSubmit = (data) => {
    const passwordObject = {
      id: userId,
      password: data.newPassword,
    };

    apiCall(`/rest/userpassword`, {
      method: "PUT",
      body: JSON.stringify(passwordObject),
    }).then((res) => {
      if (res.ok) {
        handleResetSuccess();
      }
    });
  };

  const handleValidate = () => {
    if (getValues("newPassword") !== getValues("confirmPassword")) {
      return "Passwords must match.";
    }
    return "";
  };

  return (
    <FormDialog
      onCancel={onCancel}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      acceptText="Reset Password"
      variant="secondary"
      title="Reset Password"
      description={`Send password rest email to ${user.username}`}
    >
      <div className={styles["button-email"]}>
        <Button variant="primary" onClick={handleEmail}>
          Send Email
        </Button>
      </div>
      <Card variant="fit" color="primary">
        <div className={styles["second-title"]}>Reset New Password</div>
        Are you sure you want to reset {user.username} password?
        <TextField
          // onChange={(e) => handleChange("newPassword", e.target.value)}
          register={register}
          label="newPassword"
          title="New Temporary Password"
          required="This field is required."
          error={errors.newPassword?.message}
          validate={handleValidate}
        />
        {/* {!errors.newPassword?.message &&
          errors.newPassword?.type === "validate" && (
            <p className={styles["error-message"]}>Passwords must match.</p>
          )} */}
        <TextField
          // onChange={(e) => handleChange("confirmPassword", e.target.value)}
          title="Confirm Temporary Password"
          register={register}
          label="confirmPassword"
          required="This field is required."
          error={errors.confirmPassword?.message}
          validate={handleValidate}
        />
        {/* {!errors.confirmPassword?.message &&
          errors.confirmPassword?.type === "validate" && (
            <p className={styles["error-message"]}>Passwords must match.</p>
          )} */}
      </Card>
    </FormDialog>
  );
};

ResetPasswordModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleResetSuccess: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};

export default ResetPasswordModal;
