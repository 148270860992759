import * as toolkitRaw from "@reduxjs/toolkit";
const { createSlice } = toolkitRaw.default ?? toolkitRaw;

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    activeUser: null,
    authenticating: true,
  },
  reducers: {
    addUsers: (state, action) => {
      state.users = action.payload;
    },
    addUser: (state, action) => {
      state.users.push(action.payload);
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
      state.authenticating = false;
    },
    updateUser: (state, action) => {
      state.activeUser = { ...state.activeUser, ...action.payload };
    },
    updateUsers: (state, action) => {
      console.log(action.payload);
      state.users = { users: action.payload };
    },
    deleteUser: (state, action) => {
      console.log(state.users.users);
      const users = state.users.users.filter(
        (user) => user.user_id !== action.payload
      );
      state.users = { ...state.users, users };
    },
    completeAuthenticating: (state) => {
      state.authenticating = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addUsers,
  addUser,
  setActiveUser,
  updateUser,
  deleteUser,
  completeAuthenticating,
  updateUsers,
} = usersSlice.actions;

export const selectAllUsers = (state) => state.users.users;
export const selectActiveUser = (state) => state.users.activeUser;
export const selectAuthenticating = (state) => state.users.authenticating;

export default usersSlice.reducer;
