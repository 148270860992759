import React, { useEffect } from "react";
import TextField from "@/components/common/TextField/index.js";
import Select from "@/components/common/Select/index.js";
import Card from "@/components/common/Card";

import styles from "./MarketListingForm.module.scss";
import { classnames } from "classnames";

const BuyNowCard = ({ errors, updateListing, register, watch, setValue }) => {
  const buyNow = watch("buyNow");

  useEffect(() => {
    register("buyNow", { required: "This field is required." });
  }, [register]);

  const onChange = (value, name) => {
    setValue(name, Number(value));
  };

  return (
    <Card color={updateListing && "blue"}>
      <div className={styles["heading"]}>Listing</div>
      <div className={styles["button-holder"]}>
        <label>
          <Card>
            <div className={styles["radio_buttons"]}>
              <input
                type="radio"
                value={0}
                checked={buyNow === 0}
                onChange={(e) => onChange(e.target.value, "buyNow")}
              />
              Buy Now Only
            </div>
            <div className={styles["button-text"]}>
              Set a price for how much you’d sell for
            </div>
          </Card>
        </label>
      </div>
      <div className={styles["button-holder"]}>
        <label>
          <Card>
            <div className={styles["radio_buttons"]}>
              <input
                type="radio"
                value={2}
                checked={buyNow === 2}
                onChange={(e) => onChange(e.target.value, "buyNow")}
              />
              Auction Only
            </div>
            <div className={styles["button-text"]}>
              Set a reserve for the minimum bid you’ll accept
            </div>
          </Card>
        </label>
      </div>
      <div className={styles["button-holder"]}>
        <label>
          <Card>
            <div className={styles["radio_buttons"]}>
              <input
                type="radio"
                value={1}
                checked={buyNow === 1}
                onChange={(e) => onChange(e.target.value, "buyNow")}
              />
              Buy Now till Auction
            </div>
            <div className={styles["button-text"]}>
              If no one offers the Buy Now price, set the listing to an auction
              after the start date
            </div>
          </Card>
        </label>
      </div>
      {errors.buyNow?.message && (
        <div className="input__error-message">{errors.buyNow?.message}</div>
      )}
      <div className="hold_2 form_holder">
        {(buyNow === 1 || buyNow === 0) && (
          <TextField
            title="Price"
            type="text"
            register={register}
            name="price"
            required="This field is required."
            error={errors.price?.message}
            // onChange={(e) => handleInput(e.target.value, "price")}
          />
        )}
        {(buyNow === 2 || buyNow === 1) && (
          <TextField
            title="Reserve"
            type="text"
            // onChange={(e) => handleInput(e.target.value, "reserve")}
            register={register}
            name="reserve"
            required="This field is required."
            error={errors.reserve?.message}
          />
        )}
      </div>
      {(buyNow === 2 || buyNow === 1) && (
        <div className="hold_2 form_holder">
          <TextField
            title="Starting Bid"
            type="text"
            // onChange={(e) => handleInput(e.target.value, "startingBid")}
            register={register}
            name="startingBid"
            required="This field is required."
            error={errors.startingBid?.message}
          />
          <Select
            label="Duration"
            onChange={(e) => onChange(e.target.value, "listingLength")}
            required="This field is required."
            error={errors.listingLength?.message}
          >
            <option value="" />
            <option value={10}>10 days</option>
            <option value={20}>20 days</option>
            <option value={30}>30 days</option>
          </Select>
        </div>
      )}
      {(buyNow || buyNow === 0) && (
        <label>
          <div className="checkbox">
            <input
              type="checkbox"
              checked={watch("allowOffers")}
              {...register("allowOffers")}
              // onChange={(e) => handleInput(e.target.checked, "allowOffers")}
            />
            Allow Offers
          </div>
        </label>
      )}
    </Card>
  );
};

export default BuyNowCard;
