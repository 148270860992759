import React from "react";
import PropTypes from "prop-types";
import Modal from "@/components/common/Modal/index.js";
import Card from "@/components/common/Card/index.js";
import styles from "./FormDialog.module.scss";
import Button from "@/components/common/Button/index.js";

const FormDialog = ({
  onCancel,
  title,
  acceptText,
  description,
  children,
  variant,
  onSubmit,
  handleSubmit,
}) => {
  return (
    <Modal id="root-portal-container" onCancel={onCancel}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <div className={styles["dialog-children"]}>
            <div className={styles["title"]}>{title}</div>
            <div className={styles["description"]}>{description}</div>
            {children}
          </div>
          <div className={styles["button-holder"]}>
            <Button variant="gray" outline onClick={onCancel} margin>
              Cancel
            </Button>
            <Button variant={variant} type="submit">
              {acceptText}
            </Button>
          </div>
        </Card>
      </form>
    </Modal>
  );
};

FormDialog.defaultProps = {
  title: null,
  description: null,
};

FormDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  title: PropTypes.string,
  acceptText: PropTypes.string.isRequired,
  description: PropTypes.string,
  variant: PropTypes.string.isRequired,
};

export default FormDialog;
