import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";

import Button from "@/components/common/Button";
import MarketListingCategoriesForm from "@/components/forms/MarketListingForm/MarketListingCategoriesForm.jsx";
import styles from "./MarketListingForm.module.scss";
import TitleAndDescription from "./TitleAndDescription";
import BuyNowCard from "./BuyNowCard";
import ContentCard from "./ContentCard";
import ScheduleCard from "./ScheduleCard";
import {
  createListing,
  publishListing,
  updateListingCall,
} from "../../../lib/services/MarketListingApiCalls";
import { useForm } from "react-hook-form";

const MarketListingForm = ({
  user,
  setShowForm,
  updateListing,
  listingId,
  setUpdateMessage,
}) => {
  const [categories, setCategories] = useState(null);
  const [fields, setFields] = useState(null);
  const [schema, setSchema] = useState(null);
  const [photoOG, setPhotoOG] = useState(null);
  const [listingOG, setListingOG] = useState({});
  const [buttonFlag, setButtonFlag] = useState(null);
  const nowTime = new Date().getTime();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categoryAttributes: {},
    },
  });

  useEffect(() => {
    if (updateListing) {
      apiCall(`/rest/marketplacelisting/${listingId}`)
        .then((res) => res.json())
        .then((json) => {
          const timeStamp = new Date(json.startDate);
          const year = timeStamp.getFullYear();
          const month = `0${timeStamp.getMonth() + 1}`.slice(-2);
          const date = `0${timeStamp.getDate()}`.slice(-2);
          const startDate = `${year}-${month}-${date}`;

          let hours = timeStamp.getHours();
          let minutes = timeStamp.getMinutes();

          if (minutes < 10) {
            minutes = `0${minutes}`;
          }
          if (hours < 10) {
            hours = `0${hours}`;
          }

          const startTime = `${hours}:${minutes}`;

          let photoSrc = [];
          if (json.photos) {
            json.photos.forEach((photo) =>
              photoSrc.push({
                name: photo,
                source: `https://s3.amazonaws.com/boatlist.uploads/${listingId}%2F${photo}`,
              })
            );
          } else {
            photoSrc = [];
          }

          // setListing({
          //   ...json,
          //   startDate,
          //   photos: photoSrc,
          //   startTime,
          // });
          reset({
            ...json,
            startDate,
            photos: photoSrc,
            startTime,
          });
          setListingOG({
            ...json,
            photos: photoSrc,
            startDate,
            startTime,
          });
          setPhotoOG(photoSrc);
        });
    }
  }, [updateListing]);

  const handleUploadProgress = ({ done }) => {
    if (done) {
      // setShowForm(false);
    }
  };

  const handleFormAction = (formAction, id, listingObject) => {
    const nowDate = new Date().toISOString();
    const futureDate = listingObject.startDate > nowDate;
    let publishPhotos = true;
    const photoNames = listingObject.photos.map((photo) => photo.name);

    switch (formAction) {
      case "submit":
      case "preview":
        window.location.href = `/marketplace/${id}`;
        break;
      case "publish":
        publishListing(id, futureDate, publishPhotos);
        break;
      case "publishUpdate":
        publishListing(id, futureDate, publishPhotos).then((res) => {
          if (res.ok) {
            setUpdateMessage(true);
          }
        });
        break;
      case "update":
        if (
          JSON.stringify(listingOG.photos) !==
            JSON.stringify(listingObject.photos) &&
          listingOG.listingStatus === 2
        ) {
          apiCall(`/rest/marketplacelisting-pictures/${id}`, {
            method: "POST",
          }).then((res) => {
            if (res.ok) {
              setUpdateMessage(true);
            }
          });
        }
        setUpdateMessage(true);
        break;
      case "relist":
        if (
          JSON.stringify(photoNames) === JSON.stringify(listingObject.photos)
        ) {
          publishPhotos = false;
        }
        publishListing(id, null, publishPhotos).then((res) => {
          if (res.ok) {
            window.location.href = `/marketplace/${id}`;
          }
        });
        break;
      default:
        window.location.href = `/marketplace`;
        break;
    }
  };

  const handleSubmitCall = (data, formAction) => {
    const photoNames = data.photos.map((photo) => photo.name);

    const listingObject = {
      ...data,
      listingId: undefined,
      userId: user.id,
      username: user.username,
      picture: data.photos[0].name,
      photos: photoNames,
      videos: data.videos,
    };
    if (data.startDate) {
      listingObject.startDate = new Date(
        `${data.startDate}T${data.startTime}`
      ).toISOString();
    } else {
      listingObject.startDate = new Date().toISOString();
    }

    const photoFiles = data.photos;

    createListing(listingObject, handleUploadProgress, photoFiles).then(
      (id) => {
        handleFormAction(formAction, id, listingObject);
      }
    );
  };

  const handleUpdate = (data, formAction) => {
    const expTimeStamp = new Date(listingOG.expirationDate).getTime();
    const diffInTime = expTimeStamp - nowTime;
    let shouldUpdateStartDate = false;

    const photoNames = data.photos.map((photo) => photo.name);

    const listingObject = {
      ...data,
      listingId: undefined,
      userId: user.id,
      username: user.username,
      picture: data.photos[0].name,
      photos: photoNames,
      videos: data.videos,
    };

    // new start date create the start date utc the way in the submit
    const newStartDate = new Date(
      `${data.startDate}T${data.startTime}`
    ).toISOString();

    if (
      newStartDate !== listingOG.startDate
      // check outside update listing pass shouldUpdateStartDate add photoFiles
    ) {
      shouldUpdateStartDate = true;
      listingObject.startDate = newStartDate;
    }
    if (!shouldUpdateStartDate && diffInTime < 0) {
      shouldUpdateStartDate = true;
      listingObject.startDate = new Date().toISOString();
    }

    const photoFiles = data.photos;
    // check for change before call
    if (listingId) {
      if (
        JSON.stringify(data) !== JSON.stringify(listingOG) ||
        shouldUpdateStartDate
      ) {
        updateListingCall(
          listingObject,
          handleUploadProgress,
          listingId,
          photoOG,
          shouldUpdateStartDate,
          photoFiles
        ).then((id) => {
          handleFormAction(formAction, id, listingObject);
        });
      }
    }
  };

  useEffect(() => {
    apiCall(`/rest/marketplaceschemas`)
      .then((res) => res.json())
      .then((json) => {
        setFields(
          json.find((item) => item.categoryId === 0 && item.categorySchema)
        );
        setCategories(
          json.filter((item) => item.categoryId !== 0 && item.categorySchema)
        );
      });
  }, []);

  const onSubmit = (data) => {
    switch (buttonFlag) {
      case "submit":
        handleSubmitCall(data, buttonFlag);
        break;
      case "publish":
        handleSubmitCall(data, buttonFlag);
        break;
      case "update":
        handleUpdate(data, buttonFlag);
        break;
      case "publishUpdate":
        handleUpdate(data, buttonFlag);
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles["market-form"]}>
        {/* <Card color={updateListing && "blue"}> */}
        {updateListing && (
          <h2 className={styles["market-title-update"]}>
            Market Listing Update
          </h2>
        )}
        <div className={styles["margin"]}>
          <TitleAndDescription
            {...{
              errors,
              updateListing,
              register,
              setValue,
              getValues,
            }}
          />
        </div>
        <div className={styles["margin"]}>
          <BuyNowCard
            {...{
              errors,
              updateListing,
              register,
              watch,
              setValue,
            }}
          />
        </div>
        <div className={styles["margin"]}>
          <ScheduleCard
            {...{
              errors,
              updateListing,
              register,
            }}
          />
        </div>
        <div className={styles["margin"]}>
          <MarketListingCategoriesForm
            {...{
              categories,
              schema,
              errors,
              setSchema,
              updateListing,
              register,
              setValue,
              getValues,
            }}
          />
        </div>
        <ContentCard
          {...{
            errors,
            updateListing,
            register,
            setValue,
            watch,
          }}
        />
        <div className={styles["button-holder"]}>
          {updateListing ? (
            <>
              <Button
                variant="primary"
                margin
                onClick={() => setButtonFlag("update")}
              >
                Save
              </Button>
              <Button
                variant="primary"
                margin
                onClick={() => setButtonFlag("publishUpdate")}
              >
                Publish
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="primary"
                margin
                onClick={() => setButtonFlag("submit")}
              >
                Submit
              </Button>
              <Button
                variant="primary"
                margin
                onClick={() => setButtonFlag("publish")}
              >
                Publish
              </Button>
            </>
          )}
          {!updateListing && (
            <Button variant="gray" outline onClick={() => setShowForm(false)}>
              Cancel
            </Button>
          )}
        </div>
        {/* </Card> */}
      </div>
    </form>
  );
};

MarketListingForm.propTypes = {
  user: PropTypes.object.isRequired,
};

export default MarketListingForm;
