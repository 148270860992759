import React from "react";
import PropTypes from "prop-types";

import Card from "@/components/common/Card/index.js";
import styles from "@/pages/SingleUserPage/SingleUserPage.module.scss";

const UserDetailsCard = ({ user }) => {
  const handleDate = (login) => {
    if (login) {
      const date = new Date(login).toLocaleString("en-us", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      return date;
    }

    return "Never logged in";
  };

  return (
    <Card>
      <div className={styles["user-details"]}>User Details</div>
      <Card color="primary">
        <div className={styles["details-header"]}>User ID</div>
        <div className={styles["details-content"]}>{user.id}</div>
        <div className={styles["details-header"]}>Username</div>
        <div className={styles["details-content"]}>{user.username}</div>
        <div className={styles["details-header"]}>Email</div>
        <div className={styles["details-content"]}>{user.email}</div>
        <div className={styles["details-header"]}>Joined</div>
        <div className={styles["details-content"]}>
          {handleDate(user.membersince)}
        </div>
        <div className={styles["details-header"]}>Last Login</div>
        <div className={styles["details-content"]}>
          {handleDate(user.lastlogin)}
        </div>
        <div className={styles["details-header"]}>Role</div>
        <div className={styles["details-content"]}>{user.roles}</div>
      </Card>
    </Card>
  );
};

UserDetailsCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserDetailsCard;
