import * as toolkitRaw from "@reduxjs/toolkit";
const { createSlice } = toolkitRaw.default ?? toolkitRaw;

export const bidsSlice = createSlice({
  name: "bids",
  initialState: {
    bids: [],
  },
  reducers: {
    addBids: (state, action) => {
      state.bids = action.payload;
    },
    addBid: (state, action) => {
      state.bids.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addBids, addBid } = bidsSlice.actions;

export const selectAllBids = (state) => state.bids.bids;

export default bidsSlice.reducer;
