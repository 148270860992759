import React from "react";
import PropTypes from "prop-types";

import styles from "./PaymentInfo.module.scss";
import Card from "@/components/common/Card";

const PaymentInfo = ({ user }) => {
  return (
    <Card color="primary">
      <div className={styles["info-boxes"]}>
        <div className={styles["title"]}>Payment Info</div>
        <div className={styles["payment-group"]}>
          <div className={styles["payment-body"]}>Name on Card</div>
          <div className={styles["payment-input"]}>
            {user.firstname} {user.lastname}
          </div>
        </div>
        <div className={styles["payment-group"]}>
          <div className={styles["payment-body"]}>Card Ending In:</div>
          <div className={styles["payment-input"]}>***{user.cclast4}</div>
        </div>
        <div className={styles["payment-group"]}>
          <div className={styles["payment-body"]}>Exp:</div>
          <div className={styles["payment-input"]}>
            {user.ccexp.slice(0, 2)}/{user.ccexp.slice(2, 4)}
          </div>
        </div>
        <div className={styles["payment-group"]}>
          <div className={styles["payment-body"]}>Address:</div>
          <div className={styles["payment-input"]}>{user.address}</div>
        </div>
        <div className={styles["payment-group"]}>
          <div className={styles["payment-body"]}>City:</div>
          <div className={styles["payment-input"]}>{user.city}</div>
        </div>
        <div className={styles["payment-group"]}>
          <div className={styles["payment-body"]}>State:</div>
          <div className={styles["payment-input"]}>{user.state}</div>
        </div>
        <div className={styles["payment-group"]}>
          <div className={styles["payment-body"]}>Zip:</div>
          <div className={styles["payment-input"]}>{user.zip}</div>
        </div>
      </div>
      <div className={styles["payment-group"]}>
        <div className={styles["payment-body"]}>Phone:</div>
        <div className={styles["payment-input"]}>{user.ccphone}</div>
      </div>
    </Card>
  );
};

PaymentInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default PaymentInfo;
