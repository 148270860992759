import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./Tabs.module.scss";

const Tabs = ({ addAnchorLinks, children, defaultTab, menu }) => {
  const [activeTab, setActiveTab] = useState(0);

  const getActiveTabName = () => {
    const tab = menu.find((item) => item.value === activeTab);

    return `active-tab__${tab.title.toLowerCase().split(" ").join("-")}`;
  };

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  const handleTabClick = (item) => {
    if (addAnchorLinks) {
      const url = window.location.href.split("#")[0];
      window.location.assign(
        `${url}#${item.title.toLowerCase().split(" ").join("-")}`
      );
    }
    setActiveTab(item.value);
  };

  return (
    <div className={cx(styles["tabs"], getActiveTabName())}>
      <div className={styles["tabs__menu"]}>
        {menu.map((item) => (
          <button
            type="button"
            key={item.value}
            onClick={() => handleTabClick(item)}
            className={cx(
              item.value === activeTab && styles["tabs__menu-item--active"],
              styles["tabs__menu-item"]
            )}
          >
            {item.title}
          </button>
        ))}
      </div>
      {children[activeTab]}
    </div>
  );
};

Tabs.defaultProps = {
  addAnchorLinks: false,
  defaultTab: 0,
  menu: [],
};

Tabs.propTypes = {
  addAnchorLinks: PropTypes.bool,
  children: PropTypes.node.isRequired,
  defaultTab: PropTypes.number,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
};

export default Tabs;
