import React, { useEffect, useState } from "react";
import { apiCall } from "@boatlist/web/src/lib/core/index.js";
import Select from "@/components/common/Select/index.js";
import Button from "@/components/common/Button/index.js";
import styles from "@/components/forms/MarketListingForm/MarketListingForm.module.scss";

const MarketBarFilter = ({
  setFilter,
  filter,
  localCategory,
  setLocalCategory,
  localSubcategory,
  setLocalSubcategory,
}) => {
  const [categories, setCategories] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    apiCall(`/rest/marketplaceschemas`)
      .then((res) => res.json())
      .then((data) =>
        setCategories(
          data.filter((item) => item.categoryId !== 0 && item.categorySchema)
        )
      );
  }, []);

  const handleSelect = (value) => {
    setSchema(
      categories.filter((category) => {
        if (category.categoryId === Number(value))
          return category.categorySchema;
      })
    );
    setLocalCategory(Number(value));
  };

  const handleInput = (value) => {
    setFilter({ ...filter, category: [value] });
  };

  const handleField = (item) => {
    console.log(item);
    const newArray = item.split(",");
    setSubCategory(newArray);
    setLocalSubcategory(newArray);
  };

  useEffect(() => {
    if (categories) {
      if (localCategory) {
        setSchema(
          categories.filter((category) => {
            if (category.categoryId === localCategory)
              return category.categorySchema;
          })
        );
        setSubCategory(localSubcategory);
      }
    } else {
      setSchema(null);
    }
  }, [categories]);

  useEffect(() => {
    if (localCategory === "") {
      setSubCategory(null);
    }
  }, [localCategory]);

  const handleValues = (values) => {
    const newValues = values.map((value) => {
      if (value.name) {
        return value.name;
      }
      return value;
    });
    return newValues;
  };

  return (
    <>
      <Select
        value={localCategory}
        onChange={(e) => handleSelect(e.target.value)}
      >
        <option value selected>
          Select Category
        </option>
        {categories &&
          categories.map((type) => (
            <option key={type.categoryId} value={type.categoryId}>
              {type.categoryName}
            </option>
          ))}
      </Select>
      <Select
        value={localSubcategory}
        onChange={(e) => handleField(e.target.value)}
      >
        <option value selected>
          Select Type
        </option>
        {schema &&
          schema[0].categorySchema
            // (filterType) => handleSecondSelect(filterType)
            .filter(
              (filterType) =>
                filterType.type === "list" || filterType.type === "fieldlist"
            )
            .map((type) => (
              <option key={type.label} value={handleValues(type.values)}>
                {type.label}
              </option>
            ))}
      </Select>
      {subCategory && (
        <Select
          value={filter["category"]}
          onChange={(e) => handleInput(e.target.value)}
        >
          <option value="" />
          {subCategory.map((value) => (
            <option value={value}>{value}</option>
          ))}
        </Select>
      )}
    </>
  );
};

export default MarketBarFilter;
