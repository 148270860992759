import React, { useEffect, useRef, useState } from "react";
import { apiCall, getConfig } from "@boatlist/common/lib";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/all.js";
import { CONSTANTS } from "@/lib/core/index.js";

import Layout from "@/components/common/Layout";
import Label from "@/components/common/Label";
import Card from "@/components/common/Card";
import RequestDetails from "@/pages/SingleAuctionRequestPage/RequestDetails.jsx";
import OwnersDetail from "@/pages/SingleAuctionRequestPage/OwnersDetail.jsx";
import AuctionLabels from "@/components/auctions/AuctionLabels";

import styles from "./SingleAuctionRequestPage.module.scss";
import Tabs from "@/components/common/Tabs";
import AuctionSubmitForm from "@/components/auctions/AuctionSubmitForm";
import UpdateMessage from "../SingleMarketListingPage/UpdateMessage";

const SingleAuctionRequestPage = () => {
  const { requestId } = useParams();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [request, setRequest] = useState();
  const messageRef = useRef();
  const [updateMessage, setUpdateMessage] = useState(null);
  const [defaultTab, setDefaultTab] = useState(0);

  useEffect(() => {
    apiCall(`/rest/listingrequest/${userId}?requestId=${requestId}`)
      .then((res) => res.json())
      .then((json) => setRequest(json));
  }, []);

  const getListingStatusLabel = (requestStatus) => {
    switch (requestStatus) {
      case CONSTANTS.REQUEST_STATUS.NEW:
        return (
          <Label variant="secondary" size="md">
            New
          </Label>
        );
      case CONSTANTS.REQUEST_STATUS.APPROVED:
        return (
          <Label variant="primary" size="md">
            Approved
          </Label>
        );
      case CONSTANTS.REQUEST_STATUS.REJECTED:
        return (
          <Label variant="success" size="md">
            Rejected
          </Label>
        );
      case CONSTANTS.REQUEST_STATUS.LISTED:
        return (
          <Label variant="danger" size="md">
            Listed
          </Label>
        );
      default:
        return (
          <Label variant="primary" size="md">
            Canceled
          </Label>
        );
    }
  };
  return (
    request && (
      <Layout activePage={CONSTANTS.NAV.REQUESTS}>
        <div className={styles["request-content"]}>
          <div className={styles["title-box"]}>
            <div className={styles["icon"]}>
              <button
                className={styles["link-button"]}
                onClick={() => navigate(-1)}
              >
                <BiArrowBack />
              </button>
            </div>
            <div className={styles["request-title"]}>
              {request.brandName ? request.brandName : request.brandOther}{" "}
              {request.modelName ? request.modelName : request.modelOther}
            </div>
            <div>{getListingStatusLabel(request.requestStatus)}</div>
          </div>
          <Card variant="fit">
            <div className={styles["request-box"]}>
              <RequestDetails {...{ request }} />
              <OwnersDetail {...{ request }} />
            </div>
            <AuctionLabels auction={request} />
          </Card>
          <div className={styles["bottom-box"]}>
            <UpdateMessage {...{ updateMessage, messageRef }} />
            <div className={styles["tabs"]}>
              <Tabs
                menu={[
                  { title: "Manage", value: 0 },
                  { title: "Update", value: 1 },
                  { title: "Settings", value: 2 },
                ]}
                {...{ defaultTab }}
              >
                <div />
                <AuctionSubmitForm
                  requestInformation={request}
                  update
                  {...{ setUpdateMessage }}
                />
              </Tabs>
            </div>
          </div>
        </div>
      </Layout>
    )
  );
};

export default SingleAuctionRequestPage;
