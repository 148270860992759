import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import styles from "./TextEditor.module.scss";

import PropTypes from "prop-types";

const TextEditor = ({ label, error, value, onChange, selector }) => {
  return (
    <div>
      <div className={styles["label"]}>{label}</div>
      <div className={styles["textBox"]}>
        <Editor
          value={value}
          init={{
            selector,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "preview",
              "help",
              "wordcount",
            ],
            menubar: false,
            toolbar: "bold italic image",
            paste_data_images: true,
          }}
          onEditorChange={onChange}
          apiKey="8fowkhfomkce36ubl3thhtbdwfl0exg8ab6n3901evkejl12"
        />
      </div>
      {error && <div className={styles["error-message"]}>{error}</div>}
    </div>
  );
};

export default TextEditor;
