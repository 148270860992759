import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TextEditor from "@/components/common/TextEditor";
import Card from "@/components/common/Card";
import ImagePicker from "./ImagePicker";
import styles from "./AuctionsPage.module.scss";

const FormImagePicker = ({ images, imageField }) => {
  const { register, getValues, setValue, formState } = useFormContext();
  const { errors } = formState;
  const selectedImageName = getValues(
    `categoryDescriptions.${imageField.name}`
  );

  useEffect(() => {
    // only the first image should be required
    if (imageField.name === "picture1") {
      register(`categoryDescriptions.${imageField.name}`, {
        required: "This field is required.",
      });
    } else {
      register(`categoryDescriptions.${imageField.name}`);
    }
  }, []);

  const handleChange = (newImage) => {
    setValue(`categoryDescriptions.${imageField.name}`, newImage.name);
  };

  return (
    <div>
      <div>{imageField.label}</div>
      <ImagePicker {...{ images, handleChange, selectedImageName }} />
      <div className={styles["error-message"]}>
        {errors?.categoryDescriptions?.[imageField.name]?.message}
      </div>
    </div>
  );
};

const FormTextEditor = ({ description, ...rest }) => {
  const { register, getValues, setValue, formState } = useFormContext();
  const { errors } = formState;
  const longText = getValues(`categoryDescriptions.${description.name}`);

  useEffect(() => {
    // only the first description should be required
    if (description.name === "longText1") {
      register(`categoryDescriptions.${description.name}`, {
        required: "This field is required.",
      });
    } else {
      register(`categoryDescriptions.${description.name}`);
    }
  }, []);

  const onChange = (value, name) => {
    setValue(`categoryDescriptions.${name}`, value);
  };

  return (
    <TextEditor
      label={description.label}
      value={longText}
      error={errors?.categoryDescriptions?.[description.name]?.message}
      onChange={(value) => onChange(value, description.name)}
      {...rest}
    />
  );
};

const TitleAndDescription = ({
  errors,
  updateListing,
  register,
  setValue,
  getValues,
  watch,
  descriptions,
}) => {
  const images = watch("photos");
  console.log(errors);

  return (
    <Card color={updateListing && "blue"}>
      <h3>Description</h3>

      {descriptions &&
        descriptions.map((description) =>
          description.name.includes("longText") ? (
            <FormTextEditor {...{ description }} />
          ) : (
            <FormImagePicker imageField={description} {...{ images }} />
          )
        )}

      {/* <ImagePicker {...{ images, setValue, image1 }} /> */}
      {/* <TextField
      title="Long Description"
      type="text"
      value={listing.longText}
      onChange={(e) => handleInput(e.target.value, "longText")}
      error={error.longText}
    /> */}
      {/* <TextEditor
        label="Long Text"
        value={longText}
        error={errors.longText?.message}
        onChange={(value) => onChange(value, "longText")}
      /> */}
    </Card>
  );
};

export default TitleAndDescription;
