import * as toolkitRaw from "@reduxjs/toolkit";
const { createSlice } = toolkitRaw.default ?? toolkitRaw;

const getIdsAndBoats = (payload) =>
  payload.reduce(
    (obj, watchItem) => {
      if (watchItem) {
        obj.ids.push(watchItem.listingId);
        obj.boats.push(watchItem);
      }
      return obj;
    },
    { ids: [], boats: [] }
  );

export const watchListSlice = createSlice({
  name: "watchList",
  initialState: {
    ids: [],
    boats: [],
  },
  reducers: {
    addWatchList: (state, action) => {
      const { ids, boats } = getIdsAndBoats(action.payload);
      state.ids = ids;
      state.boats = boats;
    },
    setWatchListIds: (state, action) => {
      state.ids = action.payload;
    },
    setWatchList: (state, action) => {
      const { ids, boats } = getIdsAndBoats(action.payload);
      state.ids = ids;
      state.boats = boats;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addWatchList, setWatchListIds } = watchListSlice.actions;

export const selectWatchListBoats = (state) => state.watchList.boats;
export const selectWatchListIds = (state) => state.watchList.ids;
// export const selectFollowByIdAndName = (name, id) => (state) =>
//   state.watchList.watchList[name] &&
//   state.watchList.watchList[name].includes(id);

export default watchListSlice.reducer;
