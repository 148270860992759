import React from "react";
import classnames from "classnames";
import styles from "./IconButton.module.scss";
import PropTypes from "prop-types";

const IconButton = ({ variant, onClick, children, hover, noPadding, size }) => {
  const buttonClasses = classnames(
    styles["button"],
    styles[`button__${variant}`],
    noPadding && styles[`button--noPadding`],
    styles[`button__size-${size}`]
  );

  return (
    <button className={buttonClasses} onClick={onClick}>
      {children}
    </button>
  );
};

IconButton.defaultProps = {
  active: false,
  variant: "primary",
};

IconButton.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "danger"]),
  onClick: PropTypes.func,
  active: PropTypes.bool,
  children: PropTypes.string,
};

export default IconButton;
