import * as toolkitRaw from "@reduxjs/toolkit";
const { createSlice } = toolkitRaw.default ?? toolkitRaw;

export const followListSlice = createSlice({
  name: "followList",
  initialState: {
    ids: {},
    follows: {},
  },
  reducers: {
    addFollowList: (state, action) => {
      const newFollowList = {};

      action.payload.forEach((followItem) => {
        if (followItem) {
          const { followType, followId, name } = followItem;

          if (newFollowList[followType]) {
            newFollowList[followType].push({
              id: followId,
              followName: name,
              checked: true,
            });
          } else {
            newFollowList[followType] = [
              {
                id: followId,
                followName: name,
                checked: true,
              },
            ];
          }
        }
      });
      state.follows = newFollowList;

      const newFollowIdList = {};

      action.payload.forEach((followItem) => {
        const { followType, followId } = followItem;

        if (newFollowIdList[followType]) {
          newFollowIdList[followType].push(followId);
        } else {
          newFollowIdList[followType] = [followId];
        }
      });
      state.ids = newFollowIdList;
    },
    setFollowListIds: (state, action) => {
      state.ids = action.payload;
    },
    setFollowList: (state, action) => {
      state.follows = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addFollowList, setFollowListIds } = followListSlice.actions;

export const selectFollowList = (state) => state.followList.follows;
export const selectFollowListIds = (state) => state.followList.ids;
export const selectFollowByIdAndName = (name, id) => (state) =>
  state.followList.followList[name] &&
  state.followList.followList[name].includes(id);

export default followListSlice.reducer;
