import store from "@/store";
// import { rest } from "msw";
import { apiCall } from "@boatlist/common/lib";
import {
  setActiveListing,
  updateActiveListing,
  addListings,
} from "@/store/slices/listings";
import { addQuestion } from "@/store/slices/questions";

// listings API calls

const fetchGetListings = () => {
  return apiCall(`/rest/listings`).then((res) => res.json());
};

const fetchGetListing = (listingId) => {
  return apiCall(`/rest/listing/${listingId}`).then((res) => res.json());
};

const fetchGetSellerListing = (listingId) => {
  return apiCall(`/rest/listing/seller/${listingId}`).then((res) => res.json());
};

const fetchPutListingReserve = (reserveObject) =>
  apiCall(`/rest/listing/reserve/`, {
    method: "PUT",
    body: JSON.stringify(reserveObject),
  }).then((res) => {
    if (res.ok) return res;
    return res.json();
  });

const fetchGetListingWatchers = (listingId) => {
  return apiCall(`/rest/watchesbylistingtotal/${listingId}`).then((res) =>
    res.json()
  );
};

// handle API calls

const handleGetListings = (res) => {
  return new Promise((resolve, reject) => {
    if (res.error) {
      reject(new Error(res.error));
    } else {
      store.dispatch(addListings(res));
      resolve(res);
    }
  });
};

const handleGetListing = (res) => {
  return new Promise((resolve, reject) => {
    if (res.error) {
      reject(new Error(res.error));
    } else {
      store.dispatch(setActiveListing(res));
      resolve(res);
    }
  });
};

const handlePutListingReserve = (res, reserveObject) => {
  return new Promise((resolve, reject) => {
    if (res.error) {
      reject(new Error(res.error));
    } else {
      store.dispatch(updateActiveListing(reserveObject));
      resolve();
    }
  });
};

export const handlePostQuestion = (res) => {
  return new Promise((resolve, reject) => {
    if (res.error) {
      reject(new Error(res.error));
    } else {
      store.dispatch(addQuestion(res));
      resolve(res);
    }
  });
};

const handleGetListingWatcher = (res) => {
  return new Promise((resolve, reject) => {
    if (res.error) {
      reject(new Error(res.error));
    } else {
      store.dispatch(updateActiveListing({ numOfWatchers: res }));
      resolve();
    }
  });
};

// error handling

const handleErrors = (error) => {
  console.log("Listing Error: ", error);
  throw error;
};

// public API calls

export const getListingWatchers = (listingId) => {
  fetchGetListingWatchers(listingId)
    .then(handleGetListingWatcher)
    .catch(handleErrors);
};

export const getListings = () =>
  fetchGetListings().then(handleGetListings).catch(handleErrors);

export const getListing = (listingId, user) => {
  let givenUser = user;
  // if user not provided, get from redux
  if (typeof givenUser === "undefined") {
    givenUser = store.getState().users?.activeUser;
  }
  // if the user if logged in, fetch seller listing
  if (givenUser) {
    return fetchGetSellerListing(listingId)
      .then(handleGetListing)
      .catch(handleErrors);
  }
  return fetchGetListing(listingId).then(handleGetListing).catch(handleErrors);
};

export const putListingReserve = (reserveObject) =>
  fetchPutListingReserve(reserveObject)
    .then((res) => handlePutListingReserve(res, reserveObject))
    .catch(handleErrors);

export function getActiveListings() {
  return apiCall("/rest/listingsactive/").then((res) => res.json());
}

export function getListingById(listingId) {
  return apiCall(`/rest/listing/${listingId}`).then((res) => res.json());
}

// test handlers

// export const listingHandlers = [
//   rest.get("rest/listings", async (res, req, ctx) => {
//     return res(
//       ctx.json([
//         {
//           modelId: 19200,
//           shortText:
//             "2020 Wellcraft 352, triple Yamaha 300hp motors, 302 hours, warranty through 5/23, Simrad, JL Audio, marine head, tri-axle Magic Tilt Trailer.",
//           expirationDate: null,
//           categoryName: "Center Consoles",
//           dateTime: null,
//           listingId: 3180,
//           pageURL: "/auctions/3180/",
//           brandName: "Wellcraft",
//           reserveMet: false,
//           wpId: 19292,
//           picture_mobile: "/wp-content/uploads/2022/09/php683di9-230x172.webp",
//           longText:
//             '2020 Wellcraft 352 Fisherman with triple Yamaha 300hp four stroke motors, 302 hours, warranty through 5/23, Twin Simrad 16" Plotters, S5100 Sounder w/ CHIRP, JL Audio sound system, Fusion head unit, aquarium style livewell, marine head, hull side door, full coaming bolsters, forward seating, fridge / freezer, tri-axle Magic Tilt Trailer.',
//           userId: "auth0|63123957bdea80fc65eac3f5",
//           categoryId: 3,
//           listingStatus: 1,
//           modelName: "352 Fisherman",
//           highestBidUser: null,
//           pipedriveId: 1685,
//           highestBid: 0,
//           sent1hEmail: false,
//           picture: "/wp-content/uploads/2022/09/php683di9-495x371.webp",
//           brandId: 19165,
//           username: "2020wellcraft",
//           expTimeStamp: 1696079913796,
//           sent24hEmail: false,
//           title: "2020 Wellcraft 352 Fisherman",
//         },
//       ])
//     );
//   }),
// ];
