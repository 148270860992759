import React, { useEffect, useState } from "react";

import { apiCall } from "@boatlist/common/lib";
import { CONSTANTS } from "@/lib/core";

import Layout from "@/components/common/Layout";
import Card from "@/components/common/Card";
import Auctions from "@/components/auctions/Auctions";
import WatchCard from "@/components/auctions/WatchCard";

import styles from "./Home.module.scss";
import FollowCard from "@/components/auctions/FollowCard";

const HomePage = () => {
  const [auctions, setAuctions] = useState([]);

  useEffect(() => {
    apiCall(`/rest/listingsactive`)
      .then((res) => res.json())
      .then((json) =>
        setAuctions(
          json.filter((live) => live.listingStatus === 2 && live.expirationDate)
        )
      );
  }, []);
  console.log(auctions);

  return (
    <Layout activePage={CONSTANTS.NAV.HOME}>
      <div className={styles["home-content"]}>
        <h2>Home Page</h2>
        <div className={styles["watched-box"]}>
          <WatchCard filter="live" />
          <WatchCard filter="schedule" />
          <WatchCard filter="soon" />
          <div className={styles["flex-grow"]} />
          <FollowCard filter="categories" />
          <FollowCard filter="brands" />
        </div>
        <h2>Live Auctions</h2>
        <Auctions {...{ auctions }} />
      </div>
    </Layout>
  );
};

export default HomePage;
