import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { apiCall } from "@boatlist/common/lib";
import { Spinner } from "@boatlist/common/components";
import { BiArrowBack } from "react-icons/all.js";
import * as PropTypes from "prop-types";
import { CONSTANTS } from "@/lib/core";

import Layout from "@/components/common/Layout";
import Label from "@/components/common/Label";
import Tabs from "@/components/common/Tabs";
import PaymentInfo from "@/components/users/PaymentInfo";
import ContactInfoCard from "@/pages/SingleUserPage/SettingsTab/ContactInfoCard.jsx";
import UserDetailsCard from "@/pages/SingleUserPage/UserDetailsCard.jsx";
import AuctionsTab from "@/pages/SingleUserPage/AuctionsTab/AuctionsTab.jsx";
import SettingsTab from "@/pages/SingleUserPage/SettingsTab/SettingsTab.jsx";
import styles from "./SingleUserPage.module.scss";
import WatchesAndFollowsTabs from "@/pages/SingleUserPage/WatchesAndFollowsTab/WatchesAndFollowsTabs";
import MarketplaceTab from "./MarketplaceTab/MarketplaceTab";

const SingleUserPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [defaultTab, setDefaultTab] = useState(0);

  useEffect(() => {
    apiCall(`/rest/user/${userId}`)
      .then((res) => res.json())
      .then((json) => setUser(json));
  }, []);

  const handleStatus = () => {
    if (user.blocked) {
      return <Label variant="danger">Blocked</Label>;
    }
    return <Label variant="success">Active</Label>;
  };

  return user ? (
    <Layout activePage={CONSTANTS.NAV.USERS}>
      <div className={styles["single-layout"]}>
        <div className={styles["title-group"]}>
          <div className={styles["icon"]}>
            <button
              className={styles["link-button"]}
              onClick={() => navigate(-1)}
            >
              <BiArrowBack />
            </button>
          </div>
          <div className={styles["header"]}>{user.username}</div>
          <div className={styles["header-status"]}>{handleStatus()}</div>
          <div>
            {user.founding && <Label variant="secondary">FOUNDING</Label>}
          </div>
        </div>
        <div className={styles["user-page"]}>
          <div className={styles["sidebar"]}>
            <div className={styles["card-box"]}>
              <UserDetailsCard {...{ user }} />
            </div>
            {(user.firstname || user.lastname || user.phone) && (
              <div className={styles["card-box"]}>
                <ContactInfoCard {...{ user }} />
              </div>
            )}
            {user.cclast4 && (
              <div className={styles["card-box"]}>
                <PaymentInfo {...{ user }} />
              </div>
            )}
          </div>
          <Tabs
            menu={[
              { title: "Auctions", value: 0 },
              { title: "Marketplace", value: 1 },
              { title: "Watches/Follows", value: 2 },
              { title: "Settings", value: 3 },
            ]}
            {...{ defaultTab }}
          >
            <AuctionsTab {...{ userId }} />
            <MarketplaceTab {...{ userId }} />
            <WatchesAndFollowsTabs {...{ user }} />
            <SettingsTab {...{ user, setUser }} />
          </Tabs>
        </div>
      </div>
    </Layout>
  ) : (
    <Spinner />
  );
};

export default SingleUserPage;
