import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import DataTable from "@/components/common/DataTable/index.js";

const MarketTable = ({ marketRows, columns, filter }) => {
  const [filteredMarketRows, setFilteredMarketRows] = useState(null);
  useEffect(() => {
    let newCopyRows = marketRows;

    if (marketRows) {
      if (
        filter !== null &&
        typeof filter !== "undefined" &&
        filter.length !== 0
      ) {
        if (filter["buyNow"] && filter["buyNow"].length !== 0) {
          newCopyRows = newCopyRows.filter((listing) =>
            filter["buyNow"].includes(listing.buyNow.filterValue)
          );
        }
        if (filter["status"] && filter["status"].length !== 0) {
          newCopyRows = newCopyRows.filter((listing) =>
            filter["status"].includes(listing.listingStatus.filterValue)
          );
        }
        if (filter["category"] && filter["category"].length !== 0) {
          newCopyRows = newCopyRows.filter((listing) =>
            filter["category"].includes(listing.subcategory.filterValue)
          );
        }
        setFilteredMarketRows(newCopyRows);
      } else {
        setFilteredMarketRows(marketRows);
      }
    }
  }, [marketRows, filter]);
  return <DataTable id="listings" rows={filteredMarketRows} {...{ columns }} />;
};

MarketTable.propTypes = {
  userRows: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
};

export default MarketTable;
