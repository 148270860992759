import React, { useState } from "react";

import PropTypes from "prop-types";
import Dialog from "@/components/common/Dialog";
import PostDealForm from "@/components/forms/PostDealForm";

const DealModal = ({ onCancel, handlePutDeal, auctionId, setError, error }) => {
  const [deal, setDeal] = useState({ listingId: auctionId });
  const handleInput = (name, value) => {
    setError({});
    setDeal({ ...deal, [name]: value });
  };

  return (
    <Dialog
      onCancel={onCancel}
      onAccept={() => handlePutDeal(deal)}
      acceptText="Deal"
      variant="secondary"
    >
      <PostDealForm {...{ handleInput, error }} />
    </Dialog>
  );
};

DealModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handlePutDeal: PropTypes.func.isRequired,
  auctionId: PropTypes.number.isRequired,
  setError: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default DealModal;
