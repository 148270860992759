import React from "react";
import PropTypes from "prop-types";
import { BiArrowBack } from "react-icons/all";

import styles from "./EmailsPage.module.scss";
import Button from "../../components/common/Button";
import Label from "../../components/common/Label";
import EmailsPageActionsPopper from "./EmailsPageActionsPopper";

const formatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

const convertTimestamp = (timestamp) =>
  timestamp
    ? new Date(timestamp).toLocaleDateString("en-US", formatOptions)
    : "None";

const labelStatusVariant = (status) => {
  switch (status) {
    case "Draft":
      return "secondary";
    case "Updated":
      return "primary";
    case "Published":
      return "success";
    default:
      return "secondary";
  }
};

const EmailPageHeader = ({
  activeEmailTemplate,
  reset,
  handleNewTemplate,
  handleDeleteTemplate,
  handleDuplicateTemplate,
}) => {
  if (activeEmailTemplate) {
    const formattedUpdatedTimeStamp = convertTimestamp(
      activeEmailTemplate.updatedTimestamp
    );
    const formattedPublishedTimeStamp = convertTimestamp(
      activeEmailTemplate.publishedTimestamp
    );
    return (
      <div className={styles["email-templates__header"]}>
        <div className={styles["icon"]}>
          <button
            type="button"
            className={styles["link-button"]}
            onClick={reset}
          >
            <BiArrowBack />
          </button>
        </div>
        <h2>{activeEmailTemplate.templateName || "New Email Template"}</h2>
        <Label variant={labelStatusVariant(activeEmailTemplate.templateStatus)}>
          {activeEmailTemplate.templateStatus || "Draft"}
        </Label>
        <div className={styles["email-templates__grow"]} />
        <div className={styles["email-templates__timestamp"]}>
          <div className={styles["email-templates__timestamp-label"]}>
            Last Updated:
          </div>
          <div>{formattedUpdatedTimeStamp}</div>
        </div>
        <div className={styles["email-templates__timestamp"]}>
          <div className={styles["email-templates__timestamp-label"]}>
            Published:
          </div>
          <div>{formattedPublishedTimeStamp}</div>
        </div>
        <EmailsPageActionsPopper
          {...{
            activeEmailTemplate,
            handleDeleteTemplate,
            handleDuplicateTemplate,
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles["email-templates__header"]}>
      <h2>Emails</h2>
      <div className={styles["email-templates__grow"]} />
      <Button variant="secondary" onClick={handleNewTemplate}>
        Create New Template
      </Button>
    </div>
  );
};

EmailPageHeader.defaultProps = {
  activeEmailTemplate: null,
  reset: undefined,
  handleNewTemplate: undefined,
  handleDeleteTemplate: undefined,
  handleDuplicateTemplate: undefined,
};

EmailPageHeader.propTypes = {
  activeEmailTemplate: PropTypes.shape({
    templateName: PropTypes.string,
    updatedTimestamp: PropTypes.string,
    publishedTimestamp: PropTypes.string,
    templateStatus: PropTypes.string,
  }),
  reset: PropTypes.func,
  handleNewTemplate: PropTypes.func,
  handleDeleteTemplate: PropTypes.func,
  handleDuplicateTemplate: PropTypes.func,
};

export default EmailPageHeader;
