import React from "react";
import styles from "./AuctionOfferForm.module.scss";
import TextField from "@/components/common/TextField/index.js";
import { useForm } from "react-hook-form";

const AuctionOfferForm = ({ errors, register }) => {
  return (
    <>
      <div className={styles["title"]}>Make an Offer</div>
      <div className={styles["description"]}>
        Please add the username of the buyer, user ID, the amount agreed upon,
        and an optional message.
      </div>
      <div className={styles["deal-box"]}>
        <TextField
          title="Username"
          // onChange={(e) => handleInput("username", e.target.value)}
          register={register}
          name="username"
          required="This field is required."
          error={errors.username?.message}
        />
        <TextField
          title="User ID"
          // onChange={(e) => handleInput("userId", e.target.value)}
          register={register}
          name="userId"
          required="This field is required."
          error={errors.userId?.message}
        />
        <TextField
          title="Amount"
          // onChange={(e) => handleInput("amount", Number(e.target.value))}
          register={register}
          name="amount"
          required="This field is required."
          error={errors.amount?.message}
        />
        <TextField
          title="Message"
          // onChange={(e) => handleInput("message", e.target.value)}
          register={register}
          name="message"
        />
      </div>
    </>
  );
};

export default AuctionOfferForm;
