import React from "react";
import PropTypes from "prop-types";
import styles from "@/pages/SingleUserPage/SingleUserPage.module.scss";
import Card from "@/components/common/Card/index.js";

const ContactInfoCard = ({ user }) => {
  return (
    <Card>
      <div className={styles["user-details"]}>Contact Info</div>
      <Card color="primary">
        <div className={styles["contact-name"]}>
          <div className={styles["column-box"]}>
            <div className={styles["details-header"]}>First name</div>
            <div className={styles["details-content"]}>{user.firstname}</div>
          </div>
          <div className={styles["column-box"]}>
            <div className={styles["details-header"]}>Last name</div>
            <div className={styles["details-content"]}>{user.lastname}</div>
          </div>
        </div>
        <div className={styles["details-header"]}>Number</div>
        <div className={styles["details-content"]}>{user.phone}</div>
        {/* <div className={styles["details-header"]}>Address</div> */}
        {/* <div className={styles["details-content"]}>{user.address}</div> */}
        {/* <div className={styles["details-header"]}>City</div> */}
        {/* <div className={styles["details-content"]}>{user.city}</div> */}
        {/* <div className={styles["contact-name"]}> */}
        {/*  <div className={styles["column-box"]}> */}
        {/*    <div className={styles["details-header"]}>State</div> */}
        {/*    <div className={styles["details-content"]}>{user.state}</div> */}
        {/*  </div> */}
        {/*  <div className={styles["column-box"]}> */}
        {/*    <div className={styles["details-header"]}>Zip</div> */}
        {/*    <div className={styles["details-content"]}>{user.zip}</div> */}
        {/*  </div> */}
        {/* </div> */}
      </Card>
    </Card>
  );
};

ContactInfoCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ContactInfoCard;
