import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./TextField.module.scss";

const TextField = ({
  title,
  error,
  value,
  onChange,
  placeholder,
  id,
  type,
  topHelper,
  onKeyUp,
  variant,
  register,
  name,
  required,
  validate,
  valueAsNumber,
  ...rest
}) => {
  return (
    <div className={cx(styles["input-container"], styles[`${variant}`])}>
      <span className={styles["label_text"]}>
        {title} {topHelper}
      </span>
      {register ? (
        <div className={styles["input"]}>
          <label>
            <input
              {...register(name, { required, validate, valueAsNumber })}
              type={type}
              value={value}
              placeholder={placeholder}
              // onChange={onChange}
              // {...rest}
            />
          </label>
        </div>
      ) : (
        <div className={styles["input"]}>
          <label>
            <input
              id={id}
              type={type}
              value={value}
              onChange={onChange}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              {...rest}
            />
          </label>
        </div>
      )}

      {error && <div className={styles["error-message"]}>{error}</div>}
    </div>
  );
};

TextField.defaultProps = {
  type: "text",
  title: null,
  error: null,
  value: null,
};

TextField.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default TextField;
