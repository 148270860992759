import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { apiCall } from "@boatlist/common/lib";
import { CONSTANTS } from "@/lib/core/index.js";
import Layout from "@/components/common/Layout";
import MarketListingForm from "@/components/forms/MarketListingForm";
import { selectActiveUser } from "@/store/slices/users.js";
import styles from "./MarketPlacePage.module.scss";
import MarketListings from "@/components/market/MarketListings";
import Button from "@/components/common/Button";
import Card from "@/components/common/Card";
import Select from "@/components/common/Select";
import MarketBar from "@/components/market/MarketBar/index.js";
import useLocalStorage from "@/hooks/useLocalStorage.js";
import { BiArrowBack } from "react-icons/bi";
import { Dropdown } from "@boatlist/common/components";
import IconButton from "@/components/common/IconButton";
import { BiDotsHorizontalRounded } from "react-icons/all";
import Modal from "@/components/common/Modal";

const PopperTrigger = React.forwardRef((props, ref) => {
  return (
    <button
      type="button"
      className={styles["actionsPopper__trigger"]}
      ref={ref}
      {...props}
    >
      <IconButton variant="secondary" noPadding size="md">
        <BiDotsHorizontalRounded />
      </IconButton>
    </button>
  );
});

const MarketPlacePage = () => {
  const user = useSelector(selectActiveUser);
  const [showForm, setShowForm] = useState(false);
  const [listings, setListings] = useState([]);
  const [filter, setFilter] = useLocalStorage("marketFilter", {});
  const [republishModal, setRepublishModal] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    apiCall("/rest/marketplacelistings")
      .then((res) => res.json())
      .then((json) => setListings(json));
  }, []);

  const handleAllRepublish = () => {
    console.log(status);
    // apiCall(`/rest/marketplacelisting/republish/${status}`, {
    //   method: "POST",
    // }).then((res) => {
    //   if (res.ok) {
    //     window.location.reload();
    //   }
    // });
  };

  const actions = [
    {
      title: "Republish All",
      onClick: () => setRepublishModal(true),
    },
  ];

  return (
    <Layout activePage={CONSTANTS.NAV.MARKET}>
      <div className={styles["market-page"]}>
        {showForm ? (
          <>
            <div className={styles["market-title"]}>
              <div className={styles["icon"]}>
                <button
                  type="button"
                  className={styles["link-button"]}
                  onClick={() => setShowForm(false)}
                >
                  <BiArrowBack />
                </button>
              </div>
              <h2>Market Place Listing</h2>
            </div>
            <MarketListingForm {...{ user, setShowForm }} />
          </>
        ) : (
          <div>
            <div className={styles["market-title"]}>
              <h2>Market Place</h2>
              <div className={styles["flex-grow"]} />
              <Button variant="secondary" onClick={() => setShowForm(true)}>
                Create Listing
              </Button>
              <div className={styles["actionsPopper"]}>
                <Dropdown
                  Trigger={PopperTrigger}
                  contentClassName={styles["actionsPopper"]}
                  triggerClasses={styles[""]}
                >
                  <div className={styles["actionsPopper__content"]}>
                    {actions.map((action) => (
                      <button
                        type="button"
                        className={styles["actionsPopper__item"]}
                        onClick={action.onClick}
                      >
                        {action.title}
                      </button>
                    ))}
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className={styles["market-table"]}>
              <div className={styles["group"]}>
                <MarketBar
                  {...{
                    setFilter,
                    filter,
                  }}
                />
              </div>
              <MarketListings {...{ listings, filter }} />
            </div>
          </div>
        )}
      </div>
      {republishModal && (
        <Modal onCancel={() => setRepublishModal(false)}>
          <Card>
            <div className={styles["modal-title"]}>
              Are you sure you want to republish all listings with the selected
              status?
            </div>
            <Select
              label="Select a status"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="" />
              <option value={0}>New</option>
              <option value={1}>Schedule</option>
              <option value={2}>Active</option>
              <option value={3}>Ended</option>
            </Select>
            <div className={styles["button-holder"]}>
              <Button
                variant="gray"
                outline
                onClick={() => setRepublishModal(false)}
                margin
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleAllRepublish}>
                Yes
              </Button>
            </div>
          </Card>
        </Modal>
      )}
    </Layout>
  );
};

export default MarketPlacePage;
