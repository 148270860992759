import React, { useState } from "react";
import DeleteUserModal from "@/components/users/DeleteUserModal/index.js";
import ResetPasswordModal from "@/components/users/ResetPasswordModal/index.js";
import BlockUserModal from "@/components/users/BlockUserModal/index.js";
import styles from "@/pages/SingleUserPage/SingleUserPage.module.scss";
import Button from "@/components/common/Button/index.js";

const UserBottom = ({ user }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [userAction, setUserAction] = useState(null);
  const handleResetSuccess = () => {
    setResetModal(false);
  };

  const handleDeleteSuccess = () => {
    setDeleteModal(false);
  };

  const handleBlockSuccess = () => {
    setBlockModal(false);
  };
  return (
    <>
      <div className={styles["action-box"]}>
        <div className={styles["danger"]}>Danger Zone</div>
        <div className={styles["actions"]}>
          <Button onClick={() => setDeleteModal(true)} variant="danger">
            Delete
          </Button>
          {!user.blocked ? (
            <Button
              onClick={() => {
                setBlockModal(true);
                setUserAction("Block");
              }}
              variant="danger"
            >
              Block
            </Button>
          ) : (
            <Button
              onClick={() => {
                setBlockModal(true);
                setUserAction("Unblock");
              }}
              variant="danger"
            >
              Unblock
            </Button>
          )}
          <Button onClick={() => setResetModal(true)} variant="transparent">
            Reset Password
          </Button>
        </div>
      </div>
      {deleteModal && (
        <DeleteUserModal
          name={user.username}
          userId={user.id}
          {...{ handleDeleteSuccess }}
          onCancel={() => setDeleteModal(false)}
        />
      )}
      {resetModal && (
        <ResetPasswordModal
          user={user}
          userId={user.id}
          {...{
            handleResetSuccess,
          }}
          onCancel={() => setResetModal(false)}
        />
      )}
      {blockModal && (
        <BlockUserModal
          name={user.username}
          userId={user.id}
          {...{ handleBlockSuccess }}
          action={userAction}
          onCancel={() => setBlockModal(false)}
        />
      )}
    </>
  );
};

export default UserBottom;
