import React from "react";

import { useAuth } from "@boatlist/common/lib";
import useLocalStorage from "@/hooks/useLocalStorage";

import Select from "../Select";

const EnvironmentPopper = () => {
  const [env, setEnv] = useLocalStorage("admin_env", "prod");
  const { logout } = useAuth();

  const onChange = (e) => {
    if (e.target.value !== env) {
      setEnv(e.target.value);
      window.location.reload();
    }
  };
  return (
    <Select value={env} {...{ onChange }}>
      <option value="dev">Development</option>
      <option value="prod">Production</option>
    </Select>
  );
};

export default EnvironmentPopper;
