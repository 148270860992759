import * as toolkitRaw from "@reduxjs/toolkit";
const { createSlice } = toolkitRaw.default ?? toolkitRaw;

export const listingsSlice = createSlice({
  name: "listings",
  initialState: {
    listings: [],
    activeListing: null,
  },
  reducers: {
    addListings: (state, action) => {
      state.listings = action.payload;
    },
    addListing: (state, action) => {
      state.listings.push(action.payload);
    },
    setActiveListing: (state, action) => {
      state.activeListing = action.payload;
    },
    updateListing: (state, action) => {
      state.listings.map((listing) => {
        if (listing.listingId === action.payload.listingId) {
          return { ...listing, ...action.payload };
        }
        return listing;
      });
    },
    updateActiveListing: (state, action) => {
      state.activeListing = { ...state.activeListing, ...action.payload };
    },
    deleteListing: (state, action) => {
      state.listings.filter((listing) => listing.listingId !== action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addListings,
  addListing,
  updateListing,
  setActiveListing,
  updateActiveListing,
  deleteListing,
} = listingsSlice.actions;

export const selectAllListings = (state) => state.listings.listings;
export const selectActiveListing = (state) => state.listings.activeListing;

export default listingsSlice.reducer;
