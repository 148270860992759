import React from "react";
import { getConfig } from "@boatlist/common/lib";
import PropTypes from "prop-types";

import styles from "@/pages/SingleAuctionRequestPage/SingleAuctionRequestPage.module.scss";

const RequestDetails = ({ request }) => {
  return (
    <div className={styles["owner"]}>
      <div className={styles["header"]}>Request Details</div>
      <div className={styles["color-box"]}>
        <div className={styles["row-header"]}>
          <div className={styles["title"]}>Listing ID</div>
          <div className={styles["title"]}>Boat HULL ID</div>
        </div>
        <div className={styles["row-holder"]}>
          <div className={styles["content"]}>{request.requestId}</div>
          <div className={styles["content"]}>
            {request.HIN ? request.HIN : "N/A"}
          </div>
        </div>
        <div className={styles["row-header"]}>
          <div className={styles["title"]}>Engine Hours</div>
          <div className={styles["title"]}>Boat Year</div>
        </div>
        <div className={styles["row-holder"]}>
          <div className={styles["content"]}>{request.engineHours}</div>
          <div className={styles["content"]}>{request.year}</div>
        </div>
        <div className={styles["row-header"]}>
          <div className={styles["title"]}>Electronics Brand</div>
          <div className={styles["title"]}>Boat Storage</div>
        </div>
        <div className={styles["row-holder"]}>
          <div className={styles["content"]}>{request.electronics}</div>
          <div className={styles["content"]}>{request.storage}</div>
        </div>
        <div className={styles["row-header"]}>
          <div className={styles["title"]}>Location</div>
          <div className={styles["title"]}>Uploaded Photos</div>
        </div>
        <div className={styles["row-holder"]}>
          <div className={styles["content"]}>{request.location}</div>
          <div className={styles["content"]}>
            <a
              href={`${getConfig().base_content_url}/photoviewer/viewer.html?${
                request.requestId
              }`}
              target="_blank"
              className={styles["link"]}
              rel="noreferrer"
            >
              View Photos
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

RequestDetails.propTypes = {
  request: PropTypes.object.isRequired,
};

export default RequestDetails;
