import React, { useEffect, useState } from "react";
import { apiCall, getConfig } from "@boatlist/common/lib";

import { CONSTANTS } from "@/lib/core/index.js";

import TableCell from "@/components/common/TableCell";
import Label from "@/components/common/Label";
import Button from "@/components/common/Button";
import Card from "@/components/common/Card";
import RequestTable from "@/components/requests/RequestTable/index.js";
import styles from "./AuctionRequests.module.scss";
import RequestSmallAuctionCards from "@/components/requests/RequestSmallAuctionCards/index.js";

const columns = [
  {
    id: "requestId",
    name: "requestId",
    title: "Request ID",
    value: "requestId",
    className: "requestId",
    isSortable: true,
    width: "10%",
  },
  {
    id: "title",
    name: "title",
    title: "Title",
    className: "title",
    value: "requestId",
    type: "link",
    isSortable: true,
    width: "20%",
  },
  {
    id: "username",
    name: "username",
    title: "Username",
    className: "username",
    value: "userId",
    type: "link",
    isSortable: true,
    width: "15%",
  },
  {
    id: "email",
    name: "email",
    value: "email",
    title: "Email",
    className: "email",
    width: "20%",
  },
  {
    id: "requestDate",
    name: "requestDate",
    title: "Request Date",
    className: "requestDate",
    value: "requestDate",
    isSortable: true,
    width: "20%",
  },
  {
    id: "requestStatus",
    name: "requestStatus",
    title: "Request Status",
    className: "requestStatus",
    value: "requestStatus",
    isSortable: true,
    width: "15%",
  },
  {
    id: "location",
    name: "location",
    title: "Location",
    value: "location",
    className: "location",
    width: "10%",
  },
  {
    id: "actions",
    name: "actions",
    title: "Actions",
    className: "actions",
    type: "actions",
    width: "15%",
  },
];

const getRequestRows = (requests, actions) => {
  const getListingStatusLabel = (requestStatus) => {
    switch (requestStatus) {
      case CONSTANTS.REQUEST_STATUS.NEW:
        return <Label variant="secondary">New</Label>;
      case CONSTANTS.REQUEST_STATUS.APPROVED:
        return <Label variant="primary">Approved</Label>;
      case CONSTANTS.REQUEST_STATUS.REJECTED:
        return <Label variant="danger">Rejected</Label>;
      case CONSTANTS.REQUEST_STATUS.LISTED:
        return <Label variant="success">Listed</Label>;
      default:
        return <Label variant="primary">Canceled</Label>;
    }
  };

  const getExpirationDate = (expirationDate) => {
    if (expirationDate) {
      const date = new Date(expirationDate).toLocaleString("en-us", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      return date;
    }

    return "No date available";
  };

  const getTitle = (listing) => {
    return `${listing.brandName ? listing.brandName : listing.brandOther} ${
      listing.modelName ? listing.modelName : listing.modelOther
    }`;
  };

  return requests.map((request) => ({
    id: request.requestId,
    requestId: {
      value: request.requestId,
      sortValue: request.requestId,
    },
    title: {
      value: getTitle(request) || "N/A",
      link: `/requests/${request.requestId}/${request.userId}`,
      sortValue: getTitle(request),
    },
    username: {
      value: request.username || "N/A",
      link: request.username ? `/users/${request.userId}` : null,
      sortValue: request.username,
    },
    email: {
      value: request.email || "N/A",
    },
    requestDate: {
      sortValue: new Date(request.requestDate).getTime(),
      value: getExpirationDate(request.requestDate),
    },
    requestStatus: {
      value: getListingStatusLabel(request.requestStatus),
      filterValue: Number(request.requestStatus),
    },
    location: {
      value: request.location,
    },
    actions,
  }));
};

const AuctionsRequests = ({ requests, filter }) => {
  const [requestRows, setRequestRows] = useState(null);

  useEffect(() => {
    if (requests) {
      const actions = [
        {
          name: "view",
          title: "View Photos",
          onClick: (requestId) => {
            console.log(requestId);
            window.open(
              `${
                getConfig().base_content_url
              }/photoviewer/viewer.html?${requestId}`,
              "_blank"
            );
          },
        },
      ];
      setRequestRows(getRequestRows(requests, actions));
    }
  }, [requests]);

  return (
    <>
      <div className={styles["home"]}>
        <Card>
          <RequestTable
            requestRows={requestRows}
            columns={columns}
            filter={filter}
          />
        </Card>
      </div>
      <div className={styles["mobile"]}>
        <RequestSmallAuctionCards {...{ requests, filter }} />
      </div>
    </>
  );
};

export default AuctionsRequests;
