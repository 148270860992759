import React, { useState } from "react";
import PropTypes from "prop-types";

import Dialog from "../../components/common/Dialog";
import Popper from "../../components/common/Popper";

const modalInitialState = { delete: false, duplicate: false };

const EmailsPageActionsPopper = ({
  activeEmailTemplate,
  handleDeleteTemplate,
  handleDuplicateTemplate,
}) => {
  const [modals, setModals] = useState(modalInitialState);
  const handleOpenDeleteModal = () => {
    setModals({ ...modals, delete: true });
  };
  const onDeleteModalAccept = () => {
    setModals(modalInitialState);
    handleDeleteTemplate(activeEmailTemplate);
  };
  const onDeleteModalCancel = () => {
    setModals(modalInitialState);
  };
  const handleOpenDuplicateModal = () => {
    setModals({ ...modals, duplicate: true });
  };
  const onDuplicateModalAccept = () => {
    setModals(modalInitialState);
    handleDuplicateTemplate(activeEmailTemplate);
  };
  return (
    <>
      <Popper>
        {(itemStyles) => (
          <>
            <button
              type="button"
              className={itemStyles}
              onClick={handleOpenDeleteModal}
            >
              Delete
            </button>
            <button
              type="button"
              className={itemStyles}
              onClick={handleOpenDuplicateModal}
            >
              Duplicate
            </button>
          </>
        )}
      </Popper>
      {modals.delete && (
        <Dialog
          title="Are you sure?"
          acceptText="Yes, delete"
          onAccept={onDeleteModalAccept}
          onCancel={onDeleteModalCancel}
          variant="danger"
        >
          <p>
            Are you sure you want to delete{" "}
            <strong>{activeEmailTemplate.templateName}</strong>?
          </p>
        </Dialog>
      )}
      {modals.duplicate && (
        <Dialog
          title="Are you sure?"
          acceptText="Yes, duplicate"
          onAccept={onDuplicateModalAccept}
          onCancel={onDeleteModalCancel}
        >
          <p>
            Are you sure you want to duplicate{" "}
            <strong>{activeEmailTemplate.templateName}</strong>?
          </p>
        </Dialog>
      )}
    </>
  );
};

EmailsPageActionsPopper.defaultProps = {
  activeEmailTemplate: null,
  handleDuplicateTemplate: undefined,
  handleDeleteTemplate: undefined,
};

EmailsPageActionsPopper.propTypes = {
  activeEmailTemplate: PropTypes.shape({
    design: PropTypes.shape(),
    templateName: PropTypes.string,
    updatedTimestamp: PropTypes.string,
    publishedTimestamp: PropTypes.string,
    status: PropTypes.string,
  }),
  handleDuplicateTemplate: PropTypes.func,
  handleDeleteTemplate: PropTypes.func,
};

export default EmailsPageActionsPopper;
