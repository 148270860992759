import store from "@/store";
import { apiCall } from "@boatlist/common/lib";
import { addUsers, setActiveUser } from "@/store/slices/users";

// fetch api calls
const fetchGetUsers = (body) =>
  apiCall("/rest/users", {
    method: "POST",
    body: JSON.stringify(body),
  }).then((res) => res.json());

const fetchGetUser = (id) =>
  apiCall(`/rest/user/${id}`).then((res) => res.json());

// handle api calls
const handleGetUsers = (response) => {
  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(new Error(response.error));
    } else {
      store.dispatch(addUsers(response));
      resolve(response);
    }
  });
};

const handleGetUser = (response) => {
  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(new Error(response.error));
    } else {
      store.dispatch(setActiveUser(response));
      resolve(response);
    }
  });
};

// handle errors
const handleErrors = (err) => {
  console.log("USERS:ERROR:", err);
};

// public api calls
export const getUsers = (body) =>
  fetchGetUsers(body).then(handleGetUsers).catch(handleErrors);

export const getUser = (id) =>
  fetchGetUser(id).then(handleGetUser).catch(handleErrors);

const putUpgradeToBidder = async (userObject) => {
  return new Promise((resolve, reject) => {
    apiCall("/rest/user2bidder", {
      method: "PUT",
      body: JSON.stringify(userObject),
    }).then((res) => {
      if (res.ok) {
        resolve({ ok: true });
      }
      res.json().then((json) => {
        if (json.error) {
          resolve({ error: json.error });
        }
        resolve({ error: "An error ocurred" });
      });
    });
  });
};

const signupUser = (userObject) => {
  return new Promise((resolve, reject) => {
    apiCall("/rest/user", {
      method: "POST",
      body: JSON.stringify(userObject),
    }).then((res) => {
      if (res.ok) {
        resolve({ ok: true });
      }
      res.json().then((json) => {
        if (json.error) {
          resolve({ error: json.error });
        }
        resolve({ error: "An error ocurred" });
      });
    });
  });
};

const putUpgradeToSeller = (userId) => {
  return new Promise((resolve, reject) => {
    apiCall(`/rest/user2Seller/${userId}`, {
      method: "PUT",
    }).then((res) => {
      if (res.ok) {
        resolve({ ok: true });
      }
      res.json().then((json) => {
        if (json.error) {
          resolve({ error: json.error });
        }
        resolve({ error: "An error ocurred" });
      });
    });
  });
};

export { putUpgradeToBidder, putUpgradeToSeller, signupUser };
