import React from "react";
import { CONSTANTS } from "@/lib/core";
import { toast } from "react-hot-toast";

import { useEmailTemplates } from "./useEmailTemplates";

import { Layout } from "../../components/common";
import EmailPageHeader from "./EmailPageHeader";
import EmailPageMain from "./EmailPageMain";
import styles from "./EmailsPage.module.scss";

const EmailsPage = () => {
  const {
    emailTemplates,
    activeEmailTemplate,
    setActiveEmailTemplate,
    getEmailTemplate,
    postEmailTemplate,
    putEmailTemplate,
    deleteEmailTemplate,
    publishEmailTemplate,
  } = useEmailTemplates();

  const onSubmit = (template) => {
    // if timestamp exists, call an update, not a create
    if (template.updatedTimestamp) {
      toast.promise(putEmailTemplate(template), {
        loading: "Saving email...",
        success: "Email Template saved!",
        error: "Error saving template!",
      });
      return;
    }

    toast.promise(postEmailTemplate(template), {
      loading: "Creating email...",
      success: "Email Template Created!",
      error: "Error creating template!",
    });
  };

  const onPublish = (template) => {
    toast.promise(publishEmailTemplate(template), {
      loading: "Publishing email...",
      success: "Email Template published!",
      error: "Error publishing template!",
    });
  };

  const handleDeleteTemplate = (template) => {
    toast.promise(deleteEmailTemplate(template), {
      loading: "Deleting email...",
      success: "Email Template deleted!",
      error: "Error deleting template!",
    });
  };

  const handleEditTemplate = (template) => {
    if (!template.design) {
      getEmailTemplate(template).then((completeTemplate) =>
        setActiveEmailTemplate(completeTemplate)
      );
      return;
    }
    setActiveEmailTemplate(template);
  };

  const handleNewTemplate = (template = {}) => {
    // if "template" is a synthetic event, don't pass it to activeTemplate
    if (template.target) {
      setActiveEmailTemplate({});
      return;
    }

    setActiveEmailTemplate(template);
  };

  const handleDuplicateTemplate = (template) => {
    if (!template.design) {
      getEmailTemplate(template).then((completeTemplate) => {
        const { design, templateName } = completeTemplate;
        handleNewTemplate({
          design,
          templateName: `${templateName}_Copy`,
          subject: "",
        });
      });
      return;
    }

    const { design, templateName } = template;
    handleNewTemplate({
      design,
      templateName: `${templateName}_Copy`,
      subject: "",
    });
  };

  const reset = () => {
    setActiveEmailTemplate(null);
  };

  return (
    <Layout activePage={CONSTANTS.NAV.EMAILS}>
      <div className={styles["email-templates"]}>
        <EmailPageHeader
          {...{
            activeEmailTemplate,
            reset,
            handleNewTemplate,
            handleDeleteTemplate,
            handleDuplicateTemplate,
          }}
        />
        <EmailPageMain
          {...{
            activeEmailTemplate,
            emailTemplates,
            onSubmit,
            onPublish,
            handleEditTemplate,
            handleDeleteTemplate,
            handleDuplicateTemplate,
          }}
        />
      </div>
    </Layout>
  );
};

export default EmailsPage;
