import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

import { Provider as AuthProvider } from "@boatlist/common/lib";
import store from "./store";

import { Routes } from "./pages";

import "./App.css";

const App = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <div className="app">
          <Router>
            <Routes />
          </Router>
        </div>
        <Toaster />
      </AuthProvider>
    </Provider>
  );
};

export default App;
