import React, { useState, useEffect } from "react";
import { apiCall, getConfig } from "@boatlist/common/lib";
import Label from "@/components/common/Label/index.js";
import { CONSTANTS } from "@/lib/core/index.js";
import MarketTable from "@/components/market/MarketTable";
import DeleteListingModal from "@/components/market/DeleteListingModal/index.js";
import styles from "@/components/auctions/Auctions/Auctions.module.scss";
import Card from "@/components/common/Card/Card.jsx";
import MarketSmallAuctionCards from "@/components/market/MarketSmallAuctionCards/index.js";

const columns = [
  {
    id: "listingId",
    name: "listingId",
    className: "listingId",
    title: "Listing ID",
    value: "listingId",
    isSortable: true,
    width: "10%",
  },
  {
    id: "title",
    name: "title",
    title: "Title",
    className: "title",
    type: "link",
    isSortable: true,
    value: "listingId",
    width: "30%",
  },
  {
    id: "username",
    name: "username",
    title: "Username",
    className: "username",
    isSortable: true,
    value: "userId",
    type: "link",
    width: "20%",
  },
  {
    id: "buyNow",
    title: "Buy Now",
    value: "buyNow",
    name: "buyNow",
    className: "buyNow",
    width: "15%",
  },
  {
    id: "listingStatus",
    name: "listingStatus",
    title: "Listing Status",
    value: "listingStatus",
    className: "status",
    width: "15%",
  },
  {
    id: "categoryName",
    name: "categoryName",
    title: "Category",
    value: "categoryName",
    className: "categoryName",
    width: "15%",
  },
  {
    id: "subcategory",
    name: "subcategory",
    title: "Sub-Category",
    value: "subcategory",
    className: "subcategory",
    width: "15%",
  },
  {
    id: "actions",
    name: "actions",
    title: "Actions",
    className: "actions",
    type: "actions",
    width: "10%",
  },
];

const getMarketRows = (listings, actions) => {
  const handleBuyNow = (buyNow) => {
    switch (buyNow) {
      case CONSTANTS.BUYNOW_STATUS.BUY_NOW:
        return (
          <Label variant="secondary" size="md">
            Buy Now
          </Label>
        );
      case CONSTANTS.BUYNOW_STATUS.TILL_AUCTION:
        return (
          <Label variant="primary" size="md">
            Buy Now Until Auction
          </Label>
        );
      case CONSTANTS.BUYNOW_STATUS.AUCTION:
        return (
          <Label variant="danger" size="md">
            Auction Only
          </Label>
        );
      default:
        return (
          <Label variant="danger" size="md">
            undefined
          </Label>
        );
    }
  };

  const handleStatus = (status) => {
    switch (status) {
      case CONSTANTS.MARKET_STATUS.NEW:
        return (
          <Label variant="secondary" size="md">
            New
          </Label>
        );
      case CONSTANTS.MARKET_STATUS.PUBLISHED:
        return (
          <Label variant="primary" size="md">
            Scheduled
          </Label>
        );
      case CONSTANTS.MARKET_STATUS.ACTIVE:
        return (
          <Label variant="success" size="md">
            Active
          </Label>
        );
      case CONSTANTS.MARKET_STATUS.ENDED:
        return (
          <Label variant="danger" size="md">
            Ended
          </Label>
        );
      case CONSTANTS.MARKET_STATUS.POSTDEAL:
        return (
          <Label variant="primary" size="md">
            Post Deal
          </Label>
        );
      default:
        return (
          <Label variant="danger" size="md">
            Cancelled
          </Label>
        );
    }
  };

  return listings.map((listing) => ({
    id: listing.listingId,
    listingId: {
      sortValue: listing.listingId,
      value: listing.listingId,
    },
    title: {
      sortValue: listing.title,
      value: listing.title,
      link: `/marketplace/${listing.listingId}`,
    },
    username: {
      sortValue: listing.username,
      value: listing.username,
      link: `/users/${listing.userId}`,
    },
    buyNow: {
      value: handleBuyNow(listing.buyNow),
      filterValue: Number(listing.buyNow),
    },
    listingStatus: {
      value: handleStatus(listing.listingStatus),
      filterValue: Number(listing.listingStatus),
    },
    categoryName: {
      value: listing.categoryName,
      filterValue: listing.categoryName,
    },
    subcategory: {
      value: listing.categoryAttributes.subcategory,
      filterValue: listing.categoryAttributes.subcategory,
    },
    actions,
  }));
};

const MarketListings = ({ listings, filter }) => {
  const [listingRows, setListingRows] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    if (listings) {
      const actions = [
        {
          name: "photos",
          title: "View Photos",
          onClick: (listingId) => {
            console.log(listingId);
            window.open(
              `${
                getConfig().base_content_url
              }/photoviewer/viewer.html?${listingId}`,
              "_blank"
            );
          },
        },
        {
          name: "view",
          title: "Preview",
          onClick: (listingId) => {
            window.open(
              `${
                getConfig().base_content_url
              }/marketplace/preview/?id=${listingId}`,
              "_blank"
            );
          },
        },
        {
          name: "delete",
          title: "Delete",
          onClick: (listingId) => {
            setDelete(listingId);
          },
        },
      ];
      setListingRows(getMarketRows(listings, actions));
    }
  }, [listings]);

  const setDelete = (listingId) => {
    setId(listingId);
    setDeleteModal(true);
  };

  const handleDeleteSuccess = () => {
    setDeleteModal(false);
  };

  return (
    <>
      <div className={styles["home"]}>
        <Card>
          <MarketTable marketRows={listingRows} {...{ filter, columns }} />
          {deleteModal && (
            <DeleteListingModal
              listingId={id}
              onCancel={() => setDeleteModal(false)}
              {...{ handleDeleteSuccess }}
            />
          )}
        </Card>
      </div>
      <div className={styles["mobile"]}>
        <MarketSmallAuctionCards {...{ listings, filter }} />
      </div>
    </>
  );
};

export default MarketListings;
