import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useForm } from "react-hook-form";

import { useAuth } from "@boatlist/common/lib";
import { CONSTANTS } from "@/lib/core";

import Layout from "@/components/common/Layout";
// import Editor from "@/components/common/Editor";
import Button from "@/components/common/Button";
import TextField from "@/components/common/TextField";

const AccountPage = () => {
  const { user, isLoading } = useAuth();
  const ref = useRef();
  const id = "content";
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = (data) => console.log(data);

  const handleInput = (value, name) => {
    console.log(value, name);
  };

  const firstName = watch("age");
  console.log(errors.year?.message);
  console.log(watch("year"));

  return (
    <Layout activePage={CONSTANTS.NAV.ACCOUNT}>
      <div>
        <h2>Account</h2>
        {!isLoading && <div>Email: {user.email}</div>}
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* include validation with required or other standard HTML validation rules */}
          {/* <input
            {...register("firstName", { required: "This is required." })}
          />
          <p>{errors.firstName?.message}</p>
          <input
            {...register("lastName", {
              required: "This is required.",
              minLength: {
                value: 4,
                message: "Min length is 4",
              },
            })}
          />
          <p>{errors.lastName?.message}</p>
          <p>{firstName}</p>
          <input type="submit" /> */}
          <TextField
            type="number"
            title="Year Built"
            register={register}
            name="year"
            valueAsNumber
            required="This field is required."
            error={errors.year?.message}
          />

          <input {...register("firstName", { required: "This is required" })} />
          <p>{errors.firstName?.message}</p>
          {/* <select {...register("gender", { required: "This is required" })}>
            <option value=""></option>
            <option value="female">female</option>
            <option value="male">male</option>
            <option value="other">other</option>
          </select>

          <input type="number" {...register("age", { min: 18, max: 99 })} />
          <p>{firstName}</p> */}
          <input type="submit" />
        </form>
      </div>
    </Layout>
  );
};

export default AccountPage;
