import React, { useEffect, useState } from "react";
import { apiCall } from "@boatlist/common/lib";
import AuctionRequests from "@/components/requests/AuctionRequests";
import styles from "./AuctionRequestPage.module.scss";
import Button from "@/components/common/Button";
import Layout from "@/components/common/Layout";
import { CONSTANTS } from "@/lib/core/index.js";
import useLocalStorage from "@/hooks/useLocalStorage.js";
import AuctionSubmitForm from "@/components/auctions/AuctionSubmitForm";

const AuctionRequestPage = () => {
  const [requests, setRequests] = useState([]);
  const [filter, setFilter] = useLocalStorage("requestsFilter", []);
  const [createView, setCreateView] = useState(false);

  const getAllRequests = () => {
    const newRequests = apiCall(`/rest/listingrequests`).then((res) =>
      res.json()
    );

    const approvedRequests = apiCall(
      `/rest/listingrequests?requestStatus=1`
    ).then((res) => res.json());

    const rejectedRequests = apiCall(
      `/rest/listingrequests?requestStatus=2`
    ).then((res) => res.json());

    const listedRequests = apiCall(
      `/rest/listingrequests?requestStatus=3`
    ).then((res) => res.json());

    return Promise.all([
      newRequests,
      approvedRequests,
      rejectedRequests,
      listedRequests,
    ]).then((allRequests) => allRequests.flat(1));
  };

  useEffect(() => {
    getAllRequests().then((json) => setRequests(json));
  }, []);

  const handleActive = (name) => {
    const isFollowed = filter.includes(name);
    if (isFollowed) {
      const newArray = filter.filter((id) => {
        return id !== name;
      });
      setFilter(newArray);
    } else {
      setFilter(filter.concat(name));
    }
  };

  console.log(filter);

  return (
    <Layout activePage={CONSTANTS.NAV.REQUESTS}>
      {createView ? (
        <AuctionSubmitForm {...{ setCreateView }} />
      ) : (
        <div className={styles["request-content"]}>
          <h2>Auction Requests</h2>
          <div className={styles["button-holder"]}>
            <Button
              variant="gray"
              onClick={() => handleActive(0)}
              active={filter.includes(0)}
              outline
              margin
            >
              New
            </Button>
            <Button
              variant="gray"
              onClick={() => handleActive(1)}
              active={filter.includes(1)}
              outline
              margin
            >
              Approved
            </Button>
            <Button
              variant="gray"
              onClick={() => handleActive(3)}
              active={filter.includes(3)}
              outline
              margin
            >
              Listed
            </Button>
            <Button
              variant="gray"
              onClick={() => handleActive(2)}
              active={filter.includes(2)}
              outline
              margin
            >
              Rejected
            </Button>

            <div className={styles["flex-grow"]} />
            <Button variant="secondary" onClick={() => setCreateView(true)}>
              Create Request
            </Button>
          </div>
          <AuctionRequests {...{ requests, filter }} />
        </div>
      )}
    </Layout>
  );
};

export default AuctionRequestPage;
