import React from "react";
import { apiCall } from "@boatlist/common/lib";
import styles from "@/pages/SingleUserPage/SingleUserPage.module.scss";
import Card from "@/components/common/Card/index.js";
import ContactInfo from "@/components/users/ContactInfo/index.js";
import NotificationsCard from "@/pages/SingleUserPage/SettingsTab/NotificationsCard.jsx";
import Button from "@/components/common/Button/index.js";
import UserBottom from "@/pages/SingleUserPage/SettingsTab/UserBottom.jsx";

const SettingsTab = ({ user, setUser }) => {
  const handleInput = (name, value) => {
    setUser({ ...user, [name]: value });
  };
  const handleUpdate = (name) => {
    if (name === "username") {
      apiCall(`/rest/username`, {
        method: "PUT",
        body: JSON.stringify({ id: user.id, username: user.username }),
      }).then((res) => {
        if (res.ok) {
          console.log("Update User");
        }
      });
    }
    if (name === "contact") {
      apiCall(`/rest/user`, {
        method: "PUT",
        body: JSON.stringify({ ...user }),
      }).then((res) => {
        if (res.ok) {
          console.log("Update User");
        }
      });
    } else {
      console.log("email update");
    }
  };
  const handleSwitch = (name) => {
    const newArray = user && user.roles.split(",");

    apiCall(`/rest/switch2${name}/${user.id}`, {
      method: "PUT",
    }).then((res) => {
      if (res.ok) {
        if (name === "seller") {
          const anotherArray = newArray.filter((role) => role !== "partner");
          const newUser = anotherArray.concat(name).join(",");
          setUser({ ...user, roles: newUser });
        }
        if (name === "partner") {
          const anotherArray = newArray.filter((role) => role !== "seller");
          const newUser = anotherArray.concat(name).join(",");
          setUser({ ...user, roles: newUser });
        }
      }
    });
  };
  return (
    <div className={styles["settings"]}>
      <Card variant="fit">
        {/* <AccountInfoForm {...{ user, handleInput, handleUpdate }} /> */}
        <div className={styles["info"]}>
          <ContactInfo {...{ handleInput, user, handleUpdate }} />
          {user.id && <NotificationsCard {...{ user }} />}
          {/* {user.cclast4 && <PaymentInfoForm {...{ user }} />} */}
        </div>
        <div className={styles["switch-button"]}>
          {user &&
          (user.roles.includes("partner") || user.roles.includes("seller")) ? (
            user.roles.includes("seller") ? (
              <Button
                variant="secondary"
                margin
                onClick={() => handleSwitch("partner")}
              >
                Switch to Partner
              </Button>
            ) : (
              <Button
                variant="secondary"
                margin
                onClick={() => handleSwitch("seller")}
              >
                Switch to Seller
              </Button>
            )
          ) : (
            <>
              <Button
                variant="secondary"
                margin
                onClick={() => handleSwitch("partner")}
              >
                Convert to Partner
              </Button>
              <Button
                variant="secondary"
                margin
                onClick={() => handleSwitch("seller")}
              >
                Convert to Seller
              </Button>
            </>
          )}
        </div>
        <UserBottom {...{ user }} />
      </Card>
    </div>
  );
};

export default SettingsTab;
