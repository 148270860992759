import React from "react";
import PropTypes from "prop-types";

import styles from "./Select.module.scss";

const Select = ({
  onChange,
  children,
  value,
  error,
  label,
  register,
  name,
  required,
  validate,
  valueAsNumber,
}) => {
  return (
    <div>
      <div className={styles["label"]}>{label}</div>
      {register ? (
        <div className={styles["select"]}>
          <select
            value={value}
            onChange={onChange}
            {...register(name, { required, validate, valueAsNumber })}
          >
            {children}
          </select>
        </div>
      ) : (
        <div className={styles["select"]}>
          <select value={value} onChange={onChange}>
            {children}
          </select>
        </div>
      )}
      {error && <div className={styles["error-message"]}>{error}</div>}
    </div>
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default Select;
