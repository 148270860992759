import React, { useState, useEffect } from "react";
import Select from "@/components/common/Select";
import TextField from "@/components/common/TextField";
import AuctionSubmitCheckBox from "./AuctionSubmitCheckBox";
import { useForm } from "react-hook-form";
import { apiCall } from "@boatlist/common/lib";
import Button from "@/components/common/Button";
import FormFileUpload from "../../common/FileUpload/FormFileUpload";
import {
  postListABoat,
  uploadListABoatFiles,
  updateListABoat,
} from "../../../lib/services/ListABoatApiCall";

import styles from "./AuctionSubmitForm.module.scss";

const AuctionSubmitForm = ({
  setCreateView,
  requestInformation,
  update,
  setUpdateMessage,
}) => {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { photos: null },
  });

  useEffect(() => {
    apiCall(`/rest/brands/`)
      .then((res) => res.json())
      .then((json) => setBrands(json));
    apiCall(`/rest/models/`)
      .then((res) => res.json())
      .then((json) => setModels(json));

    if (requestInformation) {
      reset({
        ...requestInformation,
        brand: `${requestInformation.brandId}-${requestInformation.brandName}`,
        model: `${requestInformation.modelId}-${requestInformation.modelName}`,
      });
    }
  }, []);

  const getBrand = watch("brand");
  const getModel = watch("model");

  const onSubmit = (data) => {
    const { brandOther, brand, modelOther, model } = data;
    const useBrandOther = brandOther !== "" && brand === "other";
    const useModelOther =
      modelOther !== "" && (model === "other" || useBrandOther);

    const newObject = {
      ...data,
      brandId: useBrandOther ? undefined : data.brand.split("-")[0],
      brandName: useBrandOther ? undefined : data.brand.split("-")[1],
      brandOther: useBrandOther ? brandOther : undefined,
      modelId: useModelOther ? undefined : data.model.split("-")[0],
      modelName: useModelOther ? undefined : data.model.split("-")[1],
      modelOther: useModelOther ? modelOther : undefined,
    };

    if (update) {
      updateListABoat(newObject)
        .then((res) => res.json())
        .then((json) => {
          if (data.photos) {
            uploadListABoatFiles(
              data.photos,
              json.requestId,
              handleUploadProgress
            );
          } else {
            setUpdateMessage("Updated Successfully");
          }
        });
    } else {
      postListABoat(newObject)
        .then((res) => res.json())
        .then((json) => {
          if (data.photos) {
            uploadListABoatFiles(
              data.photos,
              json.requestId,
              handleUploadProgress
            );
          } else {
            setCreateView(false);
          }
        });
    }
  };

  const handleUploadProgress = ({ done }) => {
    if (done) {
      setCreateView(false);
    }
  };

  return (
    <div className={styles["submit_boat_form"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles["boat_form"]}>
          <div className={styles["form"]}>
            <div className={styles["form_holder"]}>
              <div className={styles["input"]}>
                <label>
                  <span className="label_text">Boat Brand</span>
                  <select
                    // onChange={(e) => handleInput("brand", e.target.value)}
                    {...register("brand", {
                      required: "This field is required.",
                    })}
                    value={getBrand}
                  >
                    <option value="" selected>
                      Please Select
                    </option>
                    {brands &&
                      brands
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((brand) => (
                          <option
                            key={brand.brandId}
                            value={`${brand.brandId}-${brand.name}`}
                          >
                            {brand.name}
                          </option>
                        ))}
                    <option value="other">Other</option>
                  </select>
                </label>
                <div className="input__error-message">
                  {errors.brand?.message}
                </div>
              </div>
              <div className="input input--col">
                <label>
                  <span className="label_text">Model</span>
                  <select
                    // disabled={!formData.brand}
                    id="model"
                    className="input__outlined input__rounded-md"
                    value={getBrand === "other" ? "other" : getModel}
                    // onChange={(e) => handleInput("model", e.target.value)}
                    {...register("model", {
                      required: "This field is required.",
                    })}
                  >
                    <option value="" selected>
                      {getBrand ? "Please Select" : "Select the Brand"}
                    </option>
                    {models &&
                      models
                        .filter(
                          (model) =>
                            getBrand && getBrand.includes(model.brandId)
                        )
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((model) => (
                          <option
                            key={model.modelId}
                            value={`${model.modelId}-${model.name}`}
                          >
                            {model.name}
                          </option>
                        ))}
                    <option value="other">Other</option>
                  </select>
                </label>
                <div className="input__error-message">
                  {errors.model?.message}
                </div>
              </div>
              <TextField
                title="Year Built"
                // onChange={(e) => handleInput("year", e.target.value)}
                register={register}
                name="year"
                required="This field is required."
                error={errors.year?.message}
              />
            </div>
            <div
              className={
                getBrand === "other"
                  ? "hold_2 form_holder"
                  : "hold_1 form_holder"
              }
            >
              {getBrand === "other" && (
                <TextField
                  id="brandOther"
                  title="Other Brand"
                  register={register}
                  name="brandOther"
                  required="This field is required."
                  error={errors.brandOther?.message}
                />
              )}
              {(getModel === "other" || getBrand === "other") && (
                <TextField
                  id="modelOther"
                  title="Other Model"
                  register={register}
                  name="modelOther"
                  required="This field is required."
                  error={errors.modelOther?.message}
                />
              )}
            </div>
            {!update && (
              <div className="hold_3 form_holder">
                <TextField
                  title="First Name"
                  register={register}
                  name="firstname"
                  required="This field is required."
                  error={errors.firstname?.message}
                />
                <TextField
                  title="Last Name"
                  register={register}
                  name="lastname"
                  required="This field is required."
                  error={errors.lastname?.message}
                />
                <TextField
                  title="Phone Number"
                  className="phone_number"
                  type="tel"
                  register={register}
                  name="phone"
                  required="This field is required."
                  error={errors.phone?.message}
                />
              </div>
            )}

            <div className="hold_2 form_holder">
              {!update && (
                <TextField
                  title="Email"
                  register={register}
                  name="email"
                  required="This field is required."
                  error={errors.email?.message}
                />
              )}
              <div className="input">
                <label>
                  <span className="label_text">Are you a broker?</span>
                  <select
                    {...register("broker", {
                      required: "This field is required.",
                    })}
                  >
                    <option value="" selected>
                      Please Select
                    </option>
                    <option value="yes">
                      I am a broker, dealer, or manufacturer
                    </option>
                    <option value="no">I am an individual seller</option>
                  </select>
                </label>
                <div className="input__error-message">
                  {errors.broker?.message}
                </div>
              </div>
            </div>
            <div className="hold_2 form_holder">
              <TextField
                title="Current Location"
                placeholder="City, State"
                register={register}
                name="location"
                required="This field is required."
                error={errors.currentLocation?.message}
              />
              <TextField
                title="Electronics Brand"
                register={register}
                name="electronics"
                required="This field is required."
                error={errors.electronicBrand?.message}
              />
            </div>
            <div className="hold_2 form_holder">
              <TextField
                title="Hull ID Number - HIN"
                placeholder="(Optional)"
                register={register}
                name="HIN"
                error={errors.HIN?.message}
              />
              <TextField
                title="Engine Hours"
                placeholder="(Approximate)"
                register={register}
                name="engineHours"
                error={errors.engineHours?.message}
              />
            </div>
            <div className="hold_1 form_holder">
              <FormFileUpload
                extraDiv
                label="Upload photos of the boat for review"
                multiple
                limit={10}
                // onChange={(photos) => handleInput("photos", photos)}
                register={register}
                name="photos"
                error={errors.photos?.message}
                optional
                setValue={setValue}
                watch={watch}
              />
            </div>
            {/* <AuctionSubmitCheckBox
          {...{
            showSellerTerms,
            formData,
            user,
            errors,
            noFounding,
            handleInput,
          }}
        /> */}
            {update ? (
              <Button type="submit" variant="primary">
                Update Boat
              </Button>
            ) : (
              <Button type="submit" variant="primary">
                Submit Boat
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AuctionSubmitForm;
