import React, { useState } from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";

import Card from "@/components/common/Card/index.js";
import DealModal from "@/components/auctions/DealModal/index.js";
import AuctionsTable from "@/components/auctions/AuctionsTable/index.js";
import Dialog from "@/components/common/Dialog/index.js";
import OfferModal from "@/components/auctions/OfferModal/index.js";

import styles from "./Auctions.module.scss";
import SmallAuctionCards from "@/components/auctions/SmallAuctionCards/index.js";

const handleErrors = (dealObject) => {
  const newErrors = {};
  if (!dealObject.username) {
    newErrors.username = "This field is required.";
  }
  if (!dealObject.commission) {
    newErrors.commission = "This field is required.";
  }

  if (!dealObject.amount) {
    newErrors.amount = "This field is required.";
  }
  console.log(newErrors);
  return newErrors;
};

const Auctions = ({ auctions, deal, filter }) => {
  const [dealModal, setDealModal] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [relistModal, setRelistModal] = useState(false);
  const [auctionId, setAuctionId] = useState(null);
  const [filteredAuctionRows, setFilteredAuctionRows] = useState([]);
  const [auctionRows, setAuctionRows] = useState(null);
  const [error, setError] = useState({});
  const [reverse, setReverse] = useState(false);
  const [sortColumnName, setSortColumnName] = useState("expirationDate");

  const handleDeal = (listingId) => {
    setAuctionId(listingId);
    setDealModal(true);
  };

  const handleOffer = (listingId) => {
    setAuctionId(listingId);
    setOfferModal(true);
  };

  const handleRelist = (listingId) => {
    setAuctionId(listingId);
    setRelistModal(true);
  };

  const handlePutDeal = (dealObject) => {
    const newChatError = handleErrors(dealObject);

    if (Object.keys(newChatError).length) {
      setError(newChatError);
      return;
    }
    setError({});
    apiCall("/rest/bids/deal", {
      method: "PUT",
      body: JSON.stringify(dealObject),
    }).then((res) => {
      if (res.ok) {
        console.log("success");
        // dispatch(updateListing(auctionId));
        setDealModal(false);
      }
    });
  };

  const handlePutRelist = () => {
    apiCall(`/rest/relist/${auctionId}`, {
      method: "POST",
    }).then((res) => {
      if (res.ok) {
        setRelistModal(false);
      }
    });
  };

  return (
    <>
      <div className={styles["home"]}>
        <Card>
          <AuctionsTable
            {...{ auctions, filter, handleDeal, handleRelist, handleOffer }}
          />
          {dealModal && (
            <DealModal
              onCancel={() => {
                setDealModal(false);
                setError({});
              }}
              {...{ auctionId, handlePutDeal, setError, error }}
            />
          )}
          {relistModal && (
            <Dialog
              description="Are you sure you want to relist this auction?"
              onCancel={() => setRelistModal(false)}
              onAccept={handlePutRelist}
              acceptText="Relist"
            />
          )}
          {offerModal && <OfferModal {...{ auctionId, setOfferModal }} />}
        </Card>
      </div>
      <div className={styles["mobile"]}>
        <SmallAuctionCards {...{ auctions, filter }} />
      </div>
    </>
  );
};
Auctions.defaultProps = {
  deal: false,
  filter: [],
};

Auctions.propTypes = {
  auctions: PropTypes.object.isRequired,
  deal: PropTypes.bool,
  filter: PropTypes.array,
};

export default Auctions;
