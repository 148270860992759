import { useEffect, useState } from "react";
import MarketSmallAuctionCard from "@/components/market/MarketSmallAuctionCard";

const MarketSmallAuctionCards = ({ listings, filter }) => {
  const [filteredListings, setFilteredListings] = useState(null);
  useEffect(() => {
    let newCopyRows = listings;
    if (listings) {
      if (
        filter !== null &&
        typeof filter !== "undefined" &&
        filter.length !== 0
      ) {
        if (filter["buyNow"] && filter["buyNow"].length !== 0) {
          newCopyRows = newCopyRows.filter((listing) =>
            filter["buyNow"].includes(listing.buyNow)
          );
        }
        if (filter["status"] && filter["status"].length !== 0) {
          newCopyRows = newCopyRows.filter((listing) =>
            filter["status"].includes(listing.listingStatus)
          );
        }
        if (filter["category"] && filter["category"].length !== 0) {
          newCopyRows = newCopyRows.filter((listing) =>
            filter["category"].includes(listing.subcategory)
          );
        }
        setFilteredListings(newCopyRows);
      } else {
        setFilteredListings(listings);
      }
    }
  }, [listings, filter]);

  return (
    filteredListings &&
    filteredListings.map((auction) => (
      <MarketSmallAuctionCard {...{ auction }} />
    ))
  );
};

export default MarketSmallAuctionCards;
