import { useEffect, useState } from "react";
import SmallAuctionCard from "@/components/common/SmallAuctionCard/index.js";

const SmallAuctionCards = ({ auctions, filter }) => {
  const [filteredAuctions, setFilteredAuctions] = useState(null);

  useEffect(() => {
    if (auctions) {
      if (
        filter !== null &&
        typeof filter !== "undefined" &&
        filter.length !== 0
      ) {
        setFilteredAuctions(
          auctions.filter((auction) =>
            filter.includes(Number(auction.listingStatus))
          )
        );
      } else {
        setFilteredAuctions(auctions);
      }
    }
  }, [auctions, filter]);

  return (
    filteredAuctions &&
    filteredAuctions.map((auction) => <SmallAuctionCard {...{ auction }} />)
  );
};

export default SmallAuctionCards;
