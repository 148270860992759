import React, { useEffect } from "react";
import FileUpload from "./FileUpload";

const FormFileUpload = ({
  extraDiv,
  label,
  multiple,
  limit,
  register,
  name,
  error,
  optional,
  setValue,
  watch,
}) => {
  useEffect(() => {
    register(name);
  }, [register]);

  const onChange = (value) => {
    setValue(name, value);
  };

  const images = watch("photos");

  return (
    <FileUpload
      {...{
        extraDiv,
        label,
        multiple,
        limit,
        images,
        onChange,
        error,
        optional,
      }}
    />
  );
};

export default FormFileUpload;
