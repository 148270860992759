import React from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";
import { useDispatch } from "react-redux";
import Dialog from "@/components/common/Dialog";
import { deleteUser } from "@/store/slices/users.js";

const DeleteListingModal = ({ onCancel, handleDeleteSuccess, listingId }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    console.log(listingId);
    apiCall(`/rest/marketplacelisting/${listingId}`, {
      method: "DELETE",
    }).then((res) => {
      if (res.ok) {
        console.log("success");
        handleDeleteSuccess();
      }
    });
  };
  return (
    <Dialog
      onCancel={onCancel}
      onAccept={handleDelete}
      description={`Are you sure you want to delete listing ${listingId}`}
      acceptText="Delete"
      variant="danger"
    />
  );
};

DeleteListingModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleDeleteSuccess: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default DeleteListingModal;
