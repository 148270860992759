import React, { useEffect, useState } from "react";
import { apiCall } from "@boatlist/common/lib";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { BiX, BiXCircle } from "react-icons/all";
import Button from "@/components/common/Button";

import Card from "@/components/common/Card/";

import styles from "./FollowCard.module.scss";
import Modal from "@/components/common/Modal";
import IconButton from "@/components/common/IconButton";

const FollowCard = ({ filter }) => {
  const [follows, setFollows] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    apiCall(`/rest/follows${filter}`)
      .then((res) => res.json())
      .then((json) => setFollows(json));
  }, []);
  console.log(follows);

  const handleTitle = () => {
    if (filter === "categories") {
      return "Most Followed Categories";
    }
    if (filter === "brands") {
      return "Most Followed Brands";
    }
  };
  return (
    <div className={styles["card"]}>
      <Card>
        <div className={styles["watch-title"]}>{handleTitle()}</div>
        {follows.length !== 0 ? (
          <>
            {follows.slice(0, 5).map((follow) => (
              <div className={styles["content-box"]}>
                <div className={styles["content"]}>{follow.name}</div>
                <div className={styles["content"]}>{follow.total}</div>
              </div>
            ))}
            <div className={styles["show-more"]}>
              <Button variant="transparent" onClick={() => setShowMore(true)}>
                Show More
              </Button>
            </div>
          </>
        ) : (
          <div>No Boats Available</div>
        )}
      </Card>
      {showMore && (
        <Modal onCancel={() => setShowMore(false)}>
          <Card>
            <div className={styles["modal-box"]}>
              <div className={styles["modal-title"]}>
                {filter === "brands" ? (
                  <div>Top 10 Brands</div>
                ) : (
                  <div>Top 10 Categories</div>
                )}
                <div className={styles["close-button"]}>
                  <IconButton
                    variant="danger"
                    onClick={() => setShowMore(false)}
                    noPadding
                  >
                    <BiX />
                  </IconButton>
                </div>
              </div>
              {follows.map((follow) => (
                <div className={styles["content-box"]}>
                  <div className={styles["content"]}>{follow.name}</div>
                  <div className={styles["content"]}>{follow.total}</div>
                </div>
              ))}
            </div>
          </Card>
        </Modal>
      )}
    </div>
  );
};

FollowCard.propTypes = {
  filter: PropTypes.array.isRequired,
};

export default FollowCard;
