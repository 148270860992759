import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./TableCell.module.scss";
import ActionsPopper from "@/components/common/ActionsPopper/index.js";

const TableCell = ({ column, row }) => {
  if (column.type === "link" && row[column.name].link) {
    return (
      <Link to={row[column.name].link} className={styles["link"]}>
        {row[column.name].value}
      </Link>
    );
  }
  if (column.type === "action") {
    return (
      <button
        type="button"
        className={styles["link"]}
        onClick={() => row[column.name].onClick(row.id)}
      >
        {row[column.name].value}
      </button>
    );
  }
  if (column.type === "currency") {
    return `$${row[column.name].value.toLocaleString()}`;
  }
  if (column.type === "actions") {
    return (
      <div className={styles["actions"]}>
        {/* {row[column.name].map((action) => ( */}
        {/*  <button */}
        {/*    key={action.name} */}
        {/*    type="button" */}
        {/*    onClick={() => action.onClick(row.id)} */}
        {/*    className={styles["action"]} */}
        {/*  > */}
        {/*    {action.title} */}
        {/*  </button> */}
        {/* ))} */}
        <ActionsPopper actions={row[column.name]} row={row} />
      </div>
    );
  }
  if (column.type === "custom") {
    return <div className={styles[column.name]}>{row[column.name]()}</div>;
  }
  return row[column.name].value || "N/A";
};

TableCell.propTypes = {
  column: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
};

export default TableCell;
