import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CONSTANTS } from "@/lib/core";
import { getListings } from "@/lib/services";
import { selectAllListings } from "@/store/slices/listings";
import useLocalStorage from "@/hooks/useLocalStorage";

import Auctions from "@/components/auctions/Auctions";
import Button from "@/components/common/Button";
import Layout from "@/components/common/Layout";
import styles from "./AuctionsPage.module.scss";
import NewBoatSubmit from "./NewBoatSubmit";

const AuctionsPage = () => {
  const auctions = useSelector(selectAllListings);
  const [filter, setFilter] = useLocalStorage("auctionsFilter", []);
  const [createView, setCreateView] = useState(false);

  useEffect(() => {
    getListings();
  }, []);

  const handleActive = (name) => {
    const isFollowed = filter.includes(name);
    if (isFollowed) {
      const newArray = filter.filter((id) => {
        return id !== name;
      });
      setFilter(newArray);
    } else {
      setFilter(filter.concat(name));
    }
  };

  return (
    <Layout activePage={CONSTANTS.NAV.AUCTIONS}>
      {createView ? (
        <div className={styles["form-holder"]}>
          <NewBoatSubmit />
        </div>
      ) : (
        <div className={styles["auction-content"]}>
          <h2>Auctions Page</h2>
          <div className={styles["auction-holder"]}>
            <div className={styles["button-holder"]}>
              <Button
                variant="gray"
                onClick={() => handleActive(2)}
                active={filter.includes(2)}
                outline
                margin
              >
                Active
              </Button>
              <Button
                variant="gray"
                onClick={() => handleActive(1)}
                active={filter.includes(1)}
                outline
                margin
              >
                Scheduled
              </Button>
              <Button
                variant="gray"
                onClick={() => handleActive(0)}
                active={filter.includes(0)}
                outline
                margin
              >
                New
              </Button>
              <Button
                variant="gray"
                onClick={() => handleActive(3)}
                active={filter.includes(3)}
                outline
                margin
              >
                Ended
              </Button>
            </div>
            <div className={styles["flex-grow"]} />
            <Button variant="secondary" onClick={() => setCreateView(true)}>
              Create Listing
            </Button>
          </div>
          <Auctions {...{ auctions, filter }} deal />
        </div>
      )}
    </Layout>
  );
};

export default AuctionsPage;
