import React, { useEffect, useState } from "react";
import TextField from "@/components/common/TextField";
import Card from "@/components/common/Card";

import styles from "./AuctionsPage.module.scss";

const BoatSpecs = ({
  listingSchema,
  register,
  errors,
  handleNext,
  showStep,
  trigger,
  getValues,
  watch,
  updateListing,
}) => {
  const [nameArray, setNameArray] = useState("year");
  useEffect(() => {
    if (listingSchema) {
      setNameArray([
        ...nameArray,
        listingSchema.filter((item) => item.mandatory).map((data) => data.name),
      ]);
    }
  }, [listingSchema]);

  const year = watch("year");

  const handleNextStep = () => {
    const values = getValues();
    const filteredArray = nameArray.filter(
      (name) => values.categoryAttributes[name] === ""
    );

    if (!year) {
      trigger("year");
    }

    if (filteredArray.length !== 0) {
      trigger(filteredArray);
    } else {
      handleNext();
    }
  };
  const handleField = (item) => {
    if (item.visible === true) {
      return (
        <TextField
          title={item.label}
          register={register}
          name={`categoryAttributes.${item.name}`}
          type={item.type}
          required={item.mandatory && "This field is required."}
          error={errors[item.name]?.message}
        />
      );
    }
  };

  return (
    <Card color={updateListing && "blue"}>
      <h3>Boat Specs</h3>
      <TextField
        title="Year Built"
        register={register}
        name="year"
        required="This field is required."
        error={errors.year?.message}
      />
      <div className={styles["boat-specs-list"]}>
        {listingSchema && listingSchema.map((item) => handleField(item))}
      </div>
      {showStep === 1 && (
        <div className="submit text_right">
          <button onClick={handleNextStep} type="button" className="bt">
            Next: Listing Type
          </button>
        </div>
      )}
    </Card>
  );
};

export default BoatSpecs;
