import { apiCall } from "@boatlist/common/lib";
import { deleteListABoatFiles, uploadListABoatFiles } from "./ListABoatApiCall";

export function getMarketListingByUser(userId) {
  return apiCall(`/rest/marketplacelistings/${userId}`).then((res) =>
    res.json()
  );
}

export function updateMarketStartDate(startDateObject) {
  return apiCall(`/rest/marketplacelistingstartdate`, {
    method: "PUT",
    body: JSON.stringify(startDateObject),
  });
}

export function publishListingPhotos(id) {
  return apiCall(`/rest/marketplacelisting-pictures/${id}`, {
    method: "POST",
  });
}

export function publishListing(id, futureDate, publishPhotos) {
  if (publishPhotos) {
    publishListingPhotos(id).then((res) => {
      if (res.ok) {
        apiCall(`/rest/marketplacelisting-html/${id}`, {
          method: "POST",
        }).then((data) => {
          if (data.ok) {
            if (futureDate) {
              window.location.href = `/profile#marketplace`;
            } else {
              window.location.href = `/marketplace/${id}`;
            }
          }
        });
      }
    });
  } else {
    apiCall(`/rest/marketplacelisting-html/${id}`, {
      method: "POST",
    }).then((data) => {
      if (data.ok) {
        if (futureDate) {
          window.location.href = `/profile#marketplace`;
        } else {
          window.location.href = `/marketplace/${id}`;
        }
      }
    });
  }
}

export function createListing(listingObject, handleUploadProgress, photoFiles) {
  return new Promise((resolve, reject) => {
    apiCall(`/rest/marketplacelisting`, {
      method: "POST",
      body: JSON.stringify(listingObject),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          if (photoFiles) {
            uploadListABoatFiles(
              photoFiles,
              json.listingId,
              handleUploadProgress
            ).then((res) => resolve(json.listingId));
          }
        }
      });
  });
}

export function updateListingCall(
  listingObject,
  handleUploadProgress,
  listingId,
  photoOG,
  shouldUpdateStartDate,
  photoFiles
) {
  const promises = [];
  return new Promise((resolve, reject) => {
    apiCall(`/rest/marketplacelisting/${listingId}`, {
      method: "PUT",
      body: JSON.stringify(listingObject),
    }).then((res) => {
      if (res.ok) {
        if (shouldUpdateStartDate) {
          const dateTimeObject = {
            listingId,
            startDate: listingObject.startDate,
            listingLength: listingObject.listingLength,
          };
          // here push a promise
          const updateMarketDate = updateMarketStartDate(dateTimeObject);
          promises.push(updateMarketDate);
        }
        const deleteMatch = photoOG.filter(
          (file) => !photoFiles.find((image) => image.name === file.name)
        );
        if (deleteMatch.length !== 0) {
          const deleteArray = deleteMatch.map((image) => ({
            fileName: image.name,
          }));
          // here push a promise
          const deleteFiles = deleteListABoatFiles(deleteArray, listingId);
          promises.push(deleteFiles);
        }
        const match = photoFiles.filter(
          (file) => !photoOG.find((image) => image.name === file.name)
        );
        if (match.length !== 0) {
          // here push a promise
          const uploadFiles = uploadListABoatFiles(
            match,
            listingId,
            handleUploadProgress
          );
          promises.push(uploadFiles);
        } else {
          // setTimeout(
          //   () =>
          //     (window.location.href = `/marketplacepreview/?id=${listingId}`),
          //   2000
          // );
        }
        Promise.all(promises).then((res) => {
          if (listingId) {
            resolve(listingId);
          } else {
            window.location.href = `/marketplace/preview/?id=${listingId}`;
          }
        });
      }
    });
  });
}

export function relistListing(listing) {
  const listingObject = {
    ...listing,
    listingId: undefined,
    listingStatus: 0,
    startDate: new Date().toISOString(),
  };

  const dateTimeObject = {
    listingId: listing.listingId,
    startDate: listingObject.startDate,
    listingLength: 30,
  };

  if (listingObject.relistCounter) {
    listingObject.relistCounter += 1;
  } else {
    listingObject.relistCounter = 1;
  }

  apiCall(`/rest/marketplacelisting/${listing.listingId}`, {
    method: "PUT",
    body: JSON.stringify(listingObject),
  }).then((res) => {
    if (res.ok) {
      updateMarketStartDate(dateTimeObject).then((data) => {
        if (data.ok) {
          publishListing(listing.listingId);
        }
      });
    }
  });
}