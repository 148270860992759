import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CONSTANTS } from "@/lib/core/index.js";

import styles from "./RequestSmallAuctionCard.module.scss";
import Card from "@/components/common/Card/Card.jsx";
import Label from "@/components/common/Label/index.js";

const RequestSmallAuctionCard = ({ request }) => {
  const handleDate = (expirationDate, startDate) => {
    if (expirationDate && !startDate) {
      const date = new Date(expirationDate).toString();
      const dateArray = date.split(" ", 5);
      // console.log(date);

      return `${dateArray[1]} ${dateArray[2]}`;
    }
    if (expirationDate && startDate) {
      const start = new Date(startDate).toString();
      const startDateArray = start.split(" ", 5);

      const date = new Date(expirationDate).toString();
      const dateArray = date.split(" ", 5);

      return `${startDateArray[1]} ${startDateArray[2]} - ${dateArray[1]} ${dateArray[2]}`;
    }

    return "No date available";
  };

  const getTitle = (listing) => {
    return `${listing.brandName ? listing.brandName : listing.brandOther} ${
      listing.modelName ? listing.modelName : listing.modelOther
    }`;
  };

  const getListingStatusLabel = (requestStatus) => {
    switch (requestStatus) {
      case CONSTANTS.REQUEST_STATUS.NEW:
        return (
          <Label variant="secondary" size="md">
            New
          </Label>
        );
      case CONSTANTS.REQUEST_STATUS.APPROVED:
        return (
          <Label variant="primary" size="md">
            Approved
          </Label>
        );
      case CONSTANTS.REQUEST_STATUS.REJECTED:
        return (
          <Label variant="danger" size="md">
            Rejected
          </Label>
        );
      case CONSTANTS.REQUEST_STATUS.LISTED:
        return (
          <Label variant="success" size="md">
            Listed
          </Label>
        );
      default:
        return (
          <Label variant="primary" size="md">
            Canceled
          </Label>
        );
    }
  };

  return (
    <div className={styles["small_auction"]}>
      <Card style={{ padding: "10px" }}>
        <div className={styles["auction-box"]}>
          <div className={styles["middle-box"]}>
            <div className={styles["auction-title"]}>
              <Link
                to={`/marketplace/${request.listingId}`}
                className={styles["auction-title__link"]}
              >
                {getTitle(request)}
              </Link>
            </div>
            <div className={styles["date-name"]}>
              {request.username ? request.username : request.email}
            </div>
            <div className={styles["reserve-box"]}>{request.location}</div>
            <div className={styles["bid-content"]}>
              <div className={styles["label-box"]}>
                {getListingStatusLabel(request.requestStatus)}
              </div>
              <div className={styles["bid-number"]}>
                {handleDate(request.requestDate)}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RequestSmallAuctionCard;
