import React, { useEffect } from "react";
import TextEditor from "@/components/common/TextEditor/index.js";
import TextField from "@/components/common/TextField/index.js";
import Card from "@/components/common/Card";

import styles from "./MarketListingForm.module.scss";
import Select from "@/components/common/Select";

const TitleAndDescription = ({
  errors,
  updateListing,
  register,
  setValue,
  getValues,
}) => {
  const states = [
    { name: "Alabama", label: "AL" },
    { name: "Alaska", label: "AK" },
    { name: "Arizona", label: "AZ" },
    { name: "Arkansas", label: "AR" },
    { name: "California", label: "CA" },
    { name: "Colorado", label: "CO" },
    { name: "Connecticut", label: "CT" },
    { name: "Delaware", label: "DE" },
    { name: "Florida", label: "FL" },
    { name: "Georgia", label: "GA" },
    { name: "Hawaii", label: "HI" },
    { name: "Idaho", label: "ID" },
    { name: "Illinois", label: "IL" },
    { name: "Indiana", label: "IN" },
    { name: "Iowa", label: "IA" },
    { name: "Kansas", label: "KS" },
    { name: "Kentucky", label: "KY" },
    { name: "Louisiana", label: "LA" },
    { name: "Maine", label: "ME" },
    { name: "Maryland", label: "MD" },
    { name: "Massachusetts", label: "MA" },
    { name: "Michigan", label: "MI" },
    { name: "Minnesota", label: "MN" },
    { name: "Mississippi", label: "MS" },
    { name: "Missouri", label: "MO" },
    { name: "Montana", label: "MT" },
    { name: "Nebraska", label: "NE" },
    { name: "Nevada", label: "NV" },
    { name: "New Hampshire", label: "NH" },
    { name: "New Jersey", label: "NJ" },
    { name: "New Mexico", label: "NM" },
    { name: "New York", label: "NY" },
    { name: "North Carolina", label: "NC" },
    { name: "North Dakota", label: "ND" },
    { name: "Ohio", label: "OH" },
    { name: "Oklahoma", label: "OK" },
    { name: "Oregon", label: "OR" },
    { name: "Pennsylvania", label: "PA" },
    { name: "Rhode Island", label: "RI" },
    { name: "South Carolina", label: "SC" },
    { name: "South Dakota", label: "SD" },
    { name: "Tennessee", label: "TN" },
    { name: "Texas", label: "TX" },
    { name: "Utah", label: "UT" },
    { name: "Vermont", label: "VT" },
    { name: "Virginia", label: "VA" },
    { name: "Washington", label: "WA" },
    { name: "West Virginia", label: "WV" },
    { name: "Wisconsin", label: "WI" },
    { name: "Wyoming", label: "WY" },
  ];

  const longText = getValues("longText");

  useEffect(() => {
    register("longText", { required: "This field is required." });
  }, [register]);

  const onChange = (value, name) => {
    setValue(name, value);
  };

  return (
    <Card color={updateListing && "blue"}>
      <div className={styles["heading"]}>Information</div>
      <TextField
        title="Title"
        type="text"
        register={register}
        name="title"
        required="This field is required."
        error={errors.title?.message}
        // onChange={(e) => handleInput(e.target.value, "title")}
      />
      {/* <TextField
      title="Long Description"
      type="text"
      value={listing.longText}
      onChange={(e) => handleInput(e.target.value, "longText")}
      error={error.longText}
    /> */}
      <TextEditor
        label="Long Text"
        value={longText}
        error={errors.longText?.message}
        onChange={(value) => onChange(value, "longText")}
      />
      <Select
        label="State"
        // onChange={(e) => handleInput(e.target.value, "state")}
        register={register}
        name="state"
        required="This field is required."
        error={errors.state?.message}
      >
        <option value="" />
        {states.map((state) => (
          <option value={state.label}>{state.name}</option>
        ))}
      </Select>
      <TextField
        title="City"
        // onChange={(e) => handleInput(e.target.value, "city")}
        register={register}
        name="city"
        required="This field is required."
        error={errors.city?.message}
      />
    </Card>
  );
};

export default TitleAndDescription;
