import React, { useEffect } from "react";
import Card from "@/components/common/Card";
import styles from "./AuctionsPage.module.scss";
import TextField from "../../components/common/TextField";

const ListingTypeCard = ({
  formData,
  handleInput,
  updateListing,
  handleNext,
  showStep,
  errors,
  setShowStep,
  register,
  setValue,
  watch,
  trigger,
  getValues,
  clearErrors,
}) => {
  const nameArray = ["buyNow"];

  const handleNextStep = () => {
    const values = getValues();
    const filteredArray = nameArray.filter((name) => values[name] === null);

    if (filteredArray.length !== 0) {
      trigger(filteredArray);
      clearErrors();
    } else {
      clearErrors();
      handleNext();
    }
  };

  useEffect(() => {
    register("buyNow", { required: "This field is required." });
  }, [register]);

  const onChange = (value, name) => {
    setValue(name, Number(value));
  };

  const buyNow = watch("buyNow");

  return (
    <Card color={updateListing && "blue"}>
      <div className="form_slide_display">
        <h3 className="h24 bottom_30 nocap">Listing Types</h3>
        <div className="hold_2 form_holder">
          <div className="radio checkbox">
            <label>
              <input
                type="radio"
                value={0}
                checked={buyNow === 0}
                onChange={(e) => onChange(e.target.value, "buyNow")}
              />
              <span className="display_checkbox" />
              <span className="label_text checkbox_text">
                Fixed Price{" "}
                <span className="instructions">
                  Set the price you are willing to accept for your item
                </span>
              </span>
            </label>
          </div>
          <div className="radio checkbox disabled">
            <label>
              <input
                type="radio"
                value={2}
                checked={buyNow === 2}
                onChange={(e) => onChange(e.target.value, "buyNow")}
              />
              <span className="display_checkbox" />
              <span className="label_text checkbox_text">
                Auction{" "}
                <span className="instructions">
                  Receive bids for the item you are selling
                </span>
              </span>
            </label>
          </div>
        </div>
        {errors.buyNow?.message && (
          <div className={styles["input__error-message"]}>
            {errors.buyNow?.message}
          </div>
        )}
        {showStep === 2 && (
          <div className="submit text_right">
            <button onClick={handleNextStep} type="button" className="bt">
              Next: Listing Specs
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ListingTypeCard;
