import React, { useRef, useState } from "react";
import MarketListingForm from "@/components/forms/MarketListingForm/index.js";
import styles from "@/components/forms/AuctionForm/AuctionForm.module.scss";
import UpdateMessage from "./UpdateMessage";

const MarketUpdateTab = ({ user, listingId }) => {
  const messageRef = useRef();
  const [updateMessage, setUpdateMessage] = useState(null);
  return (
    <div className={styles["form-box"]}>
      <UpdateMessage {...{ updateMessage, messageRef }} />
      <MarketListingForm
        {...{ user, listingId, setUpdateMessage }}
        updateListing
      />
    </div>
  );
};

export default MarketUpdateTab;
