import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { apiCall, getConfig } from "@boatlist/common/lib";
import { CONSTANTS } from "@/lib/core/index.js";

import DataTable from "@/components/common/DataTable/index.js";
import Label from "@/components/common/Label/index.js";
import Card from "@/components/common/Card/index.js";
import GetReservePrice from "@/components/auctions/Auctions/GetReservePrice.jsx";
import GetHighestBidUser from "@/components/auctions/Auctions/GetHighestBidUser/GetHighestBidUser.jsx";
import DealModal from "@/components/auctions/DealModal/index.js";

const columns = [
  {
    id: "listingId",
    name: "listingId",
    title: "ID",
    className: "listingId",
    isSortable: true,
    width: "100px",
  },
  {
    id: "title",
    name: "title",
    title: "Title",
    className: "title",
    value: "listingId",
    type: "link",
    isSortable: true,
    width: "100%",
  },
  {
    id: "expirationDate",
    name: "expirationDate",
    title: "Expiration Date",
    className: "expDate",
    isSortable: true,
    width: "50%",
  },
  {
    id: "listingStatus",
    name: "listingStatus",
    title: "Listing Status",
    className: "status",
    isSortable: true,
    width: "30%",
  },
  {
    id: "actions",
    title: "Actions",
    name: "actions",
    className: "actions",
    type: "actions",
    width: "20%",
  },
];

const LISTING_STATUS = {
  NEW: 0,
  ACTIVE: 1,
  LIVE: 2,
  ENDED: 3,
  CANCELLED: 4,
  POSTDEAL: 5,
};

const getAuctionRows = (auctions, actions) => {
  const getListingStatusLabel = (listingStatus) => {
    switch (listingStatus) {
      case LISTING_STATUS.NEW:
        return <Label variant="secondary">New</Label>;
      case LISTING_STATUS.ACTIVE:
        return <Label variant="primary">Scheduled</Label>;
      case LISTING_STATUS.LIVE:
        return <Label variant="success">Active</Label>;
      case LISTING_STATUS.ENDED:
        return <Label variant="danger">Ended</Label>;
      case LISTING_STATUS.POSTDEAL:
        return <Label variant="danger">Post Deal</Label>;
      default:
        return <Label variant="danger">Canceled</Label>;
    }
  };

  const getExpirationDate = (expirationDate) => {
    if (expirationDate) {
      const date = new Date(expirationDate).toLocaleString("en-us", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      return date;
    }

    return "No date available";
  };

  return auctions.map((auction) => ({
    id: auction.listingId,
    listingId: {
      sortValue: auction.listingId,
      value: auction.listingId,
    },
    title: {
      sortValue: auction.title,
      value: auction.title,
      link: `/auctions/${auction.listingId}`,
    },
    username: {
      sortValue: auction.username,
      value: auction.username,
      link: `/users/${auction.userId}`,
    },
    expirationDate: {
      sortValue: new Date(auction.expirationDate).getTime(),
      value: getExpirationDate(auction.expirationDate),
    },
    reserve: {
      value: <GetReservePrice id={auction.listingId} />,
    },
    highestBid: {
      sortValue: auction.highestBid,
      value: Number(auction.highestBid) || 0,
    },
    highestBidUser: {
      sortValue: auction.highestBidUser,
      value: <GetHighestBidUser username={auction.highestBidUser} />,
    },
    listingStatus: {
      filterValue: Number(auction.listingStatus),
      value: getListingStatusLabel(auction.listingStatus),
    },
    auction: {
      value: auction,
    },
    actions,
  }));
};

const handleErrors = (dealObject) => {
  const newErrors = {};
  if (!dealObject.username) {
    newErrors.username = "This field is required.";
  }
  if (!dealObject.commission) {
    newErrors.commission = "This field is required.";
  }

  if (!dealObject.amount) {
    newErrors.amount = "This field is required.";
  }
  console.log(newErrors);
  return newErrors;
};

const UserAuctionsTable = ({ auctions }) => {
  const [auctionRows, setAuctionRows] = useState(null);
  const [dealModal, setDealModal] = useState(false);
  const [auctionId, setAuctionId] = useState(null);
  const [error, setError] = useState({});

  useEffect(() => {
    if (auctions) {
      const actions = [
        {
          name: "deal",
          title: "Deal",
          onClick: (listingId) => {
            console.log(listingId);
            handleDeal(listingId);
          },
        },
        {
          name: "view",
          title: "View",
          onClick: (listingId) => {
            console.log(listingId);
            window.open(
              `${getConfig().base_content_url}/auctions/${listingId}`,
              "_blank"
            );
          },
        },
      ];
      setAuctionRows(getAuctionRows(auctions, actions));
    }
  }, [auctions]);

  const handleDeal = (listingId) => {
    setAuctionId(listingId);
    setDealModal(true);
  };

  const handlePutDeal = (dealObject) => {
    const newChatError = handleErrors(dealObject);

    if (Object.keys(newChatError).length) {
      setError(newChatError);
      return;
    }
    setError({});
    apiCall("/rest/bids/deal", {
      method: "PUT",
      body: JSON.stringify(dealObject),
    }).then((res) => {
      if (res.ok) {
        console.log("success");
        // dispatch(updateListing(auctionId));
        setDealModal(false);
      }
    });
  };
  return (
    <div>
      <Card>
        <DataTable
          id="auctions"
          defaultSort="expirationDate"
          rows={auctionRows}
          {...{ columns }}
        />
      </Card>
      {dealModal && (
        <DealModal
          onCancel={() => {
            setDealModal(false);
            setError({});
          }}
          {...{ auctionId, handlePutDeal, setError, error }}
        />
      )}
    </div>
  );
};

UserAuctionsTable.propTypes = {};

export default UserAuctionsTable;
