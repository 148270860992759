import * as toolkitRaw from "@reduxjs/toolkit";
const { createSlice } = toolkitRaw.default ?? toolkitRaw;

export const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    comments: [],
  },
  reducers: {
    addComments: (state, action) => {
      state.comments = action.payload;
    },
    addComment: (state, action) => {
      state.comments.push(action.payload);
    },
    updateComment: (state, action) => {
      state;
    },
    deleteComment: (state) => {
      state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addComment,
  addComments,
  setActiveComment,
  updateComment,
  deleteComment,
} = commentsSlice.actions;

export const selectAllComments = (state) => state.comments.comments;

export default commentsSlice.reducer;
