import { configureStore } from "@reduxjs/toolkit";
import {
  listingsReducer,
  usersReducer,
  commentsReducer,
  followListReducer,
  watchListReducer,
  questionsReducer,
  bidsReducer,
} from "./slices";

export default configureStore({
  reducer: {
    listings: listingsReducer,
    users: usersReducer,
    comments: commentsReducer,
    followList: followListReducer,
    watchList: watchListReducer,
    questions: questionsReducer,
    bids: bidsReducer,
  },
});
