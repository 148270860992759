import React, { useState } from "react";
import { apiCall } from "@boatlist/common/lib";
import TextField from "@/components/common/TextField/index.js";
import Button from "@/components/common/Button/index.js";
import styles from "./QuestionAndAnswer.module.scss";

const DeclineOffer = ({ question }) => {
  const [messageObject, setMessageObject] = useState(null);

  const handleInput = (value) => {
    setMessageObject(value);
  };

  const handleDecline = () => {
    const acceptObject = {
      listingId: question.listingId,
      bidId: question.bidId,
      accept: false,
      message: messageObject,
    };

    apiCall(`/rest/offer/decline`, {
      method: "PUT",
      body: JSON.stringify(acceptObject),
    }).then((res) => {
      if (res.ok) {
        console.log("success");
      }
    });
  };

  return (
    <div>
      <TextField
        title="Message"
        name="message"
        placeholder="Reason (optional)"
        onChange={(e) => handleInput(e.target.value)}
      />
      <div className={styles["decline-button"]}>
        <Button variant="danger" onClick={() => handleDecline()}>
          Decline
        </Button>
      </div>
    </div>
  );
};

export default DeclineOffer;
