import React, { useEffect, useState } from "react";
import { apiCall } from "@boatlist/common/lib";
import styles from "@/pages/SingleUserPage/SingleUserPage.module.scss";
import Card from "@/components/common/Card/index.js";

const NotificationsCard = ({ user }) => {
  const [notifications, setNotifications] = useState({});
  useEffect(() => {
    apiCall(`/rest/usernotifications/${user.id}`)
      .then((res) => res.json())
      .then((json) => setNotifications(json));
  }, []);

  return (
    <Card variant="fit" color="primary">
      <div className={styles["user-details"]}>Notifications</div>
      <div className={styles["user-notifs"]}>
        <div className={styles["checkbox"]}>
          <input type="checkbox" id="news" checked={notifications.news} />
          <span>BL News & Updates</span>
        </div>
        <div className={styles["checkbox"]}>
          <input type="checkbox" id="blog" checked={notifications.blog} />
          <span>Blog Posts</span>
        </div>
        <div className={styles["checkbox"]}>
          <input type="checkbox" id="events" checked={notifications.events} />
          <span>Events</span>
        </div>
        <div className={styles["checkbox"]}>
          <input type="checkbox" id="sms" checked={notifications.sms} />
          <span>SMS Notifications</span>
        </div>
        <div className={styles["checkbox"]}>
          <input
            type="checkbox"
            id="catbrandmodel"
            checked={notifications.catbrandmodel}
          />
          <span>Pause all listing notifications</span>
        </div>
      </div>
    </Card>
  );
};

export default NotificationsCard;
