import React from "react";
import { Link } from "react-router-dom";
import { CONSTANTS } from "@/lib/core/index.js";
import Label from "@/components/common/Label";

import styles from "./SingleMarketListingPage.module.scss";

const SingleMarketDetailsCard = ({ listing }) => {
  const handleDate = (expirationDate) => {
    if (expirationDate) {
      const date = new Date(expirationDate).toLocaleString("en-us", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      return date;
    }
    return "No date available";
  };
  const handleBuyNow = (buyNow) => {
    switch (buyNow) {
      case CONSTANTS.BUYNOW_STATUS.BUY_NOW:
        return (
          <Label variant="secondary" size="md">
            Buy Now
          </Label>
        );
      case CONSTANTS.BUYNOW_STATUS.TILL_AUCTION:
        return (
          <Label variant="primary" size="md">
            Buy Now Until Auction
          </Label>
        );
      case CONSTANTS.BUYNOW_STATUS.AUCTION:
        return (
          <Label variant="danger" size="md">
            Auction Only
          </Label>
        );
      default:
        return (
          <Label variant="danger" size="md">
            undefined
          </Label>
        );
    }
  };
  return (
    <div className={styles["auction-details"]}>
      <div className={styles["details"]}>Listing Details</div>
      <div className={styles["color-box"]}>
        <div className={styles["auction-header"]}>
          <div className={styles["header"]}>Auction ID</div>
          <div className={styles["header"]}>Seller</div>
        </div>
        <div className={styles["auction-holder"]}>
          <div className={styles["content"]}>{listing.listingId}</div>
          <div className={styles["username"]}>
            <Link
              to={`/users/${listing.userId}`}
              className={styles["seller__link"]}
            >
              {listing.username}
            </Link>
          </div>
        </div>
        <div className={styles["auction-header"]}>
          <div className={styles["header"]}>Starts</div>
          <div className={styles["header"]}>Ends</div>
        </div>
        <div className={styles["auction-holder"]}>
          <div className={styles["content"]}>
            {handleDate(listing.startDate)}
          </div>
          <div className={styles["content"]}>
            {handleDate(listing.expirationDate)}
          </div>
        </div>
        <div className={styles["auction-header"]}>
          {listing.buyNow === 0 || listing.buyNow === 1 ? (
            <div className={styles["header"]}>Price</div>
          ) : (
            <div className={styles["header"]}>Reserve</div>
          )}
          <div className={styles["header"]}>Starting Bid</div>
        </div>
        <div className={styles["auction-holder"]}>
          <div className={styles["content"]}>
            {listing.buyNow === 0 || listing.buyNow === 1 ? (
              <Label variant="secondary" size="md">
                ${listing.price && listing.price.toLocaleString()}
              </Label>
            ) : (
              <Label variant="secondary" size="md">
                ${listing.reserve && listing.reserve.toLocaleString()}
              </Label>
            )}
          </div>
          {/* <div className={styles["bid"]}> */}
          {/*  <Label variant="secondary" size="md"> */}
          {/*    ${auction.highestBid && auction.highestBid.toLocaleString()} */}
          {/*  </Label> */}
          {/*  <div className={styles["bid-user"]}> */}
          {/*    {auction.highestBidUser && ( */}
          {/*      <GetHighestBidUser username={auction.highestBidUser} /> */}
          {/*    )} */}
          {/*  </div> */}
          {/* </div> */}
          <div className={styles["content"]}>
            <Label variant="primary" size="md">
              ${listing.startingBid ? listing.startingBid.toLocaleString() : 0}
            </Label>
          </div>
        </div>
        <div className={styles["auction-header"]}>
          <div className={styles["header"]}>Buy Now Status</div>
          <div className={styles["header"]}>Duration</div>
        </div>
        <div className={styles["auction-holder"]}>
          <div className={styles["content"]}>
            {handleBuyNow(listing.buyNow)}
          </div>
          <div className={styles["content"]}>{listing.listingLength} days</div>
        </div>
        <div className={styles["auction-header"]}>
          <div className={styles["header"]}>Category</div>
          <div className={styles["header"]}>Sub-Category</div>
        </div>
        <div className={styles["auction-holder"]}>
          <div className={styles["content"]}>{listing.categoryName}</div>
          <div className={styles["content"]}>{listing.subcategory}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleMarketDetailsCard;
