import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./CreateUser.module.scss";
import TextField from "@/components/common/TextField/index.js";
import Button from "@/components/common/Button/index.js";
import { signupUser } from "@/lib/services/index.js";
import { useForm } from "react-hook-form";

const CreateUser = ({ setCreateView, setSuccessMessage }) => {
  const [submitError, setSubmitError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    signupUser({ ...data }).then((res) => {
      if (res.ok) {
        console.log("Created");
        // window.location.href = "/users";
        // close view add message
        setCreateView(false);
        setSuccessMessage(true);
      } else {
        setSubmitError(res.error);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles["user-box"]}>
        {submitError && <div>{submitError}</div>}
        <TextField
          title="Email"
          register={register}
          label="email"
          required="This is required."
          error={errors.email?.message}
          // onChange={(e) => handleInput("email", e.target.value)}
          variant="inputClass"
        />
        <TextField
          title="Username"
          register={register}
          label="username"
          required="This is required."
          error={errors.username?.message}
          // onChange={(e) => handleInput("username", e.target.value)}
          variant="inputClass"
        />
        <TextField
          title="Password"
          className="password"
          type="password"
          register={register}
          label="password"
          required="This is required."
          error={errors.password?.message}
          // onChange={(e) => handleInput("password", e.target.value)}
          variant="inputClass"
        />
        <div className={styles["user-buttons"]}>
          <Button type="submit" variant="primary">
            Submit
          </Button>
          <Button variant="secondary" onClick={() => setCreateView(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

CreateUser.propTypes = {
  setCreateView: PropTypes.func.isRequired,
};

export default CreateUser;
