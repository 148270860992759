import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "@/pages/SingleUserPage/SingleUserPage.module.scss";
import TextField from "@/components/common/TextField/index.js";
import Button from "@/components/common/Button/index.js";

const ContactInfo = ({ handleInput, user, handleUpdate }) => {
  const navigate = useNavigate();
  return (
    <div className={styles["info-outer"]}>
      <div className={styles["info-boxes"]}>
        <div className={styles["user-details"]}>Contact Info</div>
        <div className={styles["name"]}>
          <TextField
            title="First Name"
            type="text"
            value={user.firstname}
            onChange={(e) => handleInput("firstname", e.target.value)}
          />
          <TextField
            title="Last Name"
            type="text"
            value={user.lastname}
            onChange={(e) => handleInput("lastname", e.target.value)}
          />
        </div>
        <div className={styles["stretch"]}>
          <TextField
            title="Phone Number"
            className="phone_number"
            type="text"
            value={user.phone}
            onChange={(e) => handleInput("phone", e.target.value)}
          />
          {/*  <TextField */}
          {/*    title="Address" */}
          {/*    type="text" */}
          {/*    value={user.address} */}
          {/*    onChange={(e) => handleInput("address", e.target.value)} */}
          {/*  /> */}
          {/*  <TextField */}
          {/*    title="City" */}
          {/*    type="text" */}
          {/*    value={user.city} */}
          {/*    onChange={(e) => handleInput("city", e.target.value)} */}
          {/*  /> */}
          {/* </div> */}
          {/* <div className={styles["name"]}> */}
          {/*  <TextField */}
          {/*    title="State" */}
          {/*    type="text" */}
          {/*    value={user.state} */}
          {/*    onChange={(e) => handleInput("state", e.target.value)} */}
          {/*  /> */}
          {/*  <TextField */}
          {/*    title="Zip Code" */}
          {/*    type="text" */}
          {/*    value={user.zip} */}
          {/*    onChange={(e) => handleInput("zip", e.target.value)} */}
          {/*  /> */}
        </div>
      </div>
      <div className={styles["info-buttons"]}>
        <Button variant="primary" onClick={() => handleUpdate("contact")}>
          Update
        </Button>
        <Button variant="secondary" link="link" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

ContactInfo.propTypes = {
  user: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
};

export default ContactInfo;
