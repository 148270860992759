import React from "react";

import { Dropdown } from "@boatlist/common/components";
import { BiDotsHorizontalRounded } from "react-icons/all";

import PropTypes from "prop-types";
import IconButton from "@/components/common/IconButton";

import styles from "./ActionsPopper.module.scss";

const ActionsPopperTrigger = React.forwardRef((props, ref) => {
  return (
    <button
      type="button"
      className={styles["actionsPopper__trigger"]}
      ref={ref}
      {...props}
    >
      <IconButton variant="secondary" noPadding size="md">
        <BiDotsHorizontalRounded />
      </IconButton>
    </button>
  );
});

const ActionsPopper = ({ actions, row }) => {
  return (
    <div className={styles["actionsPopper"]}>
      <Dropdown
        Trigger={ActionsPopperTrigger}
        contentClassName={styles["actionsPopper"]}
        triggerClasses={styles[""]}
      >
        <div className={styles["actionsPopper__content"]}>
          {actions.map((action) => (
            <button
              type="button"
              className={styles["actionsPopper__item"]}
              onClick={() => action.onClick(row.id)}
            >
              {action.title}
            </button>
          ))}
        </div>
      </Dropdown>
    </div>
  );
};

ActionsPopper.propTypes = {
  actions: PropTypes.array.isRequired,
  rows: PropTypes.array,
};

export default ActionsPopper;
