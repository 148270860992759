import React from "react";
import PropTypes from "prop-types";
import ReactEmailEditor from "react-email-editor";

const EmailEditor = React.forwardRef(({ template }, ref) => {
  const onReady = () => {
    // editor is ready
    // you can load your template here;
    ref.current.editor.loadDesign(template.design);
  };
  return <ReactEmailEditor ref={ref} onReady={onReady} minHeight="100%" />;
});

EmailEditor.propTypes = {
  template: PropTypes.shape({
    design: PropTypes.shape(),
  }).isRequired,
};

export default EmailEditor;
