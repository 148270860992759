import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { apiCall } from "@boatlist/common/lib";
import styles from "./GetHighestBidUser.module.scss";

const GetHighestBidUser = ({ username }) => {
  const [bidderID, setBidderID] = useState(null);

  useEffect(() => {
    apiCall(`/rest/user/username/${username}`)
      .then((res) => res.json())
      .then((json) => setBidderID(json[0].user_id));
  }, []);

  return username ? (
    <div className={styles["highest-bidder"]}>
      <Link to={`/users/${bidderID}`}>{username}</Link>
    </div>
  ) : (
    <div>No bidder yet</div>
  );
};

GetHighestBidUser.propTypes = {
  username: PropTypes.string.isRequired,
};

export default GetHighestBidUser;
