import React from "react";
import PropTypes from "prop-types";

import styles from "./UsersTable.module.scss";
import DataTable from "@/components/common/DataTable/index.js";

const UsersTable = ({ userRows, columns }) => {
  return <DataTable id="users" rows={userRows} {...{ columns }} />;
};

UsersTable.propTypes = {
  userRows: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
};

export default UsersTable;
