import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "@/components/auctions/QuestionAndAnswer/QuestionAndAnswer.module.scss";
import Editor from "@/components/common/Editor";
import Label from "@/components/common/Label";
import DeclineOffer from "@/components/auctions/QuestionAndAnswer/DeclineOffer.jsx";

const EntryBox = ({ question, auction }) => {
  const answerEditorId = `${question.questionId}-answer`;

  if (question.entryType === "bid") {
    return (
      <div>
        <div className={styles["question-bid"]}>
          ${question.amount.toLocaleString()}
        </div>
      </div>
    );
  }

  if (question.entryType === "offer") {
    return (
      <div>
        <div className={styles["question"]}>
          <div className={styles["question-bid"]}>
            ${question.amount.toLocaleString()}
          </div>
          {question.message && <p>{question.message}</p>}
        </div>
        {question.bidType !== 1 ? (
          <div className={styles["answer-box"]}>
            <div className={styles["offer"]}>
              <div className={styles["answer-username"]}>
                <Link to={`/users/${auction.userId}`}>{auction.username}</Link>
              </div>
              <div className={styles["offer-label"]}>
                <Label
                  variant={question.bidType === 2 ? "primary" : "danger"}
                  size="md"
                >
                  {question.bidType === 2 ? "accepted offer" : "declined offer"}
                </Label>
              </div>
            </div>
            <p>{question.seller_message}</p>
          </div>
        ) : (
          <DeclineOffer {...{ question }} />
        )}
      </div>
    );
  }

  return (
    <div>
      <div className={styles["question"]}>{question.question_text}</div>
      {question.answer_text &&
        (question.adminname ? (
          <div className={styles["answer-box"]}>
            <div className={styles["answer"]}>{question.adminname}</div>
            <Editor
              id={answerEditorId}
              readOnly
              initialValue={question.answer_text}
            />
          </div>
        ) : (
          <div className={styles["answer-box"]}>
            <div className={styles["answer-username"]}>
              <Link to={`/users/${question.sellerId}`}>
                {question.sellername}
              </Link>
            </div>
            <Editor
              id={answerEditorId}
              readOnly
              initialValue={question.answer_text}
            />
          </div>
        ))}
    </div>
  );
};

EntryBox.propTypes = {
  question: PropTypes.object.isRequired,
};

export default EntryBox;
