import React, { useEffect, useState } from "react";
import { apiCall } from "@boatlist/common/lib";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import TextField from "@/components/common/TextField";
import Button from "@/components/common/Button";
import TextEditor from "@/components/common/TextEditor";
import BoatBrandCard from "./BoatBrandCard";
import BoatSpecs from "./BoatSpecs";
import ContentCard from "../../components/forms/MarketListingForm/ContentCard";
import ListingSpecs from "./ListingSpecs";
import ListingTypeCard from "./ListingTypeCard";
import NewBoatFormButtons from "./NewBoatFormButtons";
import {
  publishListing,
  publishListingPhotos,
} from "../../lib/services/MarketListingApiCalls";

import styles from "./AuctionsPage.module.scss";
import TitleAndDescription from "./TitleAndDescription";
import { selectActiveUser } from "@/store/slices/users";
import { useSelector } from "react-redux";
import {
  createListing,
  updateListingCall,
} from "../../lib/services/NewSubmitABoatCalls";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const getAuctionById = (listingId) => {
  return {
    brand: "4502-Albemarle",
    brandId: "4502",
    brandName: "Albemarle",
    brandOther: undefined,
    buyNow: 0,
    city: "boca",
    listingLength: 30,
    model: "8392-27 Dual Console",
    modelId: "8392",
    modelName: "27 Dual Console",
    modelOther: undefined,
    price: 20,
    schema: 1,
    state: "FL",
    year: "2001",
    listingStatus: 2,
  };
};

const NewBoatSubmit = ({ auction }) => {
  const [listingSchema, setListingSchema] = useState(null);
  const [listingDescriptions, setListingDescriptions] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
    trigger,
    getValues,
    clearErrors,
    unregister,
    reset,
    ...rest
  } = useForm({ mode: "onChange" });
  const { errors } = formState;
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [checkedObject, setCheckedObject] = useState({});
  const [showStep, setShowStep] = useState(5);
  const [selfServeOrPremiumFlag, setSelfServeOrPremiumFlag] = useState(null);
  const [buttonFlag, setButtonFlag] = useState(null);
  const [listingOG, setListingOG] = useState([]);
  const user = useSelector(selectActiveUser);
  const [updateListing, setUpdateListing] = useState(false);

  const query = useQuery();
  const listingId = query.get("id");

  useEffect(() => {
    if (auction) {
      reset({
        ...auction,
        brand: `${auction.brandId}-${auction.brandName}`,
        model: `${auction.modelId}-${auction.modelName}`,
      });
      setListingOG(auction);
      handleChoseFormType(true);
      setUpdateListing(true);
    }
  }, [auction]);

  useEffect(() => {
    apiCall(`/rest/brands/`)
      .then((res) => res.json())
      .then((json) => setBrands(json));
    apiCall(`/rest/models/`)
      .then((res) => res.json())
      .then((json) => setModels(json));
  }, []);

  const getBrand = watch("brand");
  const getModel = watch("model");

  const handleChoseFormType = (isPremium) => {
    apiCall(`/rest/listingschema/${isPremium}`) // true for premium description
      .then((res) => res.json())
      .then((json) => {
        setListingSchema(json.attributes);
        setListingDescriptions(json.descriptions);
      });
  };

  const handleFormAction = (formAction, id, listingObject) => {
    const nowDate = new Date().toISOString();
    const futureDate = listingObject.startDate > nowDate;
    let publishPhotos = true;
    const photoNames = listingId && listingOG.photos.map((photo) => photo.name);

    switch (formAction) {
      case "save":
        window.location.href = `/auctions`;
        break;
      case "preview":
        window.location.href = `/auctions/${id}`;
        break;
      case "publish":
        window.location.href = `/auctions/${id}`;
        break;
      // FIX UPDATE
      case "update":
        // if (
        //   JSON.stringify(listingOG.photos) !==
        //     JSON.stringify(listingObject.photos) &&
        //   listingOG.listingStatus === 2
        // ) {
        //   publishListingPhotos(id).then((res) => {
        //     if (res.ok) {
        //       window.location.href = `/auctions/${id}`;
        //     }
        //   });
        // }
        if (listingOG.listingStatus === 2) {
          window.location.href = `/auctions/${id}`;
        } else {
          window.location.href = `/auctions/${id}`;
        }
        break;
      case "relist":
        if (
          JSON.stringify(photoNames) === JSON.stringify(listingObject.photos)
        ) {
          publishPhotos = false;
        }
        publishListing(id, null, publishPhotos).then((res) => {
          if (res.ok) {
            window.location.href = `/auctions/${id}`;
          }
        });
        break;
      default:
        window.location.href = `/profile#my-auctions`;
        break;
    }
  };

  const handleUploadProgress = ({ done }) => {
    if (done) {
      // setShowForm(false);
    }
  };

  const handleSubmitCallback = (data, formAction) => {
    const { brandOther, brand, modelOther, model } = data;
    const useBrandOther = brandOther !== "" && brand === "other";
    const useModelOther =
      modelOther !== "" && (model === "other" || useBrandOther);

    const photoFiles = data.photos;

    const listingObject = {
      ...data,
      brandId: useBrandOther ? undefined : data.brand.split("-")[0],
      brandName: useBrandOther ? undefined : data.brand.split("-")[1],
      brandOther: useBrandOther ? brandOther : undefined,
      modelId: useModelOther ? undefined : data.model.split("-")[0],
      modelName: useModelOther ? undefined : data.model.split("-")[1],
      modelOther: useModelOther ? modelOther : undefined,
      title: `${data.year} ${
        useBrandOther ? brandOther : data.brand.split("-")[1]
      } ${useModelOther ? modelOther : data.model.split("-")[1]}`,
      username: user.username,
      userId: user.id,
      schema: selfServeOrPremiumFlag, // 2 is premium
    };

    if (data.startDate) {
      listingObject.startDate = new Date(
        `${data.startDate}T${data.startTime}`
      ).toISOString();
    } else {
      listingObject.dateTime = new Date().toISOString();
    }

    console.log({ listingObject });

    createListing(listingObject, handleUploadProgress, photoFiles).then((id) =>
      handleFormAction(formAction, id, listingObject)
    );
  };

  const handleUpdateCallback = (data, formAction) => {
    const { brandOther, brand, modelOther, model } = data;
    const useBrandOther = brandOther !== "" && brand === "other";
    const useModelOther =
      modelOther !== "" && (model === "other" || useBrandOther);

    const listingObject = {
      ...data,
      listingId: undefined,
      brandId: useBrandOther ? undefined : data.brand.split("-")[0],
      brandName: useBrandOther ? undefined : data.brand.split("-")[1],
      brandOther: useBrandOther ? brandOther : undefined,
      modelId: useModelOther ? undefined : data.model.split("-")[0],
      modelName: useModelOther ? undefined : data.model.split("-")[1],
      modelOther: useModelOther ? modelOther : undefined,
      schema: selfServeOrPremiumFlag, // 2 is premium
    };
    console.log(data);

    updateListingCall(listingObject, handleUploadProgress, data.listingId);

    // handleFormAction(formAction, id, newObject);
  };

  const onSubmit = (data) => {
    if (updateListing) {
      switch (buttonFlag) {
        case "update":
          handleUpdateCallback(data, buttonFlag);
          break;
        case "relist":
          handleUpdateCallback(data, buttonFlag);
          break;
        case "publish":
          handleUpdateCallback(data, buttonFlag);
          break;
        case "save":
          handleUpdateCallback(data, buttonFlag);
          break;
        default:
          break;
      }
    } else {
      switch (buttonFlag) {
        case "preview":
          handleSubmitCallback(data, buttonFlag);
          break;
        case "publish":
          handleSubmitCallback(data, buttonFlag);
          break;
        case "save":
          handleSubmitCallback(data, buttonFlag);
          break;
        default:
          break;
      }
    }
  };

  const handleNext = () => {
    setShowStep(showStep + 1);
  };

  return !listingDescriptions && !listingSchema ? (
    <div>
      <h3>What service would you like?</h3>
      <Button variant="primary" onClick={() => handleChoseFormType(false)}>
        Self-Serve
      </Button>
      <Button variant="primary" onClick={() => handleChoseFormType(true)}>
        Premium
      </Button>
    </div>
  ) : (
    <FormProvider
      {...{
        register,
        handleSubmit,
        watch,
        setValue,
        formState,
        trigger,
        getValues,
        clearErrors,
        unregister,
        reset,
      }}
      {...rest}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {updateListing && (
          <div className={styles["update-title"]}>Update Listing</div>
        )}
        <div className={styles["margin"]}>
          <BoatBrandCard
            {...{
              register,
              getBrand,
              brands,
              models,
              getModel,
              errors,
              showStep,
              setShowStep,
              handleNext,
              trigger,
              getValues,
              clearErrors,
              setValue,
              updateListing,
            }}
          />
        </div>
        <div className={styles["margin"]}>
          {showStep >= 1 && (
            <BoatSpecs
              {...{
                listingSchema,
                register,
                errors,
                showStep,
                setShowStep,
                handleNext,
                trigger,
                getValues,
                clearErrors,
                watch,
                updateListing,
              }}
            />
          )}
        </div>
        <div className={styles["margin"]}>
          {showStep >= 2 && (
            <ListingTypeCard
              {...{
                watch,
                register,
                setValue,
                errors,
                showStep,
                setShowStep,
                handleNext,
                trigger,
                getValues,
                clearErrors,
                updateListing,
              }}
            />
          )}
        </div>
        <div className={styles["margin"]}>
          {showStep >= 3 && (
            <ListingSpecs
              {...{
                checkedObject,
                setCheckedObject,
                watch,
                register,
                setValue,
                errors,
                showStep,
                setShowStep,
                handleNext,
                trigger,
                getValues,
                clearErrors,
                unregister,
                updateListing,
              }}
            />
          )}
        </div>
        <div className={styles["margin"]}>
          {showStep >= 4 && (
            <TitleAndDescription
              descriptions={listingDescriptions}
              {...{
                errors,
                register,
                setValue,
                getValues,
                watch,
                updateListing,
              }}
            />
          )}
        </div>
        {/* <TextEditor label="Photos" selector="textarea" /> */}
        <div className={styles["margin"]}>
          {showStep >= 4 && (
            <ContentCard
              {...{
                register,
                setValue,
                watch,
                errors,
                trigger,
                getValues,
                clearErrors,
                updateListing,
              }}
            />
          )}
        </div>
        <NewBoatFormButtons
          {...{ getValues, setButtonFlag, watch, updateListing }}
        />
      </form>
    </FormProvider>
  );
};

export default NewBoatSubmit;
