import React, { useState } from "react";
import PropTypes from "prop-types";
import { apiCall } from "@boatlist/common/lib";
import styles from "@/components/forms/PostDealForm/PostDealForm.module.scss";
import TextField from "@/components/common/TextField/index.js";
import Dialog from "@/components/common/Dialog/index.js";
import { useForm } from "react-hook-form";
import AuctionOfferForm from "../../forms/AuctionOfferForm/AuctionOfferForm";
import FormDialog from "@/components/common/FormDialog";

const handleErrors = (offer) => {
  const newErrors = {};
  if (!offer.username) {
    newErrors.username = "This field is required.";
  }
  if (!offer.userId) {
    newErrors.userId = "This field is required.";
  }

  if (!offer.amount) {
    newErrors.amount = "This field is required.";
  }
  return newErrors;
};

const OfferModal = ({ auctionId, setOfferModal }) => {
  const [offerObject, setOfferObject] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const handleInput = (name, value) => {
    setOfferObject({ ...offerObject, [name]: value });
  };

  const onSubmit = (data) => {
    data.listingId = auctionId;

    apiCall(`/rest/offer`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.ok) {
        console.log("success");
      }
    });
  };

  return (
    <FormDialog
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      onCancel={() => setOfferModal(false)}
      acceptText="Make Offer"
    >
      {/* <div className={styles["title"]}>Make an Offer</div>
      <div className={styles["description"]}>
        Please add the username of the buyer, user ID, the amount agreed upon,
        and an optional message.
      </div>
      <div className={styles["deal-box"]}>
        <TextField
          title="Username"
          onChange={(e) => handleInput("username", e.target.value)}
          error={error.username}
        />
        <TextField
          title="User ID"
          onChange={(e) => handleInput("userId", e.target.value)}
          error={error.userId}
        />
        <TextField
          title="Amount"
          onChange={(e) => handleInput("amount", Number(e.target.value))}
          error={error.amount}
        />
        <TextField
          title="Message"
          onChange={(e) => handleInput("message", e.target.value)}
        />
      </div> */}
      <AuctionOfferForm {...{ register, errors }} />
    </FormDialog>
  );
};

OfferModal.propTypes = {
  auctionId: PropTypes.number.isRequired,
  setOfferModal: PropTypes.func.isRequired,
};

export default OfferModal;
