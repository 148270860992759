import React, { useEffect, useState } from "react";
import TextField from "@/components/common/TextField";
import styles from "./AuctionReserveForm.module.scss";
import Card from "@/components/common/Card";
import { apiCall } from "@boatlist/common/lib";
import Button from "@/components/common/Button";
import Dialog from "@/components/common/Dialog/index.js";
import { useForm } from "react-hook-form";
import FormDialog from "@/components/common/FormDialog";

const AuctionReserveForm = ({ auction }) => {
  const [updateModal, setUpdateModal] = useState(false);
  const { register, watch, handleSubmit, setValue } = useForm({});

  useEffect(() => {
    apiCall(`/rest/listing/reserve/${auction.listingId}`).then((res) =>
      res.json().then((json) => setValue("reserve", json.reserve))
    );
  }, []);

  const reserve = watch("reserve");

  const onSubmit = (data) => {
    const reserveObject = {
      listingId: auction.listingId,
      reserve: Number(data.reserve),
    };

    apiCall(`/rest/listingreserve`, {
      method: "PUT",
      body: JSON.stringify(reserveObject),
    }).then((res) => {
      if (res.ok) {
        console.log("success");
      }
    });
    setUpdateModal(false);
  };

  return (
    <div className={styles["form-box"]}>
      <Card color="blue">
        <div className={styles["title"]}>Auction Reserve Update</div>
        <div className={styles["inside-box"]}>
          <TextField
            title="Auction Reserve"
            // value={reserve}
            // onChange={(e) => handleReserve(Number(e.target.value))}
            name="reserve"
            register={register}
          />
        </div>
        <div className={styles["button-right"]}>
          <Button variant="primary" onClick={() => setUpdateModal(true)}>
            Update
          </Button>
        </div>
      </Card>
      {updateModal && (
        <FormDialog
          onCancel={() => setUpdateModal(false)}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          variant="primary"
          acceptText="Update"
          description={`Are you sure you want to update the reserve to $${Number(
            reserve
          ).toLocaleString()}?`}
        />
      )}
    </div>
  );
};

export default AuctionReserveForm;
