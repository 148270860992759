import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "@/components/common/Button/Button.module.scss";

const Button = ({
  variant,
  children,
  onClick,
  link,
  active,
  outline,
  margin,
  disabled,
  type,
}) => {
  const buttonClasses = classnames(
    styles["button"],
    styles[`button__${variant}`],
    link && styles[`button__${variant}--link`],
    outline && styles[`button__${variant}--outline`],
    active && styles[`button__${variant}--active`],
    margin && styles[`button__margin`],
    disabled && styles[`button__${variant}--disabled`]
  );
  return (
    <button className={buttonClasses} type={type} onClick={onClick}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  active: false,
  variant: "primary",
  type: "button",
};

Button.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "gray",
    "header",
    "transparent",
  ]),
  onClick: PropTypes.func,
  active: PropTypes.bool,
  type: PropTypes.string,
};

export default Button;
