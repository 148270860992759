import React from "react";
import classnames from "classnames";
import PropTypes, { oneOf } from "prop-types";

import styles from "./Label.module.scss";

const Label = ({ variant, children, size }) => {
  const labelClasses = classnames(
    styles["label"],
    styles[`label__${variant}`],
    styles[`label__size-${size}`]
  );
  return <div className={labelClasses}>{children}</div>;
};

Label.defaultProps = {
  size: "sm",
};

Label.propTypes = {
  variant: oneOf(["danger", "success", "primary", "secondary"]).isRequired,
  children: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default Label;
