import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "@/pages/SingleAuctionRequestPage/SingleAuctionRequestPage.module.scss";

const OwnersDetail = ({ request }) => {
  return (
    <div className={styles["owner"]}>
      <div className={styles["header"]}>Owner Details</div>
      <div className={styles["color-box"]}>
        <div className={styles["row-header"]}>
          <div className={styles["title"]}>User</div>
          <div className={styles["title"]}>Email</div>
        </div>
        <div className={styles["row-holder"]}>
          {request.username ? (
            <div className={styles["username"]}>
              <Link to={`/users/${request.userId}`}>{request.username}</Link>
            </div>
          ) : (
            <div className={styles["content"]}>N/A</div>
          )}
          <div className={styles["content"]}>{request.email}</div>
        </div>
        <div className={styles["row-header"]}>
          <div className={styles["title"]}>Ownership Length</div>
          <div className={styles["title"]}>Title on Hand?</div>
        </div>
        <div className={styles["row-holder"]}>
          <div className={styles["content"]}>{request.ownershipLength}</div>
          <div className={styles["content"]}>{request.titleOnHand}</div>
        </div>
        <div className={styles["row-header"]}>
          <div className={styles["title"]}>Broker</div>
          <div className={styles["title"]}>Photo Package Purchased?</div>
        </div>
        <div className={styles["row-holder"]}>
          <div className={styles["content"]}>
            {request.photopackage ? "Yes" : "No"}
          </div>
          <div className={styles["content"]}>{request.broker}</div>
        </div>
      </div>
    </div>
  );
};

OwnersDetail.propTypes = {
  request: PropTypes.object.isRequired,
};

export default OwnersDetail;
