import { useEffect, useState } from "react";
import RequestSmallAuctionCard from "@/components/requests/RequestSmallAuctionCard";

const RequestSmallAuctionCards = ({ requests, filter }) => {
  const [filteredListings, setFilteredListings] = useState(null);

  useEffect(() => {
    if (requests) {
      if (
        filter !== null &&
        typeof filter !== "undefined" &&
        filter.length !== 0
      ) {
        setFilteredListings(
          requests.filter((auction) =>
            filter.includes(Number(auction.listingStatus))
          )
        );
      } else {
        setFilteredListings(requests);
      }
    }
  }, [requests, filter]);

  return (
    filteredListings &&
    filteredListings.map((request) => (
      <RequestSmallAuctionCard {...{ request }} />
    ))
  );
};

export default RequestSmallAuctionCards;
