import Select from "@/components/common/Select";
import TextField from "@/components/common/TextField";
import React, { useState } from "react";

import styles from "./MarketListingForm.module.scss";

const MarketFieldList = ({
  fieldValue,
  errors,
  categoryAttributes,
  register,
}) => {
  const handleField = (item) => {
    if (item.type === "string") {
      return (
        <TextField
          title={item.label}
          // value={categoryAttributes[item.name]}
          type="text"
          register={register}
          name={`categoryAttributes.${item.name}`}
          required={item.mandatory && "This field is required."}
          error={errors.categoryAttributes?.[item.name]?.message}
        />
      );
    }
    if (item.type === "number") {
      return (
        <TextField
          title={item.label}
          // value={categoryAttributes[item.name]}
          type="text"
          register={register}
          name={`categoryAttributes.${item.name}`}
          required={item.mandatory && "This field is required."}
          error={errors.categoryAttributes?.[item.name]?.message}
        />
      );
    }
    if (item.type === "list") {
      return (
        <div className={styles["select"]}>
          <div className={styles["select-title"]}>Select a {item.label}</div>
          <Select
            // value={categoryAttributes[item.name]}
            register={register}
            name={`categoryAttributes.${item.name}`}
            required={item.mandatory && "This field is required."}
            error={errors.categoryAttributes?.[item.name]?.message}
          >
            <option value="" />
            {item.values.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </Select>
        </div>
      );
    }
  };

  const handleFilter = (value) => {
    if (value.name === categoryAttributes[fieldValue.name]) {
      return (
        value.attributes && value.attributes.map((list) => handleField(list))
      );
    }
  };

  return (
    <>
      <div className={styles["select"]}>
        <div className={styles["select-title"]}>
          Select a {fieldValue.label}
        </div>
        <Select
          // value={categoryAttributes && categoryAttributes[fieldValue.name]}
          register={register}
          name={`categoryAttributes.${fieldValue.name}`}
          required={fieldValue.mandatory && "This field is required."}
          error={errors.categoryAttributes?.[fieldValue.name]?.message}
          // error={errors[fieldValue.name]?.message}
        >
          <option value="" />
          {fieldValue.values.map((value) => (
            <option value={value.name}>{value.name}</option>
          ))}
        </Select>
      </div>
      {categoryAttributes &&
        categoryAttributes[fieldValue.name] &&
        fieldValue.values.map((value) => handleFilter(value))}
    </>
  );
};

export default MarketFieldList;
