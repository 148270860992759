import React from "react";

import { Dropdown } from "@boatlist/common/components";
import { BiDotsHorizontalRounded } from "react-icons/all";

import PropTypes from "prop-types";
import IconButton from "@/components/common/IconButton";

import styles from "./SingleMarketListingPage.module.scss";
import { getConfig } from "@boatlist/common/lib";

const ViewPopperTrigger = React.forwardRef((props, ref) => {
  return (
    <button
      type="button"
      className={styles["actionsPopper__trigger"]}
      ref={ref}
      {...props}
    >
      <IconButton variant="secondary" noPadding size="md">
        View
      </IconButton>
    </button>
  );
});

const ViewPopper = ({ listing }) => {
  const viewActions = [
    {
      title: "View Preview",
      link: `${getConfig().base_content_url}/marketplace/preview/?id=${
        listing.listingId
      }`,
    },
  ];
  if (listing.listingStatus === 2) {
    viewActions.push({
      title: "View Published",
      link: `${getConfig().base_content_url}/marketplace/${listing.listingId}`,
    });
  }
  return (
    <div className={styles["actionsPopper"]}>
      <Dropdown
        Trigger={ViewPopperTrigger}
        contentClassName={styles["actionsPopper"]}
        triggerClasses={styles[""]}
      >
        <div className={styles["actionsPopper__content"]}>
          {viewActions.map((action) => (
            <a
              className={styles["actionsPopper__item"]}
              target="_blank"
              rel="noreferrer"
              href={action.link}
            >
              {action.title}
            </a>
          ))}
        </div>
      </Dropdown>
    </div>
  );
};

ViewPopper.propTypes = {
  actions: PropTypes.array.isRequired,
  rows: PropTypes.array,
};

export default ViewPopper;
