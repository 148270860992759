import PropTypes from "prop-types";
import { apiCall } from "@boatlist/common/lib";
import { useState, useEffect } from "react";

const GetReservePrice = ({ id }) => {
  const [reserve, setReserve] = useState({});

  useEffect(() => {
    apiCall(`/rest/listing/reserve/${id}`)
      .then((res) => res.json())
      .then((json) => Number(setReserve(json)));
  }, []);
  if (reserve.reserve) {
    return `$${reserve.reserve.toLocaleString()}`;
  }
  return `$${reserve.reserve}`;
};

GetReservePrice.propTypes = {
  id: PropTypes.number.isRequired,
};

export default GetReservePrice;
