import React, { useEffect, useState } from "react";
import { apiCall, getConfig } from "@boatlist/common/lib";
import Label from "@/components/common/Label";
import MarketTable from "@/components/market/MarketTable";
import { CONSTANTS } from "@/lib/core/index.js";
import Card from "@/components/common/Card/index.js";

const MarketplaceTab = ({ userId }) => {
  const [marketListings, setMarketListings] = useState(null);
  const [marketRows, setMarketRows] = useState(null);
  useEffect(() => {
    apiCall(`/rest/marketplacelistings/${userId}`)
      .then((res) => res.json())
      .then((json) => setMarketListings(json));
  }, []);

  const columns = [
    {
      id: "listingId",
      name: "listingId",
      className: "listingId",
      title: "ID",
      value: "listingId",
      isSortable: true,
      width: "10%",
    },
    {
      id: "title",
      name: "title",
      title: "Title",
      className: "title",
      type: "link",
      isSortable: true,
      value: "listingId",
      width: "100%",
    },
    {
      id: "listingStatus",
      name: "listingStatus",
      title: "Listing Status",
      value: "listingStatus",
      className: "status",
      width: "200px",
    },
    {
      id: "actions",
      name: "actions",
      title: "Actions",
      className: "actions",
      type: "actions",
      width: "15%",
    },
  ];

  const getMarketRows = (listings, actions) => {
    const handleStatus = (status) => {
      switch (status) {
        case CONSTANTS.MARKET_STATUS.NEW:
          return (
            <Label variant="secondary" size="md">
              New
            </Label>
          );
        case CONSTANTS.MARKET_STATUS.PUBLISHED:
          return (
            <Label variant="primary" size="md">
              Published
            </Label>
          );
        case CONSTANTS.MARKET_STATUS.ACTIVE:
          return (
            <Label variant="success" size="md">
              Active
            </Label>
          );
        case CONSTANTS.MARKET_STATUS.ENDED:
          return (
            <Label variant="danger" size="md">
              Ended
            </Label>
          );
        default:
          return (
            <Label variant="danger" size="md">
              Cancelled
            </Label>
          );
      }
    };

    return listings.map((listing) => ({
      id: listing.listingId,
      listingId: {
        sortValue: listing.listingId,
        value: listing.listingId,
      },
      title: {
        sortValue: listing.title,
        value: listing.title,
        link: `/marketplace/${listing.listingId}`,
      },
      listingStatus: {
        value: handleStatus(listing.listingStatus),
        filterValue: Number(listing.listingStatus),
      },
      actions,
    }));
  };

  useEffect(() => {
    if (marketListings) {
      const actions = [
        {
          name: "view",
          title: "Preview",
          onClick: (listingId) => {
            window.open(
              `${
                getConfig().base_content_url
              }/marketplace/preview/?id=${listingId}`,
              "_blank"
            );
          },
        },
      ];
      setMarketRows(getMarketRows(marketListings, actions));
    }
  }, [marketListings]);

  return (
    <>
      <h2>Marketplace</h2>
      <Card>
        <MarketTable marketRows={marketRows} {...{ columns }} />
      </Card>
    </>
  );
};

export default MarketplaceTab;
