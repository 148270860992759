import React, { useState } from "react";
import Select from "@/components/common/Select/index.js";
import styles from "./MarketBar.module.scss";
import Button from "@/components/common/Button/index.js";
import MarketBarFilter from "@/components/market/MarketBar/MarketBarFilter.jsx";
import useLocalStorage from "@/hooks/useLocalStorage.js";

const MarketBar = ({ setFilter, filter }) => {
  const [localCategory, setLocalCategory] = useLocalStorage(
    "localCategory",
    ""
  );
  const [localSubcategory, setLocalSubcategory] = useLocalStorage(
    "localSubcategory",
    []
  );

  const handleClear = () => {
    setFilter({});
    setLocalCategory("");
    setLocalSubcategory([]);
  };

  const handleActive = (value, name) => {
    if (filter[name]) {
      const isFollowed = filter[name].includes(value);
      if (isFollowed) {
        const newArray = filter[name].filter((id) => {
          return id !== value;
        });
        setFilter({ ...filter, [name]: newArray });
      } else {
        setFilter({ ...filter, [name]: [...filter[name], value] });
      }
    } else {
      setFilter({ ...filter, [name]: [value] });
    }
  };

  return (
    <div>
      <div>Filter by Category</div>
      <div className={styles["market_bar"]}>
        <MarketBarFilter
          {...{
            setFilter,
            filter,
            handleClear,
            localCategory,
            setLocalCategory,
            localSubcategory,
            setLocalSubcategory,
          }}
        />
      </div>
      <div>Filter by Buy Now</div>
      <div className={styles["button-holder"]}>
        <Button
          variant="gray"
          onClick={() => handleActive(0, "buyNow")}
          active={filter["buyNow"] && filter["buyNow"].includes(0)}
          outline
          margin
        >
          Buy Now
        </Button>
        <Button
          variant="gray"
          onClick={() => handleActive(1, "buyNow")}
          active={filter["buyNow"] && filter["buyNow"].includes(1)}
          outline
          margin
        >
          Buy Now Until Auction
        </Button>
        <Button
          variant="gray"
          onClick={() => handleActive(2, "buyNow")}
          active={filter["buyNow"] && filter["buyNow"].includes(2)}
          outline
          margin
        >
          Auction
        </Button>
      </div>
      <div>Filter by Status</div>
      <div className={styles["button-holder"]}>
        <Button
          variant="gray"
          onClick={() => handleActive(0, "status")}
          active={filter["status"] && filter["status"].includes(0)}
          outline
          margin
        >
          New
        </Button>
        <Button
          variant="gray"
          onClick={() => handleActive(1, "status")}
          active={filter["status"] && filter["status"].includes(1)}
          outline
          margin
        >
          Scheduled
        </Button>
        <Button
          variant="gray"
          onClick={() => handleActive(2, "status")}
          active={filter["status"] && filter["status"].includes(2)}
          outline
          margin
        >
          Active
        </Button>
        <Button
          variant="gray"
          onClick={() => handleActive(3, "status")}
          active={filter["status"] && filter["status"].includes(3)}
          outline
          margin
        >
          Ended
        </Button>
      </div>
      <div className={styles["button-margin"]}>
        <Button onClick={handleClear}>Clear</Button>
      </div>
    </div>
  );
};

export default MarketBar;
