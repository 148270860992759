import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./MarketSmallAuctionCard.module.scss";
import Card from "@/components/common/Card/Card.jsx";
import Label from "@/components/common/Label/index.js";

const MarketSmallAuctionCard = ({ auction }) => {
  const handleDate = (expirationDate, startDate) => {
    if (expirationDate && !startDate) {
      const date = new Date(expirationDate).toString();
      const dateArray = date.split(" ", 5);
      // console.log(date);

      return `${dateArray[1]} ${dateArray[2]}`;
    }
    if (expirationDate && startDate) {
      const start = new Date(startDate).toString();
      const startDateArray = start.split(" ", 5);

      const date = new Date(expirationDate).toString();
      const dateArray = date.split(" ", 5);

      return `${startDateArray[1]} ${startDateArray[2]} - ${dateArray[1]} ${dateArray[2]}`;
    }

    return "No date available";
  };

  return (
    <div className={styles["small_auction"]}>
      <Card style={{ padding: "10px" }}>
        <div className={styles["auction-box"]}>
          <div className={styles["image-container"]}>
            <img
              src={`https://s3.amazonaws.com/boatlist.uploads/${auction.listingId}%2F${auction.picture}`}
              className={styles["image"]}
              alt={auction.title}
            />
          </div>
          <div className={styles["middle-box"]}>
            <div className={styles["auction-title"]}>
              <Link
                to={`/marketplace/${auction.listingId}`}
                className={styles["auction-title__link"]}
              >
                {auction.title}
              </Link>
            </div>
            <div className={styles["date-name"]}>
              {handleDate(auction.expirationDate, auction.dateTime)}
            </div>
            <div className={styles["content"]}>
              {auction.buyNow === 0 ? (
                <div className={styles["reserve-box"]}>
                  <div className={styles["reserve-container"]}>
                    Price: ${Number(auction.price).toLocaleString()}
                  </div>
                </div>
              ) : (
                <div className={styles["reserve-box"]}>
                  <div className={styles["reserve-container"]}>
                    Reserve: ${Number(auction.reserve).toLocaleString()}
                  </div>
                </div>
              )}
              <div className={styles["bid-content"]}>
                <Label variant="secondary" size="md">
                  <div className={styles["bid-info"]}>
                    {auction.highestBidUser || "N/A"}
                  </div>
                </Label>
                :
                <div className={styles["bid-number"]}>
                  {" "}
                  ${auction.highestBid && auction.highestBid.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default MarketSmallAuctionCard;
