import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./QuestionAndAnswer.module.scss";
import EntryBox from "@/components/auctions/QuestionAndAnswer/EntryBox.jsx";

const formatQADate = (timeId) => {
  const date = new Date(timeId).toString();
  const dateArray = date.split(" ", 5);

  let time = dateArray[4];

  time = time.split(":");
  // console.log(time);

  const hours = Number(time[0]);
  const minutes = Number(time[1]);

  let timeValue;

  if (hours > 0 && hours <= 12) {
    timeValue = `${hours}`;
  } else if (hours > 12) {
    timeValue = `${hours - 12}`;
  } else if (hours === 0) {
    timeValue = "12";
  }

  timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
  timeValue += hours >= 12 ? " PM" : " AM";

  return `${dateArray[1]} ${dateArray[2]} at ${timeValue}`;
};

const QuestionAndAnswer = ({ question, auction }) => {
  const getDate = () => {
    if (question.entryType === "bid") {
      return formatQADate(question.bidId);
    }
    if (question.entryType === "offer") {
      return formatQADate(question.bidId);
    }

    return formatQADate(question.questionId);
  };
  return (
    <div>
      <div className={styles["user-box"]}>
        <div className={styles["question-username"]}>
          <Link to={`/users/${question.userId}`}>{question.username}</Link>
        </div>
        <div className={styles["question-date"]}>{getDate()}</div>
      </div>
      <EntryBox {...{ question, auction }} />
      <hr className={styles["border"]} />
    </div>
  );
};

QuestionAndAnswer.propTypes = {
  question: PropTypes.object.isRequired,
};

export default QuestionAndAnswer;
