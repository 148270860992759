import React from "react";
import PropTypes from "prop-types";

import EmailTemplateForm from "./EmailTemplateForm";
import styles from "./EmailsPage.module.scss";
import TextField from "../../components/common/TextField";
import EmailsTable from "./EmailsTable";
import useLocalStorage from "../../hooks/useLocalStorage";

const EmailPageMain = ({
  activeEmailTemplate,
  emailTemplates,
  onSubmit,
  onPublish,
  handleDeleteTemplate,
  handleDuplicateTemplate,
  handleEditTemplate,
}) => {
  const [search, setSearch] = useLocalStorage("emails-search");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  if (activeEmailTemplate) {
    return (
      <EmailTemplateForm {...{ activeEmailTemplate, onSubmit, onPublish }} />
    );
  }

  const filteredEmailTemplates = emailTemplates
    ? emailTemplates.filter(
        (t) => !search || t.templateName.toLowerCase().includes(search)
      )
    : [];

  return (
    <div>
      <div className={styles["email-search"]}>
        <TextField onChange={handleSearch} title="Search" value={search} />
      </div>
      <EmailsTable
        emailTemplates={filteredEmailTemplates}
        {...{
          activeEmailTemplate,
          handleEditTemplate,
          handleDeleteTemplate,
          handleDuplicateTemplate,
        }}
      />
    </div>
  );
};

EmailPageMain.defaultProps = {
  activeEmailTemplate: undefined,
  emailTemplates: [],
  onSubmit: undefined,
};

EmailPageMain.propTypes = {
  activeEmailTemplate: PropTypes.shape(),
  emailTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      templateName: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func,
  onPublish: PropTypes.func.isRequired,
  handleEditTemplate: PropTypes.func.isRequired,
  handleDeleteTemplate: PropTypes.func.isRequired,
  handleDuplicateTemplate: PropTypes.func.isRequired,
};

export default EmailPageMain;
