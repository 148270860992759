import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";

import TextField from "@/components/common/TextField/index.js";
import Button from "@/components/common/Button/index.js";
import Card from "@/components/common/Card/index.js";
import styles from "./AuctionDateTimeForm.module.scss";
import Dialog from "@/components/common/Dialog/index.js";
import { useForm } from "react-hook-form";
import FormDialog from "@/components/common/FormDialog";

const AuctionDateTimeForm = ({ auction, setAuction }) => {
  const [timeObject, setTimeObject] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const handleInput = (value, name) => {
    if (name === "startDate") {
      const startDate = new Date(`${value}T00:00`).toLocaleDateString("en-US");
      console.log(`date: ${startDate}`);
      setTimeObject({
        ...timeObject,
        [name]: startDate,
        listingId: auction.listingId,
      });
    }
    if (name === "startTime") {
      console.log(`date: ${value}`);
      setTimeObject({
        ...timeObject,
        [name]: value,
        listingId: auction.listingId,
      });
    }
  };

  const timeStamp = new Date(auction.dateTime);

  useEffect(() => {
    const year = timeStamp.getFullYear();
    const month = `0${timeStamp.getMonth() + 1}`.slice(-2);
    const date = `0${timeStamp.getDate()}`.slice(-2);

    const hours = timeStamp.getHours();
    const minutes = timeStamp.getMinutes();
    if (minutes === 0) {
      setTimeObject({
        ...timeObject,
        startTime: `${hours}:${minutes}0`,
        startDate: `${year}-${month}-${date}`,
      });
    } else {
      setTimeObject({
        ...timeObject,
        startTime: `${hours}:${minutes}`,
        startDate: `${year}-${month}-${date}`,
      });
    }
  }, []);

  const onSubmit = (data) => {
    data.listingId = auction.listingId;
    apiCall(`/rest/listingstartdate`, {
      method: "PUT",
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.ok) {
        console.log("success datetime");
        res.json().then((json) => setAuction({ ...auction, ...json }));
        setUpdateModal(false);
      }
    });
  };

  return (
    timeObject && (
      <div className={styles["form-box"]}>
        <Card color="blue">
          <div className={styles["title"]}>Auction Start Time</div>
          <div className={styles["description"]}>
            Updating the start time will update the expiration date.
          </div>
          <div className={styles["inside-box"]}>
            <TextField
              title="Auction Start Date"
              type="date"
              defaultValue={timeObject.startDate}
              // onChange={(e) => handleInput(e.target.value, "startDate")}
              register={register}
              required="This field is required."
              name="startDate"
              error={errors.startDate?.message}
            />
            <TextField
              title="Auction Start Time"
              type="time"
              defaultValue={timeObject.startTime}
              // onChange={(e) => handleInput(e.target.value, "startTime")}
              register={register}
              required="This field is required."
              name="startTime"
              error={errors.startTime?.message}
            />
          </div>
          <div className={styles["button-right"]}>
            <Button variant="primary" onClick={() => setUpdateModal(true)}>
              Update
            </Button>
          </div>
        </Card>
        {updateModal && (
          <FormDialog
            onCancel={() => setUpdateModal(false)}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            variant="primary"
            acceptText="Update"
            description="Are you sure you want to update this auction?"
          />
        )}
      </div>
    )
  );
};

AuctionDateTimeForm.propTypes = {
  auction: PropTypes.object.isRequired,
  setAuction: PropTypes.func.isRequired,
};

export default AuctionDateTimeForm;
