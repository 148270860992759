import React from "react";

import PropTypes from "prop-types";
import TextField from "@/components/common/TextField/index.js";

import styles from "./PostDealForm.module.scss";

const PostDealForm = ({ error, handleInput }) => {
  return (
    <div>
      <div className={styles["title"]}>Post Auction Deal</div>
      <div className={styles["description"]}>
        Please add the username of the buyer, the amount agreed upon, and the
        commission percentage.
      </div>
      <div className={styles["deal-box"]}>
        <TextField
          title="Buyer"
          onChange={(e) => handleInput("username", e.target.value)}
          error={error.username}
        />
        <TextField
          title="Amount"
          onChange={(e) => handleInput("amount", Number(e.target.value))}
          error={error.amount}
        />
        <TextField
          title="Commission"
          onChange={(e) => handleInput("commission", e.target.value)}
          error={error.commission}
        />
      </div>
    </div>
  );
};

PostDealForm.propTypes = {
  error: PropTypes.string.isRequired,
  handleInput: PropTypes.func.isRequired,
};

export default PostDealForm;
