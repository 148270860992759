import React, { useState } from "react";
import { apiCall } from "@boatlist/common/lib";

import PropTypes from "prop-types";

import Card from "@/components/common/Card/index.js";
import PostDealForm from "@/components/forms/PostDealForm/index.js";
import Button from "@/components/common/Button/index.js";
import Dialog from "@/components/common/Dialog/index.js";
import styles from "@/pages/SingleAuctionPage/SingleAuctionPage.module.scss";

const handleErrors = (dealObject) => {
  const newErrors = {};
  if (!dealObject.username) {
    newErrors.username = "This field is required.";
  }
  if (!dealObject.commission) {
    newErrors.commission = "This field is required.";
  }

  if (!dealObject.amount) {
    newErrors.amount = "This field is required.";
  }
  return newErrors;
};

const AuctionManageTab = ({ auctionId, auction }) => {
  const [error, setError] = useState({});
  const [dealModal, setDealModal] = useState(false);
  const [relistModal, setRelistModal] = useState(false);
  const [deal, setDeal] = useState({ listingId: auctionId });
  const handleInput = (name, value) => {
    setError({});
    setDeal({ ...deal, [name]: value });
  };

  const handlePutDeal = (dealObject) => {
    const newChatError = handleErrors(dealObject);

    if (Object.keys(newChatError).length) {
      setError(newChatError);
      setDealModal(false);
      return;
    }
    setError({});
    apiCall("/rest/bids/deal", {
      method: "PUT",
      body: JSON.stringify(dealObject),
    }).then((res) => {
      if (res.ok) {
        setDealModal(false);
      }
    });
  };

  const handlePutRelist = () => {
    apiCall(`/rest/relist/${auctionId}`, {
      method: "POST",
    }).then((res) => {
      if (res.ok) {
        setRelistModal(false);
      }
    });
  };
  return (
    <>
      <div className={styles["deal-box"]}>
        <Card color="blue">
          <div className={styles["mobile-box"]}>
            <PostDealForm modal {...{ error, handleInput }} />
            <div className={styles["button-right"]}>
              <Button variant="primary" onClick={() => setDealModal(true)}>
                Deal
              </Button>
            </div>
          </div>
        </Card>
      </div>
      {auction.listingStatus === 3 && (
        <div className={styles["deal-box"]}>
          <Card color="blue">
            <div className={styles["mobile-box"]}>
              <div className={styles["relist_title"]}>Relist Auction</div>
              <div className={styles["description"]}>
                Relisting will create a new listing request and can be viewed in
                Pipedrive.
              </div>
              <div className={styles["button-right"]}>
                <Button variant="primary" onClick={() => setRelistModal(true)}>
                  Relist
                </Button>
              </div>
            </div>
          </Card>
        </div>
      )}
      {dealModal && (
        <Dialog
          onCancel={() => setDealModal(false)}
          onAccept={() => handlePutDeal(deal)}
          variant="primary"
          acceptText="Deal"
          description="Are you sure you want to process this deal?"
        />
      )}
      {relistModal && (
        <Dialog
          description="Are you sure you want to relist this auction?"
          onCancel={() => setRelistModal(false)}
          onAccept={handlePutRelist}
          acceptText="Relist"
        />
      )}
    </>
  );
};

AuctionManageTab.propTypes = {
  auctionId: PropTypes.number.isRequired,
};

export default AuctionManageTab;
