import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";

import Select from "@/components/common/Select";
import TextField from "@/components/common/TextField";
import styles from "@/components/forms/MarketListingForm/MarketListingForm.module.scss";
import Card from "@/components/common/Card/index.js";
import MarketFieldList from "./MarketFieldList";

const MarketListingCategoriesForm = ({
  categories,
  errors,
  setSchema,
  schema,
  updateListing,
  register,
  setValue,
  getValues,
}) => {
  const categoryId = getValues("categoryId");
  const categoryAttributes = getValues("categoryAttributes");

  useEffect(() => {
    register("categoryId", { required: "This field is required." });
  }, [register]);

  useEffect(() => {
    if (categoryId) {
      handleCategory(categoryId);
    }
  }, [categories]);

  const handleCategory = (value) => {
    setSchema(
      categories.filter((category) => {
        if (category.categoryId === value) return category.categorySchema;
      })
    );
    setValue("categoryId", value);
  };

  const handleField = (item) => {
    if (item.type === "string") {
      return (
        <TextField
          title={item.label}
          // value={categoryAttributes && categoryAttributes[item.name]}
          type="text"
          // onChange={(e) => handleCategoryAttributes(e.target.value, item.name)}
          register={register}
          name={`categoryAttributes.${item.name}`}
          required={item.mandatory && "This field is required."}
          error={errors.categoryAttributes?.[item.name]?.message}
        />
      );
    }
    if (item.type === "number") {
      return (
        <TextField
          title={item.label}
          // value={categoryAttributes && categoryAttributes[item.name]}
          type="text"
          // onChange={(e) =>
          //   handleCategoryAttributes(Number(e.target.value), item.name)
          // }
          register={register}
          name={`categoryAttributes.${item.name}`}
          required={item.mandatory && "This field is required."}
          error={errors.categoryAttributes?.[item.name]?.message}
        />
      );
    }
    if (item.type === "list") {
      return (
        <div className={styles["select"]}>
          <div className={styles["select-title"]}>Select a {item.label}</div>
          <Select
            // value={categoryAttributes && categoryAttributes[item.name]}
            // onChange={(e) =>
            //   handleCategoryAttributes(e.target.value, item.name)
            // }
            register={register}
            name={`categoryAttributes.${item.name}`}
            required={item.mandatory && "This field is required."}
            error={errors.categoryAttributes?.[item.name]?.message}
          >
            <option value="" />
            {item.values.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </Select>
        </div>
      );
    }
    if (item.type === "fieldlist") {
      return (
        <MarketFieldList
          fieldValue={item}
          {...{ listing, errors, categoryAttributes, register }}
        />
      );
    }
  };

  return (
    <Card color={updateListing && "blue"}>
      <div className={styles["heading"]}>Category & Attributes</div>
      <div className={styles["select"]}>
        <Select
          value={categoryId}
          label="Select a Category"
          onChange={(e) => handleCategory(Number(e.target.value))}
          error={errors.categoryId?.message}
        >
          <option value="" />
          {categories &&
            categories.map((item) => (
              <option value={item.categoryId}>{item.categoryName}</option>
            ))}
        </Select>
      </div>
      {schema &&
        schema[0].categorySchema.map((schemaItem) => handleField(schemaItem))}
    </Card>
  );
};

MarketListingCategoriesForm.propTypes = {
  schemas: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  handleInput: PropTypes.func.isRequired,
  setSchemas: PropTypes.func.isRequired,
};

export default MarketListingCategoriesForm;
