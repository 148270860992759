import React, { useState } from "react";
import cx from "classnames";
import Button from "@/components/common/Button";
import Modal from "@/components/common/Modal";
import Card from "@/components/common/Card";
import styles from "./AuctionsPage.module.scss";

const ImagePicker = ({ images, handleChange, selectedImageName }) => {
  const [imageModal, setImageModal] = useState(null);

  const handlePhotoPick = (newValue) => {
    setImageModal(false);
    handleChange(newValue);
  };

  return (
    <>
      <div>Insert Images</div>
      <div className={styles["outside-box"]}>
        <Button
          variant="primary"
          type="button"
          margin
          onClick={() => setImageModal(true)}
        >
          Choose image
        </Button>
        {selectedImageName && (
          <div className={styles["photo-show"]}>
            <div className={styles["photo"]}>
              <img
                alt={selectedImageName}
                src={URL.createObjectURL(
                  images.find((image) => image.name === selectedImageName)
                )}
                className={styles["image"]}
              />
              <div>{selectedImageName}</div>
            </div>
          </div>
        )}
      </div>
      {imageModal && (
        <Modal onCancel={() => setImageModal(false)}>
          <Card>
            <div className={styles["modal-title"]}>
              Select one image to add to the description
            </div>
            {images ? (
              <div className={styles["images"]}>
                {images &&
                  images.map((image) => (
                    <div className={styles["image-holder"]}>
                      <button
                        type="button"
                        className={cx(
                          styles["image-button"],
                          selectedImageName === image.name && styles["selected"]
                        )}
                        onClick={() => handlePhotoPick(image)}
                      >
                        <img
                          alt={image.name}
                          src={URL.createObjectURL(image)}
                          className={styles["image"]}
                        />
                        <div className={styles["image-name"]}>{image.name}</div>
                        {selectedImageName === image.name && (
                          <div className={styles["selected-name"]}>
                            Selected
                          </div>
                        )}
                      </button>
                    </div>
                  ))}
              </div>
            ) : (
              <div>Please add images below to select one</div>
            )}
            <div className={styles["button-right"]}>
              <Button
                variant="primary"
                type="button"
                onClick={() => setImageModal(false)}
              >
                Close
              </Button>
            </div>
          </Card>
        </Modal>
      )}
    </>
  );
};

export default ImagePicker;
