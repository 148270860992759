import React, { useEffect, useState } from "react";
import TextField from "@/components/common/TextField";
import Card from "@/components/common/Card";
import Select from "@/components/common/Select";

import styles from "./AuctionsPage.module.scss";

const BoatBrandCard = ({
  register,
  getBrand,
  brands,
  models,
  getModel,
  errors,
  showStep,
  handleNext,
  trigger,
  getValues,
  updateListing,
}) => {
  const [nameArray, setNameArray] = useState(["brand", "model"]);

  const handleNextStep = () => {
    const values = getValues();
    const filteredArray = nameArray.filter((name) => {
      if (values[name].length === 0) {
        return name;
      }
    });

    if (filteredArray.length !== 0) {
      trigger(filteredArray);
    } else {
      handleNext();
    }
  };

  useEffect(() => {
    if (getBrand === "other") {
      setNameArray(["brandOther", "modelOther"]);
    }
    if (getModel === "other") {
      setNameArray([...nameArray, "modelOther"]);
    }
  }, [getBrand, getModel]);

  return (
    <Card color={updateListing && "blue"}>
      <div className={styles["hold_3"]}>
        <div className="input">
          <label>
            <h3>Boat Brand</h3>
            <Select
              name="brand"
              register={register}
              required="This field is required."
              error={errors.brand?.message}
              value={getBrand}
            >
              <option value="">Please Select</option>
              {brands &&
                brands
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((brand) => (
                    <option
                      key={brand.brandId}
                      value={`${brand.brandId}-${brand.name}`}
                    >
                      {brand.name}
                    </option>
                  ))}
              <option value="other">Other</option>
            </Select>
          </label>
        </div>
        <div className="input input--col">
          <label>
            <span className="label_text">Model</span>
            <Select
              value={getBrand === "other" ? "other" : getModel}
              name="model"
              register={register}
              required="This field is required."
              error={errors.model?.message}
            >
              <option value="" selected>
                {getBrand ? "Please Select" : "Select the Brand"}
              </option>
              {models &&
                models
                  .filter(
                    (model) => getBrand && getBrand.includes(model.brandId)
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((model) => (
                    <option
                      key={model.modelId}
                      value={`${model.modelId}-${model.name}`}
                    >
                      {model.name}
                    </option>
                  ))}
              <option value="other">Other</option>
            </Select>
          </label>
        </div>
      </div>
      <div
        className={
          getBrand === "other" ? "hold_2 form_holder" : "hold_1 form_holder"
        }
      >
        {getBrand === "other" && (
          <TextField
            id="brandOther"
            title="Other Brand"
            register={register}
            name="brandOther"
            required="This field is required."
            error={errors.brandOther?.message}
          />
        )}
        {(getModel === "other" || getBrand === "other") && (
          <TextField
            id="modelOther"
            title="Other Model"
            register={register}
            name="modelOther"
            required="This field is required."
            error={errors.modelOther?.message}
          />
        )}
      </div>
      {showStep === 0 && (
        <div className="submit text_right">
          <button onClick={handleNextStep} type="button" className="bt">
            Next: Boat Specs
          </button>
        </div>
      )}
    </Card>
  );
};

export default BoatBrandCard;
