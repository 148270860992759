import React from "react";
import PropTypes from "prop-types";

import AuctionForm from "@/components/forms/AuctionForm";
import AuctionReserveForm from "@/components/forms/AuctionReserveForm";
import AuctionDateTimeForm from "@/components/forms/AuctionDateTimeForm";
import NewBoatSubmit from "../AuctionsPage/NewBoatSubmit";

const AuctionUpdateTab = ({ auction, setAuction }) => {
  const defaultValues = {
    listingStatus: auction.listingStatus,
    featured: auction.featured,
    startingBid: auction.startingBid,
  };

  return (
    <>
      <AuctionForm {...{ auction, defaultValues, setAuction }} />
      <AuctionDateTimeForm {...{ auction, setAuction }} />
      <AuctionReserveForm {...{ auction }} />
      <NewBoatSubmit {...{ auction }} />
    </>
  );
};

AuctionUpdateTab.propTypes = {
  auction: PropTypes.object.isRequired,
  setAuction: PropTypes.func.isRequired,
};

export default AuctionUpdateTab;
