/* eslint-disable no-nested-ternary */
import React from "react";
import Button from "@/components/common/Button";

export const ShowNormalButtons = ({
  setButtonFlag,
  handlePublishOrSchedule,
}) => {
  return (
    <>
      <Button
        variant="primary"
        margin
        type="submit"
        onClick={() => setButtonFlag("publish")}
      >
        {handlePublishOrSchedule()}
      </Button>

      <Button
        variant="primary"
        type="submit"
        margin
        onClick={() => setButtonFlag("preview")}
      >
        Preview
      </Button>
      <Button
        variant="secondary"
        type="submit"
        margin
        onClick={() => setButtonFlag("save")}
      >
        Save for Later
      </Button>
    </>
  );
};

const NewBoatFormButtons = ({
  getValues,
  setButtonFlag,
  updateListing,
  watch,
}) => {
  const listing = getValues();
  const nowTime = new Date();
  const expTimeStamp = new Date(listing.expirationDate).getTime();
  const diffInTime = expTimeStamp - nowTime.getTime();
  const listingStartTimes = watch();

  const handlePublishOrSchedule = () => {
    const startTime = listingStartTimes.startTime
      ? new Date(
          `${listingStartTimes.startDate}T${listingStartTimes.startTime}`
        ).toISOString()
      : 0;

    if (startTime && startTime < nowTime.toISOString()) {
      return "Publish Boat";
    }
    if (startTime && startTime > nowTime.toISOString()) {
      return "Schedule Boat";
    }
    return "Publish Boat";
  };

  return updateListing ? (
    listing.listingStatus !== 0 ? (
      <>
        {listing.listingStatus === 3 || diffInTime < 0 ? (
          <Button
            variant="primary"
            margin
            type="submit"
            onClick={() => setButtonFlag("relist")}
          >
            Relist
          </Button>
        ) : (
          <Button
            margin
            variant="primary"
            type="submit"
            onClick={() => setButtonFlag("update")}
            // disabled
            // disabled={!disableFlag}
          >
            Update
          </Button>
        )}
        <Button
          variant="secondary"
          margin
          // onClick={handleCheckForUpdate}
        >
          Go to Listing
        </Button>
      </>
    ) : (
      <ShowNormalButtons {...{ handlePublishOrSchedule, setButtonFlag }} />
    )
  ) : (
    <ShowNormalButtons {...{ handlePublishOrSchedule, setButtonFlag }} />
  );
  // <button type="submit" className="bt">
  //   Submit Boat
  // </button>
};

export default NewBoatFormButtons;
