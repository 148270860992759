import React, { useState, useEffect } from "react";
import Card from "@/components/common/Card/index.js";
import Button from "@/components/common/Button/index.js";
import Select from "@/components/common/Select/index.js";

import styles from "./SingleMarketListingPage.module.scss";
import DeleteListingModal from "@/components/market/DeleteListingModal/index.js";
import { apiCall } from "@boatlist/common/lib";
import Dialog from "../../components/common/Dialog/index";
import {
  publishListing,
  relistListing,
} from "../../lib/services/MarketListingApiCalls";
import MarketStartTimeForm from "../../components/forms/MarketStartTimeForm";

const MarketManageTab = ({ listing, setListing }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [renewModal, setRenewModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [endListingModal, setEndListingModal] = useState(false);
  const [listingStatus, setListingStatus] = useState(null);

  useEffect(() => {
    setListingStatus(listing.listingStatus);
  }, [listing]);

  const handleDeleteSuccess = () => {
    setDeleteModal(false);
  };

  const handleInput = (value) => {
    setListingStatus(value);
  };

  const handleSubmit = () => {
    apiCall(`/rest/marketplacelisting/${listing.listingId}`, {
      method: "PUT",
      body: JSON.stringify({ listingStatus }),
    }).then((res) => {
      if (res.ok) {
        console.log("success");
      }
    });
  };

  const handleRenewListing = () => {
    relistListing(listing);
    setRenewModal(false);
  };

  const handlePublishListing = () => {
    publishListing(listing.listingId);
  };

  const handleEndListingEarly = () => {
    apiCall(`/rest/marketplacelisting/${listing.listingId}`, {
      method: "PUT",
      body: JSON.stringify({ listingStatus: 4 }),
    }).then((res) => {
      if (res.ok) {
        setEndListingModal(false);
        window.location.reload();
      }
    });
  };

  return (
    <>
      {listing.listingStatus < 2 && (
        <div className={styles["deal-box"]}>
          <Card color="blue">
            <div className={styles["mobile-box"]}>
              <div className={styles["relist_title"]}>Publish Listing</div>
              <div className={styles["description"]}>
                Publishing will publish this listing.
              </div>
              <div className={styles["button-right"]}>
                <Button variant="primary" onClick={() => setPublishModal(true)}>
                  Publish
                </Button>
              </div>
            </div>
          </Card>
          {publishModal && (
            <Dialog
              description="Are you sure you want to publish this listing?"
              onCancel={() => setPublishModal(false)}
              onAccept={handlePublishListing}
              acceptText="Publish"
            />
          )}
        </div>
      )}
      <div className={styles["deal-box"]}>
        <Card color="blue">
          <div className={styles["mobile-box"]}>
            <div className={styles["relist_title"]}>Delete Listing</div>
            <div className={styles["description"]}>
              Deleting this listing will remove all photos as well.
            </div>
            <div className={styles["button-right"]}>
              <Button variant="primary" onClick={() => setDeleteModal(true)}>
                Delete
              </Button>
            </div>
          </div>
        </Card>
        {deleteModal && (
          <DeleteListingModal
            listingId={listing.listingId}
            onCancel={() => setDeleteModal(false)}
            {...{ handleDeleteSuccess }}
          />
        )}
      </div>
      <div className={styles["deal-box"]}>
        <Card color="blue">
          <div className={styles["mobile-box"]}>
            <div className={styles["relist_title"]}>Renew Listing</div>
            <div className={styles["description"]}>
              Renewing will publish this listing again.
            </div>
            <div className={styles["button-right"]}>
              <Button variant="primary" onClick={() => setRenewModal(true)}>
                Renew
              </Button>
            </div>
          </div>
        </Card>
        {renewModal && (
          <Dialog
            description="Are you sure you want to renew this listing?"
            onCancel={() => setRenewModal(false)}
            onAccept={handleRenewListing}
            acceptText="Renew"
          />
        )}
      </div>
      <div className={styles["deal-box"]}>
        <Card color="blue">
          <div className={styles["mobile-box"]}>
            <div className={styles["relist_title"]}>End Listing</div>
            <div className={styles["description"]}>
              Ending this listing will end the listing before the expiration
              date.
            </div>
            <div className={styles["button-right"]}>
              <Button
                variant="primary"
                onClick={() => setEndListingModal(true)}
              >
                End Now
              </Button>
            </div>
          </div>
        </Card>
        {endListingModal && (
          <Dialog
            description="Are you sure you want to end this listing early"
            onCancel={() => setEndListingModal(false)}
            onAccept={handleEndListingEarly}
            acceptText="End Now"
          />
        )}
      </div>
      <div className={styles["deal-box"]}>
        <Card color="blue">
          <div className={styles["mobile-box"]}>
            <div className={styles["relist_title"]}>Update Listing</div>
            <div className={styles["description"]}>
              Update the listing status on this market listing.
            </div>
            <Select
              label="Listing Status"
              value={listingStatus}
              onChange={(e) => handleInput(Number(e.target.value))}
            >
              <option value="" />
              <option value={0}>New</option>
              <option value={1}>Scheduled</option>
              <option value={2}>Active</option>
              <option value={3}>Ended</option>
            </Select>
            <div className={styles["button-right"]}>
              <Button variant="primary" onClick={handleSubmit}>
                Update
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <MarketStartTimeForm {...{ listing, setListing }} />
    </>
  );
};

export default MarketManageTab;
