import React from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";
import Dialog from "@/components/common/Dialog";
import { getUsers } from "@/lib/services/index.js";

const BlockUserModal = ({
  onCancel,
  name,
  action,
  userId,
  handleBlockSuccess,
}) => {
  const handleBlock = () => {
    apiCall(`/rest/user/${action}/${userId}`, {
      method: "POST",
    }).then((res) => {
      if (res.ok) {
        getUsers({ page: 0, users: 20 });
        handleBlockSuccess();
      }
    });
  };
  return (
    <Dialog
      onCancel={onCancel}
      onAccept={handleBlock}
      acceptText={`${action} User`}
      description={`Are you sure you want to ${action} ${name}?`}
      variant="secondary"
    />
  );
};

BlockUserModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  handleBlockSuccess: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default BlockUserModal;
