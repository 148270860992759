import React, { useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { Spinner } from "@boatlist/common/components";

import Card from "@/components/common/Card";
import Button from "@/components/common/Button";
import useLocalStorage from "../../../hooks/useLocalStorage";
import TableCell from "../TableCell";
import styles from "./DataTable.module.scss";

const HeaderItem = ({ _column, sortBy, handleSort }) => {
  if (_column.isSortable) {
    return (
      <div
        className={cx(
          _column.className,
          styles["table__cell"],
          _column.hideMobile && styles["table__cell--mobile"]
        )}
        style={{ width: _column.width }}
      >
        <Button
          variant="header"
          onClick={() => handleSort(_column.name)}
          active={sortBy === _column.name}
        >
          {_column.title}
        </Button>
      </div>
    );
  }
  return (
    <div
      className={cx(
        styles[_column.className],
        styles["table__cell"],
        _column.hideMobile && styles["table__cell--mobile"]
      )}
      style={{ width: _column.width }}
    >
      {_column.title}
    </div>
  );
};

const DataTableHeader = ({ _columns, sortBy, handleSort }) => {
  return (
    <div className={styles["header"]}>
      {_columns.map((_column) => (
        <HeaderItem {...{ _column, sortBy, handleSort }} />
      ))}
    </div>
  );
};

const DataTableRows = ({ _columns, _rows }) => {
  return (
    <div className={styles["table__content"]}>
      {_rows.map((_row) => (
        <div key={_row.id} className={styles["table__row"]}>
          {_columns.map((_column) => (
            <div
              key={_column.id}
              className={cx(
                _column.className,
                styles["table__cell"],
                _column.hideMobile && styles["table__cell--mobile"]
              )}
              style={{ width: _column.width }}
            >
              <TableCell column={_column} row={_row} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const sortRows = (a, b, sortBy, reverse) => {
  const aSortValue = a[sortBy].sortValue;
  const bSortValue = b[sortBy].sortValue;
  if (!aSortValue || typeof aSortValue === "undefined") return 1;
  if (!bSortValue || typeof bSortValue === "undefined") return -1;

  if (reverse) {
    if (typeof aSortValue === "number") {
      return bSortValue - aSortValue;
    }

    return bSortValue.localeCompare(aSortValue);
  }
  if (typeof aSortValue === "number") {
    return aSortValue - bSortValue;
  }

  return aSortValue.localeCompare(bSortValue);
};

const DataTable = ({ id, defaultSort, columns, rows, visibleColumns }) => {
  const [sortBy, setSortBy] = useLocalStorage(
    `${id}-table-sort`,
    defaultSort || "id"
  );
  const [reverseSort, setReverseSort] = useState("false");
  const [_columns, setColumns] = useState(columns);
  const [_rows, setRows] = useState(rows);

  const handleSort = (name) => {
    if (name === sortBy) {
      setReverseSort(!reverseSort);
    } else {
      setReverseSort(false);
    }
    setSortBy(name);
  };

  useEffect(() => {
    if (rows) {
      if (
        sortBy !== null &&
        typeof sortBy !== "undefined" &&
        columns.find((sortName) => sortName.name === sortBy)
      ) {
        const sortedRows = rows.sort((a, b) =>
          sortRows(a, b, sortBy, reverseSort)
        );
        setRows(sortedRows);
      } else {
        setRows(rows);
      }
    }
  }, [sortBy, rows, reverseSort]);

  return (
    <>
      <DataTableHeader {...{ _columns, sortBy, handleSort }} />
      {_rows ? <DataTableRows {...{ _columns, _rows }} /> : <Spinner />}
    </>
  );
};

DataTable.propTypes = {};

export default DataTable;
