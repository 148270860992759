import React from "react";
import PropTypes from "prop-types";
import Button from "@/components/common/Button/index.js";

const AuctionLabels = ({ auction }) => {
  return (
    <>
      {auction.categoryName && (
        <Button variant="primary" outline margin>
          {auction.categoryName}
        </Button>
      )}
      {auction.brandOther ? (
        <Button variant="danger" outline margin>
          {auction.brandOther}
        </Button>
      ) : (
        <Button variant="primary" outline margin>
          {auction.brandName}
        </Button>
      )}
      {auction.modelOther ? (
        <Button variant="danger" outline margin>
          {auction.modelOther}
        </Button>
      ) : (
        <Button variant="primary" outline margin>
          {auction.modelName}
        </Button>
      )}
    </>
  );
};

AuctionLabels.propTypes = {
  auction: PropTypes.object.isRequired,
};

export default AuctionLabels;
