import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";

import { BiTrash, TfiControlShuffle } from "react-icons/all";
import { BiMenu } from "react-icons/bi";
import styles from "./FileUpload.module.scss";
import TextField from "../TextField";
import IconButton from "@/components/common/IconButton";

const FileUpload = ({
  error,
  label,
  multiple,
  onChange,
  limit,
  optional,
  images,
}) => {
  const [imageError, setImageError] = useState("");

  const checkForMatch = (files) => {
    const list = [...files];
    const match = list.filter(
      (file) => !images.find((image) => image.name === file.name)
    );

    if (match) {
      onChange([...images, ...match]);
    } else {
      onChange([...images, ...files]);
    }
  };

  const handleAdd = (value) => {
    const fileList = [...value].map((file) => {
      file.source = URL.createObjectURL(file);
      return file;
    });
    if (value.length <= limit) {
      if (!images) {
        onChange([...fileList]);
      } else {
        checkForMatch(fileList);
      }
      setImageError(null);
    } else {
      setImageError(`Please select Only ${limit} images to upload!`);
    }
  };

  const handleDelete = (value) => {
    onChange(
      images.filter((filterValue) => {
        return filterValue.name !== value;
      })
    );
  };

  const handleSort = (result) => {
    if (!result.destination) return;

    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onChange(items);
  };

  const handleClassName = (index) => {
    if (index === 0) {
      return styles["drag_container__main"];
    }
    return styles["drag_container"];
  };

  // console.log(images);

  const handlePrint = (image, index) => {
    console.log("image", image, index);
  };

  return (
    <div>
      <TextField
        title={label}
        type="file"
        multiple={multiple}
        limit={limit}
        onChange={(e) => handleAdd(e.target.files)}
        topHelper={
          <span className="instructions">
            Please select up to {limit} media {limit > 1 ? "files" : "file"} at
            the same time.
            {optional && <span className="color_sky"> (Optional)</span>}
          </span>
        }
      />
      {(imageError || error) && (
        <div className={styles["input__error-message"]}>
          {imageError || error}
        </div>
      )}
      <div className="upload_file_names">
        <DragDropContext onDragEnd={handleSort}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {images &&
                  images.map((image, index) => (
                    <div className={styles["checkbox-box"]}>
                      <Draggable
                        key={image.name}
                        draggableId={image.name}
                        index={index}
                      >
                        {(providedInside) => (
                          <div
                            ref={providedInside.innerRef}
                            {...providedInside.draggableProps}
                            {...providedInside.dragHandleProps}
                          >
                            <div className={handleClassName(index)}>
                              <BiMenu size={20} />
                              <img
                                className={styles["img_container"]}
                                src={image.source}
                                alt={image.name}
                              />
                              <div
                                className={styles["name_holder"]}
                                title={image.name}
                              >
                                {image.name}
                              </div>
                              <IconButton
                                variant="danger"
                                hover
                                onClick={() => handleDelete(image.name)}
                              >
                                <BiTrash />
                              </IconButton>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

FileUpload.defaultProps = {
  error: "",
  multiple: false,
  optional: false,
};

FileUpload.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  limit: PropTypes.number.isRequired,
  optional: PropTypes.bool,
  images: PropTypes.array.isRequired,
};

export default FileUpload;
