import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import DataTable from "@/components/common/DataTable/index.js";

const RequestTable = ({ requestRows, columns, filter }) => {
  const [filterRequestRows, setFilteredRequestRows] = useState(null);

  console.log(requestRows);

  useEffect(() => {
    if (requestRows) {
      if (
        filter !== null &&
        typeof filter !== "undefined" &&
        filter.length !== 0
      ) {
        setFilteredRequestRows(
          requestRows.filter((request) =>
            filter.includes(request.requestStatus.filterValue)
          )
        );
      } else {
        setFilteredRequestRows(requestRows);
      }
    }
  }, [requestRows, filter]);

  return (
    <DataTable
      id="requests"
      defaultSort="requestDate"
      rows={filterRequestRows}
      {...{ columns }}
    />
  );
};

RequestTable.propTypes = {
  requestRows: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
};

export default RequestTable;
