import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "@boatlist/common/components";

import { getConfig } from "@boatlist/common/lib";
import { BiCheck, BiX } from "react-icons/all";
import Card from "@/components/common/Card";
import CreateUser from "@/components/users/CreateUser";
import UsersTableBar from "@/components/users/UsersTableBar";
import Button from "@/components/common/Button";
import UsersTable from "@/components/users/UsersTable";
import DeleteUserModal from "@/components/users/DeleteUserModal";
import ResetPasswordModal from "@/components/users/ResetPasswordModal";
import BlockUserModal from "@/components/users/BlockUserModal";
import Label from "@/components/common/Label";

import styles from "@/pages/UsersPage/UsersPage.module.scss";
import IconButton from "@/components/common/IconButton/index.js";

const columns = [
  {
    id: "username",
    name: "username",
    className: "username",
    value: "user_id",
    title: "Username",
    type: "link",
    width: "10%",
  },
  {
    id: "email",
    name: "email",
    className: "email",
    title: "Email",
    value: "email",
    width: "20%",
    hideMobile: true,
  },
  {
    id: "name",
    name: "name",
    className: "name",
    title: "Full Name",
    value: "user_metadata",
    width: "10%",
  },
  {
    id: "founding",
    name: "created_at",
    className: "founding",
    value: "created_at",
    title: "Founding",
    width: "10%",
    hideMobile: true,
  },
  {
    id: "status",
    name: "blocked",
    className: "status",
    hideMobile: true,
    value: "blocked",
    title: "Status",
    width: "10%",
  },
  {
    id: "login",
    name: "last_login",
    className: "login",
    value: "last_login",
    title: "Login",
    width: "15%",
    hideMobile: true,
  },
  {
    id: "actions",
    name: "actions",
    title: "Actions",
    className: "actions",
    type: "actions",
    width: "15%",
  },
];

const getUsersRows = (users, actions) => {
  const handleStatus = (user) => {
    if (user.blocked) {
      return <Label variant="danger">BLOCKED</Label>;
    }
    return <Label variant="success">ACTIVE</Label>;
  };

  const handleDate = (login) => {
    if (login) {
      const date = new Date(login).toLocaleString("en-us", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      return date;
    }

    return "Never logged in";
  };

  const getName = (user) => {
    if (user) {
      if (user.bidder_seller_info) {
        return `${user.bidder_seller_info.firstname} ${user.bidder_seller_info.lastname}`;
      }
    }
    return "N/A";
  };

  const handleFounding = (date) => {
    // const tt = new moment.tz("Jan 1, 2023, 6:00:00 AM", 'America/New_York'); // JAN 1st 2023
    const newDate = new Date(date);
    const tt = new Date("2023-01-01T06:00:00.000Z");
    const founding = newDate < tt;
    if (founding) {
      return (
        <Label variant="secondary" size="md">
          <BiCheck />
        </Label>
      );
    }
    return (
      <Label variant="danger" size="md">
        <BiX />
      </Label>
    );
  };

  return users.map((user) => ({
    id: user,
    username: {
      value: user.username,
      link: `/users/${user.user_id}`,
    },
    email: {
      value: user.email,
    },
    name: {
      value: getName(user.user_metadata),
    },
    created_at: {
      value: handleFounding(user.created_at),
    },
    blocked: {
      value: handleStatus(user),
    },
    last_login: {
      value: handleDate(user.last_login),
    },
    user: {
      value: user,
    },
    actions,
  }));
};

const Users = ({ users }) => {
  const [userRows, setUserRows] = useState(null);
  const [userObject, setUserObject] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [userAction, setUserAction] = useState(null);
  const [createView, setCreateView] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const setBlock = (user) => {
    if (user.blocked) {
      setUserAction("Unblock");
    } else {
      setUserAction("Block");
    }
    setUserObject(user);
    setBlockModal(true);
  };
  const setDelete = (user) => {
    setUserObject(user);
    setDeleteModal(true);
  };
  const setReset = (user) => {
    setUserObject(user);
    setResetModal(true);
  };

  useEffect(() => {
    if (users) {
      const actions = [
        {
          name: "block",
          title: "Block",
          onClick: (user) => {
            setBlock(user);
          },
        },
        {
          name: "delete",
          title: "Delete",
          onClick: (user) => {
            setDelete(user);
          },
        },
        {
          name: "reset",
          title: "Reset Password",
          onClick: (user) => {
            setReset(user);
          },
        },
      ];
      setUserRows(getUsersRows(users.users, actions));
    }
  }, [users]);

  // const userRows = getUsersRows(users.users);
  // console.log("rows:", userRows);

  const handleDeleteSuccess = () => {
    setDeleteModal(false);
  };

  const handleResetSuccess = () => {
    setResetModal(false);
  };
  const handleBlockSuccess = () => {
    setBlockModal(false);
  };
  // console.log("users", users);

  return (
    <>
      {createView ? (
        <CreateUser {...{ setCreateView, setSuccessMessage }} />
      ) : (
        <>
          {successMessage && <div>User created successfully</div>}
          <div className={styles["user-box"]}>
            <UsersTableBar {...{ setLoading }} />
            <div className={styles["flex-grow"]} />
            <Button variant="secondary" onClick={() => setCreateView(true)}>
              Create User
            </Button>
          </div>
          <Card>
            {loading ? (
              <div className={styles["center-spinner"]}>
                <Spinner />
              </div>
            ) : (
              <UsersTable
                {...{
                  userRows,
                  columns,
                }}
              />
            )}
          </Card>
        </>
      )}
      {deleteModal && (
        <DeleteUserModal
          name={userObject.username}
          userId={userObject.user_id}
          onCancel={() => setDeleteModal(false)}
          {...{ handleDeleteSuccess }}
        />
      )}
      {resetModal && (
        <ResetPasswordModal
          user={userObject}
          userId={userObject.user_id}
          onCancel={() => setResetModal(false)}
          {...{ handleResetSuccess }}
        />
      )}
      {blockModal && (
        <BlockUserModal
          name={userObject.username}
          onCancel={() => setBlockModal(false)}
          {...{ handleBlockSuccess }}
          userId={userObject.user_id}
          action={userAction}
        />
      )}
    </>
  );
};

Users.propTypes = {
  users: PropTypes.object.isRequired,
};

export default Users;
