import { apiCall } from "@boatlist/common/lib";

const basePluralUrl = "/rest/templates";
const baseSingleUrl = "/rest/template";

export const fetchGetEmailTemplates = () =>
  apiCall(basePluralUrl).then((res) => res.json());

export const fetchGetEmailTemplate = (templateName) =>
  apiCall(`${baseSingleUrl}/${templateName}`).then((res) => res.json());

export const fetchPostEmailTemplate = (template) =>
  apiCall(baseSingleUrl, {
    method: "POST",
    body: JSON.stringify(template),
  }).then((res) => res.json());

export const fetchPutEmailTemplate = (template) =>
  apiCall(baseSingleUrl, {
    method: "PUT",
    body: JSON.stringify(template),
  }).then((res) => res.json());

export const fetchDeleteEmailTemplate = (template) =>
  apiCall(`${baseSingleUrl}/${template.templateName}`, {
    method: "DELETE",
  });

export const fetchPublishEmailTemplate = (template) =>
  apiCall(`${baseSingleUrl}publish`, {
    method: "POST",
    body: JSON.stringify(template),
  }).then((res) => res.json());
