export const CONSTANTS = {
  NAV: {
    HOME: 0,
    USERS: 1,
    AUCTIONS: 2,
    REQUESTS: 3,
    MARKET: 4,
    EMAILS: 5,
  },
  BUYNOW_STATUS: {
    // 0 = only Buy Now, 1 = Buy Now until auction starts, 2 = Buy Now + Auction, 3 = No Buy Now
    BUY_NOW: 0,
    TILL_AUCTION: 1,
    AUCTION: 2,
  },
  REQUEST_STATUS: {
    NEW: 0,
    APPROVED: 1,
    REJECTED: 2,
    LISTED: 3,
  },

  LISTING_STATUS: {
    NEW: 0,
    ACTIVE: 1,
    LIVE: 2,
    ENDED: 3,
    CANCELLED: 4,
    POSTDEAL: 5,
  },

  MARKET_STATUS: {
    NEW: 0,
    PUBLISHED: 1,
    ACTIVE: 2,
    ENDED: 3,
    CANCELLED: 4,
    POSTDEAL: 5,
  },
};

// export const BUYNOW_STATUS = {
//   // 0 = only Buy Now, 1 = Buy Now until auction starts, 2 = Buy Now + Auction, 3 = No Buy Now
//   BUY_NOW: 0,
//   TILL_AUCTION: 1,
//   BUY_AUCTION: 2,
//   AUCTION: 3,
// };

// export const LISTING_STATUS = {
//   NEW: 0,
//   APPROVED: 1,
//   REJECTED: 2,
//   LISTED: 3,
// };
