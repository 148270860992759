import React, { useState, useEffect } from "react";
import { apiCall } from "@boatlist/common/lib";
import styles from "./AuctionFeed.module.scss";
import Button from "@/components/common/Button";
import Card from "@/components/common/Card";
import QuestionAndAnswer from "@/components/auctions/QuestionAndAnswer";

const AuctionFeed = ({ auctionId, auction, market }) => {
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    if (market) {
      apiCall(`/rest/questions/seller/${auctionId}?marketplace=true`)
        .then((res) => res.json())
        .then((json) => setQuestions(json));
    } else {
      apiCall(`/rest/questions/seller/${auctionId}`)
        .then((res) => res.json())
        .then((json) => setQuestions(json));
    }
  }, []);

  useEffect(() => {
    if (questions) {
      if (
        filter !== null &&
        typeof filter !== "undefined" &&
        filter.length !== 0
      ) {
        setFilteredQuestions(
          questions.filter((question) => question.entryType === filter)
        );
      } else {
        setFilteredQuestions(questions);
      }
    }
  }, [questions, filter]);

  const handleFilter = (name) => {
    if (filter === name) {
      setFilter(null);
    } else {
      setFilter(name);
    }
  };

  return (
    <div className={styles["questions-box"]}>
      <div className={styles["button-holder"]}>
        <Button
          variant="gray"
          onClick={() => handleFilter("question")}
          outline
          margin
          active={filter === "question"}
        >
          Questions
        </Button>
        <Button
          variant="gray"
          onClick={() => handleFilter("bid")}
          outline
          margin
          active={filter === "bid"}
        >
          Bids
        </Button>
        <Button
          variant="gray"
          onClick={() => handleFilter("offer")}
          outline
          margin
          active={filter === "offer"}
        >
          Offers
        </Button>
      </div>
      <Card>
        <div className={styles["scroll-box"]}>
          {filteredQuestions.map((question) => (
            <QuestionAndAnswer {...{ question, auction }} />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default AuctionFeed;
