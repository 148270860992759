import React, { useEffect, useState } from "react";
import { apiCall } from "@boatlist/common/lib";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import Card from "@/components/common/Card/";

import styles from "./WatchCard.module.scss";

const WatchCard = ({ filter }) => {
  const [watches, setWatches] = useState([]);

  useEffect(() => {
    apiCall(`/rest/watches`)
      .then((res) => res.json())
      .then((json) => {
        if (filter === "live") {
          setWatches(
            json
              .filter((live) => live.listingStatus === 2 && live.expirationDate)
              .slice(0, 4)
          );
        }
        if (filter === "schedule") {
          setWatches(
            json
              .filter((live) => live.listingStatus === 1 && live.expirationDate)
              .slice(0, 4)
          );
        }
        if (filter === "soon") {
          setWatches(
            json
              .filter(
                (live) =>
                  live.listingStatus === 1 && live.expirationDate === null
              )
              .slice(0, 4)
          );
        }
      });
  }, []);

  const handleTitle = () => {
    if (filter === "live") {
      return "Most Watched Live Boats";
    }
    if (filter === "schedule") {
      return "Most Watched Scheduled Boats";
    }
    if (filter === "soon") {
      return "Most Watched Coming Soon Boats";
    }
  };
  return (
    <div className={styles["card"]}>
      <Card>
        <div className={styles["watch-title"]}>{handleTitle()}</div>
        {watches.length !== 0 ? (
          watches.map((watch) => (
            <div className={styles["content-box"]}>
              <Link
                to={`/auctions/${watch.listingId}`}
                className={styles["link"]}
              >
                <div className={styles["content"]}>{watch.title}</div>
              </Link>
              <div className={styles["content"]}>{watch.watches}</div>
            </div>
          ))
        ) : (
          <div>No Boats Available</div>
        )}
      </Card>
    </div>
  );
};

WatchCard.propTypes = {
  filter: PropTypes.array.isRequired,
};

export default WatchCard;
