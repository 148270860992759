import React, { useState, useEffect } from "react";
import TextField from "@/components/common/TextField";
import Select from "@/components/common/Select";
import Card from "@/components/common/Card";

const states = [
  { name: "Alabama", label: "AL" },
  { name: "Alaska", label: "AK" },
  { name: "Arizona", label: "AZ" },
  { name: "Arkansas", label: "AR" },
  { name: "California", label: "CA" },
  { name: "Colorado", label: "CO" },
  { name: "Connecticut", label: "CT" },
  { name: "Delaware", label: "DE" },
  { name: "Florida", label: "FL" },
  { name: "Georgia", label: "GA" },
  { name: "Hawaii", label: "HI" },
  { name: "Idaho", label: "ID" },
  { name: "Illinois", label: "IL" },
  { name: "Indiana", label: "IN" },
  { name: "Iowa", label: "IA" },
  { name: "Kansas", label: "KS" },
  { name: "Kentucky", label: "KY" },
  { name: "Louisiana", label: "LA" },
  { name: "Maine", label: "ME" },
  { name: "Maryland", label: "MD" },
  { name: "Massachusetts", label: "MA" },
  { name: "Michigan", label: "MI" },
  { name: "Minnesota", label: "MN" },
  { name: "Mississippi", label: "MS" },
  { name: "Missouri", label: "MO" },
  { name: "Montana", label: "MT" },
  { name: "Nebraska", label: "NE" },
  { name: "Nevada", label: "NV" },
  { name: "New Hampshire", label: "NH" },
  { name: "New Jersey", label: "NJ" },
  { name: "New Mexico", label: "NM" },
  { name: "New York", label: "NY" },
  { name: "North Carolina", label: "NC" },
  { name: "North Dakota", label: "ND" },
  { name: "Ohio", label: "OH" },
  { name: "Oklahoma", label: "OK" },
  { name: "Oregon", label: "OR" },
  { name: "Pennsylvania", label: "PA" },
  { name: "Rhode Island", label: "RI" },
  { name: "South Carolina", label: "SC" },
  { name: "South Dakota", label: "SD" },
  { name: "Tennessee", label: "TN" },
  { name: "Texas", label: "TX" },
  { name: "Utah", label: "UT" },
  { name: "Vermont", label: "VT" },
  { name: "Virginia", label: "VA" },
  { name: "Washington", label: "WA" },
  { name: "West Virginia", label: "WV" },
  { name: "Wisconsin", label: "WI" },
  { name: "Wyoming", label: "WY" },
];

const ListingSpecs = ({
  listingId,
  handleNext,
  showStep,
  errors,
  checkedObject,
  setCheckedObject,
  watch,
  register,
  setValue,
  trigger,
  getValues,
  unregister,
  updateListing,
}) => {
  const [nameArray, setNameArray] = useState([
    "city",
    "state",
    "listingLength",
  ]);

  const handleNextStep = () => {
    const values = getValues();
    const filteredArray = nameArray.filter(
      (name) => values[name] === "" || Number.isNaN(values[name])
    );
    if (filteredArray.length !== 0) {
      trigger(filteredArray);
    } else {
      handleNext();
    }
  };

  const handleChecked = (value, name) => {
    if (value === true) {
      setCheckedObject({ ...checkedObject, [name]: value });
      setNameArray([...nameArray, name]);
      if (name === "startTime") {
        setNameArray([...nameArray, "startDate", "startTime"]);
      }
    } else {
      setCheckedObject({ ...checkedObject, [name]: value });
      // setValue(name, "");
      unregister(name);
      if (name === "startTime") {
        unregister("startDate", "startTime");
      }
    }
    if (name === "buyNow") {
      setValue("buyNow", Number(value ? 1 : 2));
      setCheckedObject({ ...checkedObject, [name]: value });
      setValue("price", "");
      setNameArray([...nameArray, "price"]);
    }
  };
  const buyNow = Number(watch("buyNow"));
  const listingLength = watch("listingLength");

  useEffect(() => {
    if (buyNow === 0) {
      setNameArray([...nameArray, "price"]);
    }
  }, [buyNow]);

  return (
    <Card color={updateListing && "blue"}>
      <div className="form_slide_display">
        <h3 className="h24 bottom_30 nocap">Listing Specs</h3>
        <div
          className={
            buyNow === 1 || buyNow === 2
              ? "hold_3 form_holder"
              : "hold_4 form_holder"
          }
        >
          <div className="select">
            <span className="label_text">Duration</span>
            <Select
              value={buyNow === 0 ? 30 : listingLength}
              disabled={buyNow === 0}
              valueAsNumber
              register={register}
              // required="This field is required."
              name="listingLength"
              error={errors.listingLength?.message}
            >
              <option value="" />
              <option value={10}>10 days</option>
              <option value={20}>20 days</option>
              <option value={30}>30 days</option>
            </Select>
          </div>

          {buyNow === 0 && (
            <div className="input dollar_sign">
              <TextField
                title="Price"
                type="number"
                name="price"
                register={register}
                required="This field is required."
                valueAsNumber
                error={errors.price?.message}
              />
            </div>
          )}
          <TextField
            title="City"
            register={register}
            name="city"
            // required="This field is required."
            error={errors.city?.message}
          />
          <Select
            label="State"
            register={register}
            name="state"
            // required="This field is required."
            error={errors.state?.message}
          >
            <option value="" />
            {states.map((state) => (
              <option value={state.label}>{state.name}</option>
            ))}
          </Select>
        </div>
        {(buyNow === 2 || buyNow === 1) && (
          <>
            <div className="hold_2 form_holder split60_40">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    value=""
                    onChange={(e) =>
                      handleChecked(e.target.checked, "startingBid")
                    }
                  />
                  <span className="display_checkbox" />
                  <span className="label_text checkbox_text">
                    Auction Starting Bid (optional){" "}
                    <span className="instructions">
                      To attract buyers and increase competition for your item,
                      consider a low starting bid.
                    </span>
                  </span>
                </label>
              </div>
              {checkedObject.startingBid && (
                <TextField
                  name="startingBid"
                  register={register}
                  required="This field is required."
                  valueAsNumber
                  // onChange={(e) => onChange(e.target.value, "startingBid")}
                  error={errors.startingBid?.message}
                />
              )}
            </div>
            <div className="hold_2 form_holder split60_40">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    value=""
                    onChange={(e) => handleChecked(e.target.checked, "reserve")}
                  />
                  <span className="display_checkbox" />
                  <span className="label_text checkbox_text">
                    Auction Reserve Price (optional){" "}
                    <span className="instructions">
                      Set the lowest price you want to accept for your item.{" "}
                      <a href="/how-boatlist-works/#seller?#can-i-put-a-reserve-price-on-my-auction?">
                        Learn more
                      </a>
                    </span>
                  </span>
                </label>
              </div>
              {checkedObject.reserve && (
                <TextField
                  name="reserve"
                  title="Reserve"
                  register={register}
                  required="This field is required."
                  // onChange={(e) => onChange(e.target.value, "reserve")}
                  valueAsNumber
                  error={errors.reserve?.message}
                />
              )}
            </div>
            <div className="hold_2 form_holder split60_40">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    value={buyNow === 1}
                    onChange={(e) => handleChecked(e.target.checked, "buyNow")}
                  />
                  <span className="display_checkbox" />
                  <span className="label_text checkbox_text">
                    Add Buy Now (optional)
                    <span className="instructions">
                      Buyers can purchase immediately at this price.{" "}
                    </span>
                  </span>
                </label>
              </div>
              {checkedObject.buyNow && (
                <TextField
                  title="Price"
                  type="number"
                  name="price"
                  register={register}
                  required="This field is required."
                  valueAsNumber
                  // value={formData.price}
                  // onChange={(e) => handleInput(Number(e.target.value), "price")}
                  error={errors.price?.message}
                />
              )}
            </div>
          </>
        )}
        <div className="hold_1 form_holder width_60">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                // checked={formData.allowOffers}
                // onChange={(e) => handleInput(e.target.checked, "allowOffers")}
                {...register("allowOffers")}
              />
              <span className="display_checkbox" />
              <span className="label_text checkbox_text">
                Will you accept offers? (optional)
                <span className="instructions">
                  Buyers can send you private offers for consideration.{" "}
                </span>
              </span>
            </label>
          </div>
        </div>
        <div className="hold_2 form_holder width_60">
          <div className="checkbox full_width">
            <label>
              <input
                type="checkbox"
                value=""
                checked={checkedObject.startTime}
                disabled={listingId && checkedObject.startTime}
                onChange={(e) => handleChecked(e.target.checked, "startTime")}
              />
              <span className="display_checkbox" />
              <span className="label_text checkbox_text">
                Schedule start time (optional)
                <span className="instructions">
                  It's best to choose a time in the evening when people are
                  home. Your listing will also end at this time.
                </span>
              </span>
            </label>
          </div>
          {checkedObject.startTime && (
            <>
              <TextField
                title="Date "
                type="date"
                // value={formData.startDateDate}
                // disabled={formData.listingStatus === 2 && checkedObject.startTime}
                // onChange={(e) => handleInput(e.target.value, "startDateDate")}
                register={register}
                name="startDate"
                required="This field is required."
                error={errors.startDate?.message}
              />
              <TextField
                title="Time (EST)"
                type="time"
                // value={formData.startDateTime}
                // disabled={formData.listingStatus === 2 && checkedObject.startTime}
                // onChange={(e) => handleInput(e.target.value, "startDateTime")}
                register={register}
                name="startTime"
                required="This field is required."
                error={errors.startTime?.message}
              />
            </>
          )}
        </div>
        {showStep === 3 && (
          <div className="submit text_right">
            <button onClick={handleNextStep} type="button" className="bt">
              Next: Images & Videos
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ListingSpecs;
