import React from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";
import { useDispatch } from "react-redux";
import Dialog from "@/components/common/Dialog";
import { deleteUser } from "@/store/slices/users.js";

const DeleteUserModal = ({ onCancel, handleDeleteSuccess, name, userId }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    apiCall(`/rest/user/${userId}`, { method: "DELETE" }).then((res) => {
      if (res.ok) {
        dispatch(deleteUser(userId));
        // window.location.href = "/users";
        handleDeleteSuccess();
      }
    });
  };
  console.log(name);
  return (
    <Dialog
      onCancel={onCancel}
      onAccept={handleDelete}
      description={`Are you sure you want to delete ${name}`}
      acceptText="Delete"
      variant="danger"
    />
  );
};

DeleteUserModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleDeleteSuccess: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default DeleteUserModal;
