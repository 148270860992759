import React, { useState, useEffect } from "react";
import TextField from "../TextField";
import PropTypes from "prop-types";
import Button from "@/components/common/Button";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { BiTrash } from "react-icons/all";
import { BiMenu } from "react-icons/bi";
import IconButton from "@/components/common/IconButton";

import styles from "./TextListField.module.scss";

const TextListField = ({ label, onChange, videos, error, limit }) => {
  const [inputValue, setInputValue] = useState(null);
  const [allLinks, setAllLinks] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [linkError, setLinkError] = useState(null);

  useEffect(() => {
    if (videos) {
      setAllLinks(videos);
      setDisabled(false);
      if (videos.length >= limit) {
        setDisabled(true);
      }
    }
  }, [videos]);

  const handleInput = (value) => {
    setInputValue(value);
  };

  const handleAdd = () => {
    if (inputValue === null) {
      setLinkError("Please enter a valid URL.");
    } else {
      setLinkError("");
      onChange([...allLinks, inputValue]);
    }
  };

  const handleSort = (result) => {
    if (!result.destination) return;

    const items = Array.from(allLinks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onChange(items);
  };

  const handleDelete = (value) => {
    onChange(
      allLinks.filter((filterValue) => {
        return filterValue !== value;
      })
    );
    setInputValue(null);
  };

  const handleClassName = () => {
    return styles["drag_container"];
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAdd();
      setInputValue("");
    }
  };

  return (
    <div>
      <div className={styles["text_list_container"]}>
        <TextField
          title={label}
          type="text"
          value={inputValue}
          onChange={(e) => handleInput(e.target.value)}
          error={linkError || error}
          onKeyUp={handleKeyPress}
          disabled={disabled}
        />
        <div className={styles["button_holder"]}>
          <Button
            variant="primary"
            onClick={() => {
              handleAdd();
              setInputValue("");
            }}
            disabled={disabled}
          >
            Add
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleSort}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {allLinks &&
                allLinks.map((link, index) => (
                  <div className={styles["checkbox-box"]}>
                    <Draggable key={link} draggableId={link} index={index}>
                      {(providedInside) => (
                        <div
                          ref={providedInside.innerRef}
                          {...providedInside.draggableProps}
                          {...providedInside.dragHandleProps}
                        >
                          <div className={handleClassName()}>
                            <BiMenu size={20} />
                            <div title={link} className={styles["name_holder"]}>
                              {link}
                            </div>
                            <IconButton
                              variant="danger"
                              hover
                              onClick={() => handleDelete(link)}
                            >
                              <BiTrash />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

TextListField.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  limit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  videos: PropTypes.array,
};

export default TextListField;
