import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { createEditor, Transforms, Path, Editor as _Editor } from "slate";
import { Slate, Editable, withReact, useSlateStatic } from "slate-react";
import { withHistory } from "slate-history";

import styles from "./Editor.module.scss";

import withLinks from "./plugins/withLinks";

import { insertImage } from "./utils/image";
import { insertLink } from "./utils/link";

const Paragraph = ({ attributes, children }) => (
  <p {...attributes}>{children}</p>
);

const Link = ({ attributes, element, children }) => (
  <a {...attributes} target="_blank" href={element.href}>
    {children}
  </a>
);

const Toolbar = () => {
  const editor = useSlateStatic();

  const handleInsertImage = () => {
    const url = prompt("Enter an Image URL"); // For simplicity
    insertImage(editor, url); // will be implemented later
  };

  const handleInsertLink = () => {
    const url = prompt("Enter a URL"); // For simplicity
    insertLink(editor, url); // will be implemented later
  };

  return (
    <div className="toolbar">
      <button onClick={handleInsertLink}>Link</button>
    </div>
  );
};

const Editor = ({ initialValue, id, readOnly }) => {
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);

  let initialValueJson;
  try {
    initialValueJson = initialValue && JSON.parse(initialValue);
  } catch (err) {
    initialValueJson = [{ children: [{ text: initialValue }] }];
  }

  const [value, setValue] = useState(
    initialValueJson ||
      (id && JSON.parse(localStorage.getItem(id))) || [
        {
          children: [{ text: "" }],
        },
      ]
  );
  console.log("val: ", value);

  const renderElement = (props) => {
    switch (props.element.type) {
      case "link":
        return <Link {...props} />;
      default:
        return <Paragraph {...props} />;
    }
  };

  const onChange = (val) => {
    const isAstChange = editor.operations.some(
      (op) => op.type !== "set_selection"
    );
    if (isAstChange) {
      // Save the value to Local Storage.
      const content = JSON.stringify(val);
      localStorage.setItem(id, content);
    }
  };

  return (
    <div className={styles["editor"]}>
      <Slate {...{ editor, value, setValue, onChange }}>
        {!readOnly && <Toolbar />}
        <Editable readOnly={readOnly} renderElement={renderElement} />
      </Slate>
    </div>
  );
};

Editor.defaultProps = {
  readOnly: false,
  id: undefined,
  initialValue: "",
};

Editor.propTypes = {
  initialValue: PropTypes.string,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
};

export default Editor;
