import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Modal from "@/components/common/Modal";
import Card from "@/components/common/Card";
import Button from "@/components/common/Button";
import styles from "./Dialog.module.scss";

const Dialog = ({
  onCancel,
  onAccept,
  title,
  acceptText,
  description,
  children,
  variant,
}) => {
  return (
    <Modal id="root-portal-container" onCancel={onCancel}>
      <Card>
        <div className={styles["dialog-children"]}>
          <div className={cx(styles["title"], styles[`title__${variant}`])}>
            {title}
          </div>
          <div className={styles["description"]}>{description}</div>
          {children}
        </div>
        <div className={styles["button-holder"]}>
          <Button variant="gray" outline onClick={onCancel} margin>
            Cancel
          </Button>
          <Button variant={variant} onClick={onAccept}>
            {acceptText}
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

Dialog.defaultProps = {
  title: null,
  description: null,
};

Dialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  title: PropTypes.string,
  acceptText: PropTypes.string.isRequired,
  description: PropTypes.string,
  variant: PropTypes.string.isRequired,
};

export default Dialog;
