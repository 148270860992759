import React, { useEffect, useState } from "react";
import { apiCall } from "@boatlist/common/lib";
import { Link } from "react-router-dom";
import styles from "./WatchesAndFollowsTab.module.scss";
import Card from "@/components/common/Card";

const WatchesAndFollowsTabs = ({ user }) => {
  const [follows, setFollows] = useState(null);
  const [watches, setWatches] = useState(null);
  const [marketplaceWatches, setMarketplaceWatches] = useState(null);
  useEffect(() => {
    apiCall(`/rest/follows/${user.id}`)
      .then((res) => res.json())
      .then((json) => setFollows(json));

    apiCall(`/rest/watches/${user.id}`)
      .then((res) => res.json())
      .then((json) => setWatches(json));

    apiCall(`/rest/marketplacewatches/${user.id}`)
      .then((res) => res.json())
      .then((json) => setMarketplaceWatches(json));
  }, []);

  // useEffect(() => {
  //   if (marketplaceWatches && watches) {
  //     const newArray = marketplaceWatches.filter((market) =>
  //       watches.filter((watch) => watch.listingId === market.listingId)
  //     );
  //     const arrayWithMarket = newArray.map((market) => {
  //       market.marketWatch = true;
  //       return market;
  //     });
  //   }
  // }, [marketplaceWatches, watches]);

  const brands =
    follows && follows.filter((brand) => brand.followType === "brands");
  const categories =
    follows &&
    follows.filter((category) => category.followType === "categories");
  const models =
    follows && follows.filter((model) => model.followType === "models");
  const marketplace =
    follows && follows.filter((follow) => follow.followType === "marketplace");

  return follows || watches ? (
    <>
      <h2>Follows</h2>
      <div className={styles["card-box"]}>
        {brands && brands.length !== 0 && (
          <div className={styles["card-inside"]}>
            <Card>
              <div className={styles["watch-title"]}>Brands</div>
              {brands.length !== 0 && (
                <>
                  {brands.map((brand) => (
                    <div className={styles["content-box"]}>
                      <div className={styles["content"]}>{brand.name}</div>
                    </div>
                  ))}
                </>
              )}
            </Card>
          </div>
        )}
        {categories && categories.length !== 0 && (
          <div className={styles["card-inside"]}>
            <Card>
              <div className={styles["watch-title"]}>Categories</div>
              {categories.length !== 0 && (
                <>
                  {categories.map((category) => (
                    <div className={styles["content-box"]}>
                      <div className={styles["content"]}>{category.name}</div>
                    </div>
                  ))}
                </>
              )}
            </Card>
          </div>
        )}
        {models && models.length !== 0 && (
          <div className={styles["card-inside"]}>
            <Card>
              <div className={styles["watch-title"]}>Models</div>
              {models.length !== 0 && (
                <>
                  {models.map((model) => (
                    <div className={styles["content-box"]}>
                      <div className={styles["content"]}>{model.name}</div>
                    </div>
                  ))}
                </>
              )}
            </Card>
          </div>
        )}
        {marketplace && marketplace.length !== 0 && (
          <div className={styles["card-inside"]}>
            <Card>
              <div className={styles["watch-title"]}>Marketplace</div>
              {marketplace.length !== 0 && (
                <>
                  {marketplace.map((follow) => (
                    <div className={styles["content-box"]}>
                      <div className={styles["content"]}>{follow.name}</div>
                    </div>
                  ))}
                </>
              )}
            </Card>
          </div>
        )}
      </div>

      {watches && watches.length !== 0 && (
        <div className={styles["card-inside"]}>
          <h2>Watches</h2>
          <Card>
            {watches.length !== 0 && (
              <>
                <div className={styles["watch-title"]}>Auctions</div>
                {watches.map((watch) => (
                  <div className={styles["content-box"]}>
                    <Link
                      to={`/auctions/${watch.listingId}`}
                      className={styles["link"]}
                    >
                      <div className={styles["content"]}>{watch.title}</div>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </Card>
          <div className={styles["flex-grow"]} />
          <Card>
            {marketplaceWatches && marketplaceWatches.length !== 0 && (
              <>
                <div className={styles["watch-title"]}>Marketplace</div>
                {marketplaceWatches.map((watch) => (
                  <div className={styles["content-box"]}>
                    <Link
                      to={`/marketplace/${watch.listingId}`}
                      className={styles["link"]}
                    >
                      <div className={styles["content"]}>{watch.title}</div>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </Card>
        </div>
      )}
    </>
  ) : (
    <Card>
      <div>No Boats Available</div>
    </Card>
  );
};

export default WatchesAndFollowsTabs;
