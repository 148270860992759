import React from "react";
import Card from "@/components/common/Card";
import TextField from "@/components/common/TextField";

import styles from "./MarketListingForm.module.scss";

const ScheduleCard = ({ updateListing, errors, register }) => {
  return (
    <Card color={updateListing && "blue"}>
      <div className={styles["heading"]}>Schedule Date & Time</div>
      <TextField
        title="Schedule a Start Date "
        type="date"
        // onChange={(e) => handleInput(e.target.value, "startDate")}
        register={register}
        name="startDate"
        required="This field is required."
        error={errors.startDate?.message}
      />
      <TextField
        title="Schedule a Start Time "
        type="time"
        // onChange={(e) => handleInput(e.target.value, "startTime")}
        register={register}
        name="startTime"
        required="This field is required."
        error={errors.startTime?.message}
      />
    </Card>
  );
};

export default ScheduleCard;
