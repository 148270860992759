import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { apiCall } from "@boatlist/common/lib";

import Select from "@/components/common/Select/index.js";
import Button from "@/components/common/Button/index.js";
import Card from "@/components/common/Card/Card.jsx";
import styles from "./AuctionForm.module.scss";
import TextField from "@/components/common/TextField";
import { useForm } from "react-hook-form";
import FormDialog from "@/components/common/FormDialog";

const AuctionForm = ({ setAuction, auction, defaultValues }) => {
  const [updateModal, setUpdateModal] = useState(false);
  const { register, handleSubmit, reset } = useForm({});

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const onSubmit = (data) => {
    const auctionObject = {
      ...data,
      listingStatus: Number(data.listingStatus),
      startingBid: Number(data.startingBid),
    };

    apiCall(`/rest/listing/${auction.listingId}`, {
      method: "PUT",
      body: JSON.stringify(auctionObject),
    }).then((response) => {
      if (response.ok) {
        setUpdateModal(false);
        setAuction({ ...auction, ...auctionObject });
      }
    });
  };

  return (
    <div className={styles["form-box"]}>
      <Card color="blue">
        <div className={styles["title"]}>Auction Update</div>
        <div className={styles["inside-box"]}>
          <div className={styles["select-title"]}>Auction Status</div>
          <Select
            // onChange={(e) =>
            //   handleInput(Number(e.target.value), "listingStatus")
            // }
            register={register}
            name="listingStatus"
          >
            <option value="" />
            <option value={0}>New</option>
            <option value={1}>Scheduled</option>
            <option value={2}>Active</option>
            <option value={3}>Ended</option>
            <option value={4}>Cancelled</option>
            <option value={5}>Post Deal</option>
          </Select>
          <div className={styles["checkbox-holder"]}>
            <div className={styles["checkbox"]}>
              <input
                type="checkbox"
                id="blog"
                // onChange={(e) =>
                //   handleInput(Number(e.target.checked), "featured")
                // }
                {...register("featured")}
              />
              <span>Featured Auction</span>
            </div>
          </div>
          <div>
            <TextField
              title="Starting Bid"
              // onChange={(e) =>
              //   handleInput(Number(e.target.value), "startingBid")
              // }
              register={register}
              name="startingBid"
            />
          </div>
        </div>
        <div className={styles["button-right"]}>
          <Button variant="primary" onClick={() => setUpdateModal(true)}>
            Update
          </Button>
        </div>
      </Card>
      {updateModal && (
        <FormDialog
          onCancel={() => setUpdateModal(false)}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          variant="primary"
          acceptText="Update"
          description="Are you sure you want to update this auction?"
        />
      )}
    </div>
  );
};

AuctionForm.propTypes = {
  auction: PropTypes.object.isRequired,
  setAuction: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default AuctionForm;
