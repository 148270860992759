import React from "react";
import PropTypes from "prop-types";

import DataTable from "../../components/common/DataTable";
import Label from "../../components/common/Label";
import Card from "../../components/common/Card";
import EmailsPageActionsPopper from "./EmailsPageActionsPopper";

const columns = [
  {
    id: "templateName",
    name: "templateName",
    className: "templateName",
    value: "templateName",
    title: "Name",
    type: "action",
    width: "40%",
    isSortable: true,
  },
  {
    id: "templateStatus",
    name: "templateStatus",
    className: "templateStatus",
    hideMobile: true,
    value: "templateStatus",
    title: "Status",
    width: "25%",
  },
  {
    id: "updatedTimestamp",
    name: "updatedTimestamp",
    className: "updatedTimestamp",
    value: "updatedTimestamp",
    title: "UpdatedTimestamp",
    width: "30%",
    hideMobile: true,
    isSortable: true,
  },
  {
    id: "actions",
    name: "actions",
    title: "Actions",
    className: "actions",
    type: "custom",
    width: "15%",
  },
];

const getEmailTemplatesRows = (emailTemplates, actions, handleEditTemplate) => {
  const handleStatus = (status) => {
    switch (status) {
      case "New":
        return (
          <Label variant="secondary" size="md">
            New
          </Label>
        );
      case "Draft":
        return (
          <Label variant="secondary" size="md">
            Draft
          </Label>
        );
      case "Updated":
        return (
          <Label variant="primary" size="md">
            Updated
          </Label>
        );
      case "Published":
        return (
          <Label variant="success" size="md">
            Published
          </Label>
        );
      default:
        return (
          <Label variant="danger" size="md">
            N/A
          </Label>
        );
    }
  };

  const handleDate = (updatedTimestamp) =>
    updatedTimestamp
      ? new Date(updatedTimestamp).toLocaleString("en-us", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
      : "None";

  return emailTemplates.map((template) => ({
    id: template,
    templateName: {
      value: template.templateName,
      onClick: handleEditTemplate,
      sortValue: template.templateName,
    },
    templateStatus: {
      value: handleStatus(template.templateStatus),
    },
    updatedTimestamp: {
      value: handleDate(template.updatedTimestamp),
      sortValue: template.updatedTimestamp,
    },
    actions: () => actions(template),
  }));
};

const EmailsTable = ({
  emailTemplates,
  handleEditTemplate,
  handleDeleteTemplate,
  handleDuplicateTemplate,
}) => {
  //   const actions = [
  //     {
  //       name: "duplicate",
  //       title: "Duplicate",
  //       onClick: handleDuplicateTemplate,
  //     },
  //     {
  //       name: "delete",
  //       title: "Delete",
  //       onClick: handleDeleteTemplate,
  //     },
  //   ];
  const actions = (activeEmailTemplate) => (
    <EmailsPageActionsPopper
      {...{
        handleDeleteTemplate,
        handleDuplicateTemplate,
        activeEmailTemplate,
      }}
    />
  );
  const rows = getEmailTemplatesRows(
    emailTemplates,
    actions,
    handleEditTemplate
  );
  return (
    <Card>
      <DataTable id="emailTemplates" {...{ rows, columns }} />
      {/* {emailTemplates &&
        emailTemplates.map((template) => (
          <Button
            key={template.templateName}
            outline
            onClick={() => handleEditTemplate(template)}
          >
            {template.templateName}
          </Button>
        ))} */}
    </Card>
  );
};

EmailsTable.defaultProps = {
  emailTemplates: null,
  activeEmailTemplate: null,
};

EmailsTable.propTypes = {
  emailTemplates: PropTypes.arrayOf([
    PropTypes.shape({
      templateName: PropTypes.string.isRequired,
    }),
  ]),
  activeEmailTemplate: PropTypes.shape(),
  handleEditTemplate: PropTypes.func.isRequired,
  handleDeleteTemplate: PropTypes.func.isRequired,
  handleDuplicateTemplate: PropTypes.func.isRequired,
};

export default EmailsTable;
