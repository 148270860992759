import React, { useEffect } from "react";

const UpdateMessage = ({ updateMessage, messageRef }) => {
  useEffect(() => {
    if (updateMessage) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [updateMessage]);
  return updateMessage && <div ref={messageRef}>Update Successful</div>;
};

export default UpdateMessage;
